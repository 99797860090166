import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, cssTransition } from 'react-toastify';

import styles from './styles.less';

const ToastTransition = cssTransition({
  enter: styles.fadeIn,
  exit: styles.fadeOut,
  duration: 300
});

class CustomizedToastContainer extends Component {
  render() {
    const { darkenSurroundings } = this.props;

    return (
      <div className={styles.toastContainerWithDarkening}>
        {darkenSurroundings && <div className={styles.toastDarkener}>
          <div className={styles.darken} />
          <div className={styles.notDarken} />
          <div className={styles.darken} />
        </div>}
        <ToastContainer
          toastClassName={styles.toast}
          bodyClassName={styles.toastBody}
          progressClassName={styles.toastProgress} // needed for autoclose to work with hidden Progress Bar, see https://github.com/fkhadra/react-toastify/issues/320
          closeButton={false}
          draggable={false}
          hideProgressBar
          transition={ToastTransition}
          closeOnClick={false}
          autoClose={5000}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ darkenSurroundings: { darkenSurroundings } }) => {
  return {
    darkenSurroundings
  };
};

export default connect(
  mapStateToProps,
)(CustomizedToastContainer);
