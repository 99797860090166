import React from 'react';
import { IndexDual } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';
import { IMAGE_THUMBNAIL } from '../components/constants';

export default ({ file_image_id, file_background_image_id, ...props }) => {
  return (<IndexDual
    {...props}
    imageSource={generateUrl(file_image_id, { width: IMAGE_THUMBNAIL.CONTENT.WIDTH })}
    backgroundImageSource={generateUrl(file_background_image_id, { width: IMAGE_THUMBNAIL.PAGE.WIDTH })}
    textElementsLeft={props.children.reduce((acc, key) => {
      if (key.config && JSON.parse(key.config).column === 'left') {
        const { file_audio_id, ...items } = key;
        acc.push({ ...items, audioSource: generateUrl(file_audio_id) });
      }
      return acc;
    }, [])}
    textElementsRight={props.children.reduce((acc, key) => {
      if (key.config && JSON.parse(key.config).column === 'right') {
        const { file_audio_id, ...items } = key;
        acc.push({ ...items, audioSource: generateUrl(file_audio_id) });
      }
      return acc;
    }, [])}
  />);
};