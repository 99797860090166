import React from 'react';
import { AnswerTextSelection } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_audio_id, ...props }) => (
  <AnswerTextSelection
    {...props}
    textElements={props.answers.map(textElement => ({
      id: textElement.id,
      text: textElement.text,
      audioSource: generateUrl(textElement.file_audio_id)
    }))}
  />
);