import localForage from 'localforage';
import { batchActions } from 'redux-batched-actions';
import mime from 'mime';

import { store } from '../../redux';
import { actions } from '../redux';

const ERROR_MESSAGES = {
  FETCHING_HEADERS: 'Error, could not fetch headers file:',
  FILE_EXISTS: 'Error, checking if file exists:',
  DOWNLOAD_PROMISE: 'Error, download promise failed:',
  DOWNLOAD: 'Error, could not download file:',

  WRITING_BLOB_TO_FS: 'Error, could not write blob to filesystem:',
  WRITE_FILE_ON_CORDOVA: 'Error, could not write file on cordova:',
  WRITE_FILE_ON_ELECTRON: 'Error, saving file on mainprocess failed:',

  CREATE_CACHED_BLOB: 'Error, could not create blob from cache:',
  DOWNLOAD_BLOB: 'Error, could not download blob:',
  PERSISTING_BLOB: 'Error, could not persist blob:',
};

class DownloadHandler {
  static errorMessage = (errorMessage, err, addition = '') => {
    console.warn(errorMessage, addition, err);
  }

  /**
   * In case we can't load some asset, we load a transparent gif instead.
   * When using fetch on a base64 string we can easily generate a blob from it
   * to store in our local storage. (indexeddb, websql, etc)
   */
  transparentGif = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
  transparentBlob;
  setFileActionBuffer = [];
  setFileTimeout = false;

  downloadsInProgress = {};
  persistingBlobsInProgress = {};
  constructor() {
    fetch(this.transparentGif)
      .then(res => res.blob())
      .then(blob => this.transparentBlob = blob);
  }

  getPathToFile = (name, ext = 'txt') => {
    console.warn('Not implemented');
  }

  isFileDownloaded = async (name, ext) => {
    console.warn('Not implemented');
  }

  downloadFile = async (url, name) => {
    console.warn('Not implemented');
  }

  saveBlobToFilesystem = (blob, url, name) => {
    console.warn('Not implemented');
  }

  resolveFilename = filename => {
    return filename.replace(/\?/g, '_');
  }

  /**
  * Batch multiple set file actions into one batched action
  */
  queueSetFile = file => {
    clearTimeout(this.setFileTimeout);

    this.setFileActionBuffer.push(actions.setFileBlobUrl(file));

    this.setFileTimeout = setTimeout(() => {
      store.dispatch(batchActions(this.setFileActionBuffer));
      this.setFileActionBuffer = [];
    }, 250);
  }

  getChunkedBlob = async item => {
    const { chunksAmount, type } = item;

    const chunksPromises = [];

    for (let i = 1; i <= chunksAmount; i++) {
      chunksPromises.push(localForage.getItem(`${item.name}.part${i}`));
    }

    const parts = await Promise.all(chunksPromises);

    return new Blob(parts, { type });
  }

  getFileExtension = async url => {
    return fetch(
      url,
      {
        method: 'HEAD',
      },
    ).then(res => {
      if (res.ok) {
        // console.log(Number(res.headers.get('Content-Length') / 1024 / 1024).toFixed(2) + ' MB:', url);
        return mime.getExtension(res.headers.get('Content-Type'));
      } else {
        return undefined;
      }
    }).catch(err => DownloadHandler.errorMessage(ERROR_MESSAGES.FETCHING_HEADERS, err));
  }

  migrateFileFromBlobToFilesystem = async (blob, url, name) => {
    let fileUrl;
    if (blob) {
      if (this.persistingBlobsInProgress[name]) {
        fileUrl = await this.persistingBlobsInProgress[name];
      } else {
        if (blob.isChunked) {
          blob = await this.getChunkedBlob(blob);
        }

        this.persistingBlobsInProgress[name] = this.saveBlobToFilesystem(blob, url, name);
        fileUrl = await this.persistingBlobsInProgress[name];
      }
      await localForage.removeItem(name);

      delete this.persistingBlobsInProgress[name];

      return fileUrl;
    }
  }

  downloadAsFile = async (url, name) => {
    let blob = await localForage.getItem(name);
    let fileUrl;

    if (blob) {
      // Dont migrate if already same file in filesystem
      const ext = mime.getExtension(blob.type);

      const pathToFile = this.getPathToFile(name, ext);
      const fileExists = await this.isFileDownloaded(name, ext);

      if (!fileExists) {
        return this.migrateFileFromBlobToFilesystem(blob, url, name);
      }
      fileUrl = pathToFile;
    } else {
      fileUrl = store.getState().assets.files[name];
    }

    if (fileUrl) {
    } else {
      try {
        if (this.downloadsInProgress[url]) {
          fileUrl = await this.downloadsInProgress[url];
        } else {
          const downloadPromise = new Promise(async (resolve, reject) => {
            try {
              const filePath = await this.downloadFile(url, name);
              resolve(filePath);
            } catch (err) {
              DownloadHandler.errorMessage(ERROR_MESSAGES.DOWNLOAD_PROMISE, err);
              resolve(null);
            }
          });
          this.downloadsInProgress[url] = downloadPromise;
          fileUrl = await downloadPromise;
          delete this.downloadsInProgress[url];
        }
      } catch (err) {
        DownloadHandler.errorMessage(ERROR_MESSAGES.DOWNLOAD, err, `${name} from ${url}`);
        delete this.downloadsInProgress[url];
        return '';
      }
    }

    delete this.downloadsInProgress[url];
    return fileUrl;
  }

  downloadAsBlob = async (url, name) => {
    let item = await localForage.getItem(name);
    let blobUrl;

    if (item) {
      // item found in localstorage, use a new bloburl for it

      if (item.isChunked) {
        item = await this.getChunkedBlob(item);
      }

      try {
        blobUrl = window.URL.createObjectURL(item);
      } catch (err) {
        DownloadHandler.errorMessage(ERROR_MESSAGES.CREATE_CACHED_BLOB, err);
        return null;
      }
    } else {
      let res;
      let blob;

      try {
        if (this.downloadsInProgress[url]) {
          blob = await this.downloadsInProgress[url];
        } else {
          const downloadPromise = new Promise(async (resolve, reject) => {
            res = await fetch(url);

            // if res is okay, we get the response blob
            if (res.ok) {
              blob = await res.blob();

              if (!blob.type) {
                // !! ensure that mimetype is set on the blob, safari on ios <= 10.3 doesnt auto populate the blobs type field  !!
                blob = blob.slice(0, blob.size, res.headers.get('Content-Type'));
              }
            } else {
              blob = null;
            }
            resolve(blob);
          });

          this.downloadsInProgress[url] = downloadPromise;

          blob = await downloadPromise;

          delete this.downloadsInProgress[url];
        }
      } catch (err) {
        DownloadHandler.errorMessage(ERROR_MESSAGES.DOWNLOAD_BLOB, err, `${name} from ${url}`);
        return null;
      }

      if (blob) {
        if (this.persistingBlobsInProgress[name]) {
          blobUrl = await this.persistingBlobsInProgress[name];
        } else {
          this.persistingBlobsInProgress[name] = new Promise(async (resolve, reject) => {
            try {
              const limit = 10000000;

              if (blob.size > limit) {
                const chunksAmount = Math.ceil(blob.size / limit);
                const chunks = [];

                for (let i = 0; i < chunksAmount; i++) {
                  const end = i === chunksAmount - 1 ? blob.size : ((i + 1) * limit);

                  chunks.push(
                    blob.slice(i * limit, end, blob.type)
                  );
                }

                chunks.forEach(async (chunk, index) => await localForage.setItem(`${name}.part${index + 1}`, chunk));

                const chunksInfo = {
                  isChunked: true,
                  chunksAmount,
                  size: blob.size,
                  name,
                  type: blob.type
                };

                await localForage.setItem(name, chunksInfo);

                blob = await this.getChunkedBlob(chunksInfo);
              } else {
                await localForage.setItem(name, blob);
                blob = await localForage.getItem(name);
              }

              resolve(window.URL.createObjectURL(blob));
              blob = undefined;
              delete this.persistingBlobsInProgress[name];
            } catch (err) {
              DownloadHandler.errorMessage(ERROR_MESSAGES.PERSISTING_BLOB, err);
              resolve(null);
            }
          });
          blobUrl = await this.persistingBlobsInProgress[name];
        }
      } else {
        blobUrl = null;
      }
    }

    return blobUrl;
  }

  returnCorrectErrorObject = () => {
    return window.URL.createObjectURL(this.transparentBlob);
  }

  downloadAsset = async ({ url, name, regenerateBlobUrl = false }) => {
    if (typeof url !== 'string') {
      return this.returnCorrectErrorObject();
    }

    let persistedUrl;

    persistedUrl = await this.downloadAsBlob(url, name);
    if (persistedUrl) {
      this.queueSetFile({ name, url: persistedUrl });
    }

    return persistedUrl || this.returnCorrectErrorObject();
  }
}

export {
  ERROR_MESSAGES
};
export default DownloadHandler;