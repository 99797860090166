function getDuration (end, start) {
  const ms = new Date(end - start).getMilliseconds();
  const s = new Date(end - start).getSeconds();
  const m = new Date(end - start).getMinutes();

  return  m + ':' + s + ':' + ms;
}


const performanceChecks = {
  enabled: false,
  downloadStart: 0,
  downloadEnd: 0,
  downloadDuration: () => getDuration(performanceChecks.downloadEnd, performanceChecks.downloadStart),
  blobsStart: 0,
  blobsEnd: 0 ,
  blobsDuration: () => getDuration(performanceChecks.blobsEnd, performanceChecks.blobsStart),
  blobsPageStart: 0,
  blobsPageEnd: 0 ,
  blobsPageDuration: () => getDuration(performanceChecks.blobsPageEnd, performanceChecks.blobsPageStart),
  blobsPageOverviewStart: 0,
  blobsPageOverviewEnd: 0 ,
  blobsPageOverviewDuration: () => getDuration(performanceChecks.blobsPageOverviewEnd, performanceChecks.blobsPageOverviewStart)
};

module.exports = performanceChecks;
