export default function getThumbnailPath(thumbnailProps) {
  let thumbnailPath = '';
  if (thumbnailProps) {
    if (!thumbnailProps.width && !thumbnailProps.height) {
      console.error('Please give the image a width or height');
    } else {
      thumbnailPath = '?type=thumbnail';
      thumbnailPath += thumbnailProps.width ? `&width=${thumbnailProps.width}` : '&width=0';
      thumbnailPath += thumbnailProps.height ? `&height=${thumbnailProps.height}` : '&height=0';
      thumbnailPath += thumbnailProps.quality ? `&quality=${thumbnailProps.quality}` : '';
      thumbnailPath += '&ext=jpg';
    }
  }
  return thumbnailPath;
}