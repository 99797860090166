import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions } from '../../domains/magazines/redux';
import { actions as darkenSurroundingsActions } from '../../domains/magazines/blacklist/redux';

import { OVERLAY_TYPES } from '../constants.js';
import appVersion from '../../helpers/appVersion';

import { Button, SimpleButton } from '../../components';

import styles from './styles.less';

import downloadFromBrowser from '../../helpers/downloadFromBrowser';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metaMenu: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMetaMenu);
  }

  render() {
    const { metaMenu } = this.state;
    const { overlay, currentMagazine, sourceDirectory, showHotspots, toggleHotspots, darkenSurroundings } = this.props;

    if (this.state.metaMenu) {
      document.addEventListener('mousedown', this.closeMetaMenu);
    } else {
      document.removeEventListener('mousedown', this.closeMetaMenu);
    }

    switch (overlay) {
      case 'PAGES':
      case 'IMPRESSUM':
      case 'CONTACT':
      case 'DATA_PROTECTION':
      case 'MAGAZINES':
      case 'HELP':
      case 'NONE':
        return (
          <div className={styles.header}>
            <div className={styles.left}>
              <div className={styles.headerEntryLeft} onClick={() => this.setOverlay(OVERLAY_TYPES.MAGAZINES)}>
                <SimpleButton icon="home" />
              </div>
              {overlay !== 'MAGAZINES' && overlay !== 'CONTACT' && overlay !== 'DATA_PROTECTION' && currentMagazine && (
                [
                  <div key="chevronPages" className={`${styles.headerEntryLeft} ${styles.chevronOuter}`}>
                    <div className={styles.chevron} />
                  </div>,
                  <div key="pages" className={styles.headerEntryLeft}>
                    <SimpleButton icon="pages" onClick={() => this.setOverlay(OVERLAY_TYPES.PAGES)} />
                  </div>
                ]
              )}
              {overlay !== 'MAGAZINES' && overlay !== 'PAGES' && overlay !== 'CONTACT' && overlay !== 'DATA_PROTECTION' && currentMagazine && (
                [
                  <div key="chevronPage" className={`${styles.headerEntryLeft} ${styles.chevronOuter}`}>
                    <div className={styles.chevron} />
                  </div>,
                  <div key="page" className={`${styles.headerEntryLeft} ${styles.names}`}>
                    {currentMagazine.name}
                    &nbsp;
                    {currentMagazine.subtitle}
                  </div>
                ]
              )}
            </div>
            <div className={styles.right}>
              <SimpleButton icon="fichierWithFont" onClick={() => {
                if (appVersion.isDesktop) {
                  const { shell } = window.require('electron');
                  shell.openExternal('https://www.s-fichier.ch');
                } else {
                  window.open('https://www.s-fichier.ch');
                }
              }} />
              {(overlay === 'NONE' || overlay === 'HELP') && <Button icon="interaction" color="hotspot" smallShadow smaller active={showHotspots} onClick={() => toggleHotspots(!showHotspots)} styles={styles.headerEntryRight} />}
              <Button id="menuButton" icon="hamburger" color="white" smallShadow smaller active={metaMenu} onClick={this.toggleMetaMenu} styles={styles.headerEntryRight} />
            </div>
            {metaMenu &&
              <div className={styles.metaMenu} ref={node => this.node = node}>
                <button disabled={overlay !== 'NONE'} onClick={() => {
                  const { setDarkenSurroundings } = this.props;
                  setDarkenSurroundings(true);
                  this.setOverlay(OVERLAY_TYPES.HELP);
                }}>Hilfe</button>
                <button onClick={() => this.setOverlay(OVERLAY_TYPES.MAGAZINES)}>Magazinübersicht</button>
                <button disabled={(overlay !== 'NONE' && overlay !== 'PAGES') || !sourceDirectory} onClick={() => { downloadFromBrowser(sourceDirectory, `${currentMagazine.name}_Quellenverzeichnis`); }}>Quellenverzeichnis</button>
                <button disabled={(overlay !== 'NONE' && overlay !== 'PAGES')} onClick={() => this.setOverlay(OVERLAY_TYPES.IMPRESSUM)}>Impressum</button>
                <button onClick={() => this.setOverlay(OVERLAY_TYPES.CONTACT)}>Kontakt</button>
                <button onClick={() => this.setOverlay(OVERLAY_TYPES.DATA_PROTECTION)}>Datenschutz</button>
              </div>
            }
            {darkenSurroundings && <div className={styles.headerDarkener}>
              <div className={styles.darken} />
              <div className={styles.notDarken} />
              <div className={styles.darken} />
            </div>
            }
          </div >
        );
      default:
        return null;
    }
  }

  setOverlay = e => {
    //const { page } = e.currentTarget.dataset;
    this.setMetaMenu(false);

    this.props.setOverlay(e);
  }

  setMetaMenu = value => this.setState({
    metaMenu: value
  });

  toggleMetaMenu = () => this.setMetaMenu(!this.state.metaMenu)

  closeMetaMenu = e => {
    if ((this.node && this.node.contains(e.target)) || (e.target.id === 'menuButton')) {
      return;
    }

    this.setState({
      metaMenu: false
    });
  }
}

const mapStateToProps = ({ magazines: { showHotspots, overlay, magazines, currentMagazineId }, darkenSurroundings: { darkenSurroundings } }) => {
  return {
    showHotspots,
    overlay,
    currentMagazine: magazines.filter(x => x.id === currentMagazineId)[0],
    darkenSurroundings
  };
};

const mapDispatchToProps = {
  setOverlay: actions.setOverlay,
  toggleHotspots: actions.toggleHotspots,
  setDarkenSurroundings: darkenSurroundingsActions.setDarkenSurroundings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
