import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TaskTemplate } from '@ovos-media/templates/dist/sp';

import { generateUrl } from '../../../../domains/uploads';
import * as Templates from '../../../../templates';
import Header from '../../Components/Header';
import Button from '../../../Button';
import { TextWithAudio } from '@ovos-media/templates/dist/sp';

import { TEMPLATE_TYPE_MAP, VALIDATION } from '../../../constants';

import styles from '../styles/taskPair.less';

export default class TaskPair extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstTime: true, // ugly workaround for transition, should be hadnled differently
      tasks: [props.task],
      startSlide: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.$taskTempalte && !nextProps.enteredAnswer) {
      this.$taskTempalte.resetScroll(); // set scrollTop position to 0
    }
    if (this.props.setup !== nextProps.setup) {
      setTimeout(() => this.setState({ firstTime: false }), 1000);
    } else if (this.props.task.id !== nextProps.task.id) {
      this.state.tasks.push(nextProps.task);
      this.setState({
        startSlide: true
      });
    } else if (this.props.task.id === nextProps.task.id) {
      const tasks = this.state.tasks;
      tasks[0] = nextProps.task;
      this.setState({
        tasks
      });
    }
  }

  render() {
    const { showDatapools, datapoolName, information, showInformation, toggleInformation, startRotation, backgroundColor,
      togglePopup, task, canContinue, verify, setup, firstPlayer, player } = this.props;
    const { firstTime, tasks, startSlide } = this.state;
    if (setup) {
      return this.setupScreen();
    }
    return (
      <div className={styles.pairContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          information={information}
          showInformation={showInformation}
          toggleInformation={toggleInformation}
          showTaskInformation={!!task.description}
          onClose={togglePopup}
        />
        <div className={`${styles.backgroundLeft} ${styles.transition} ${firstPlayer && styles.playerActive}`}>
          <div className={`${styles.sideBar} ${styles[backgroundColor]}`} />
          <div className={`${styles.topBar} ${styles[backgroundColor]}`}>
            <div className={styles.label}>{player.player1}</div>
          </div>
        </div>
        <div className={`${styles.backgroundRight} ${!firstTime && styles.transition} ${!firstPlayer && styles.playerActive}`}>
          <div className={`${styles.sideBar} ${styles[backgroundColor]}`} />
          <div className={`${styles.topBar} ${styles[backgroundColor]}`}>
            <div className={styles.label}>{player.player2}</div>
          </div>
        </div>
        <div className={styles.containerOutline}>
          <div className={styles.containerOutlineBackground} />
        </div>
        <div className={`${styles.taskContentContainer} ${backgroundColor && styles.blockInput}`}>
          <div className={`${styles.border} ${startRotation && styles.rotation} ${startSlide && styles.animateLeft}`} onAnimationEnd={this.onAnimationEnd}>
            {tasks.map(task =>
              <div key={task.id} className={styles.borderContent}>
                {this.renderCircles()}
                <TaskTemplate
                  ref={taskTemplate => { this.$taskTempalte = taskTemplate; }}
                  className={styles.template}
                  question={React.createElement(
                    Templates[TEMPLATE_TYPE_MAP[task.question.type]],
                    {
                      ...task.question
                    })}
                  answers={React.createElement(
                    Templates[TEMPLATE_TYPE_MAP[task.answerGroup.type]],
                    {
                      inputId: this.context.getInputId,
                      multipleChoice: task.answerGroup.config ? JSON.parse(task.answerGroup.config).validation === VALIDATION.MULTIPLE : null,
                      ...task.answerGroup
                    })}
                />
              </div>
            )}
          </div>
          <div className={styles.bottomBar}>
            <div className={styles.bottomBarContent}>
              <Button icon="arrow-right" disabled={!canContinue} onClick={verify} />
            </div>
          </div>
        </div>
      </div >
    );
  }

  onAnimationEnd = () => {
    const { startSlide } = this.state;
    if (startSlide) {
      this.state.tasks.shift();
      this.setState({ startSlide: false });
    }
  }

  setupScreen = () => {
    const {
      imageId, datapoolName, description, showDatapools, information, showInformation,
      toggleInformation, togglePopup, setPlayerName, player, finishSetup
    } = this.props;
    return (
      <div className={styles.pairContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          information={information}
          showInformation={showInformation}
          toggleInformation={toggleInformation}
          onClose={togglePopup}
        />
        <div className={styles.descriptionContainer}>
          <img src={generateUrl(imageId)} alt="datapool name" />
          <TextWithAudio className={styles.description} text={description} />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.title}>Ici, vous pouvez entrer vos noms:</div>
          <div className={styles.names}>
            <div className={styles.nameBox}>
              <input placeholder="Joueur 1" value={player.player1} onFocus={this.onFocus} onBlur={e => this.onBlur(e, 'Joueur 1')} onChange={e => setPlayerName('player1', e.target.value)} maxLength="10" />
            </div>
            <div className={styles.nameBox}>
              <input placeholder="Joueur 2" value={player.player2} onFocus={this.onFocus} onBlur={e => this.onBlur(e, 'Joueur 2')} onChange={e => setPlayerName('player2', e.target.value)} maxLength="10" />
            </div>
          </div>
        </div>
        <div className={styles.setupBottom}>
          <Button icon="arrow-right" onClick={finishSetup} />
        </div>
      </div>
    );
  }

  onFocus = e => {
    e.target.placeholder = '';
  }

  onBlur = (e, placeholder) => {
    e.target.placeholder = placeholder;
  }

  renderCircles = () => {
    const { rounds, taskProgress, firstPlayer, player } = this.props;
    const states = [];
    const playerProgress = taskProgress.filter(progress => progress.player === (firstPlayer ? player.player1 : player.player2));
    for (let i = 0; i < rounds; i++) {
      if (i <= playerProgress.length - 1) {
        states.push(
          <div key={`full_${i}`} className={`${styles.progressCircle} ${this.getColor(playerProgress[i].status)} `} />
        );
      } else {
        states.push(
          <div key={`empty_${i}`} className={styles.progressCircle} />
        );
      }
    }
    return <div className={`${styles.progressContainer} ${!firstPlayer && styles.reverse}`}>{states}</div>;
  }

  getColor = status => {
    if (status === 'right') {
      return styles.green;
    } else if (status === 'answered') {
      return styles.grey;
    } else if (status === 'wrong') {
      return styles.red;
    }
  };
}

TaskPair.propTypes = {
  setPlayerName: PropTypes.func.isRequired,
  startRotation: PropTypes.bool
};

TaskPair.defaultProps = {
  startRotation: false
};

TaskPair.contextTypes = {
  getClozeAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string
    })
  ),
  getInputId: PropTypes.string
};
