import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

export default class DoughnutChart extends Component {
  render() {
    const { colors, data, radius, hole } = this.props;
    const cleanData = data.map(d => d ? d : 0); // if value is undefined, set it to 0
    return (
      <Pie
        data={cleanData}
        radius={radius}
        hole={hole}
        colors={colors}
      />
    );
  }
};

DoughnutChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  radius: PropTypes.number,
  hole: PropTypes.number
};

DoughnutChart.defaultProps = {
  colors: ['green', 'yellow', 'red', 'grey'],
  data: [3, 3, 2],
  radius: 85,
  hole: 50
};

class Pie extends Component {
  render() {
    const { data, colors, radius, hole } = this.props;
    const diameter = radius * 2;
    let sum = data.reduce((acc, cur) => {
      return acc + cur;
    }, 0);
    let startAngle = -90;
    return (
      <svg className={styles.svg} width={diameter} height={diameter} viewBox={'0 0 ' + diameter + ' ' + diameter} xmlns="http://www.w3.org/2000/svg" version="1.1">
        {data.map((slice, sliceIndex) => {
          const nextAngle = startAngle;
          if (!slice) return null;
          const angle = (slice / sum) * 359.99; // weird fix to allow one full circle
          startAngle += angle;
          const color = colors[sliceIndex % colors.length];
          return <Slice
            key={sliceIndex}
            value={slice}
            startAngle={nextAngle}
            angle={angle}
            radius={radius}
            hole={radius - hole}
            className={styles[color]}
          />;
        })}
      </svg>
    );
  }
};

class Slice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: ''
    };
  }

  componentDidMount() {
    this.setState({ path: '' });
    this.draw();
  }

  render() {
    const { className } = this.props;
    const { path } = this.state;
    return (
      <g overflow="hidden">
        <path
          d={path}
          className={className}
        />
      </g>
    );
  }

  draw(s = 0) {
    const { angle, startAngle, radius, hole } = this.props;
    const path = [];
    const step = angle / (37.5 / 2);

    if (s + step > angle) {
      s = angle;
    }
    // Get angle points
    const a = this.getAnglePoint(startAngle, startAngle + s, radius, radius, radius);
    const b = this.getAnglePoint(startAngle, startAngle + s, radius - hole, radius, radius);

    path.push('M' + a.x1 + ',' + a.y1);
    path.push('A' + radius + ',' + radius + ' 0 ' + (s > 180 ? 1 : 0) + ',1 ' + a.x2 + ',' + a.y2);
    path.push('L' + b.x2 + ',' + b.y2);
    path.push('A' + (radius - hole) + ',' + (radius - hole) + ' 0 ' + (s > 180 ? 1 : 0) + ',0 ' + b.x1 + ',' + b.y1);

    // Close
    path.push('Z');

    this.setState({
      path: path.join(' ')
    });

    if (s < angle) {
      // this.draw(s + step);
      setTimeout(() => { this.draw(s + step); }, 16);
    }
  }

  getAnglePoint(startAngle, endAngle, radius, x, y) {
    const x1 = x + radius * Math.cos(Math.PI * startAngle / 180);
    const y1 = y + radius * Math.sin(Math.PI * startAngle / 180);
    const x2 = x + radius * Math.cos(Math.PI * endAngle / 180);
    const y2 = y + radius * Math.sin(Math.PI * endAngle / 180);

    return { x1, y1, x2, y2 };
  }
};