import React from 'react';
import { ImageTextLong } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';
import { IMAGE_THUMBNAIL } from '../components/constants';

export default ({ file_image_id, file_background_image_id, file_audio_id, ...props }) => (
  <ImageTextLong
    {...props}
    imageSource={generateUrl(file_image_id, { width: IMAGE_THUMBNAIL.CONTENT.WIDTH })}
    backgroundImageSource={generateUrl(file_background_image_id, { width: IMAGE_THUMBNAIL.PAGE.WIDTH })}
    audioSource={generateUrl(file_audio_id)}
  />
);