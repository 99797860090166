import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { TextWithAudio } from '@ovos-media/templates/dist/sp';

import Header from '../../Components/Header';
import Button from '../../../Button';
import DoughnutChart from '../../../Doughnut';

import styles from '../styles/result.less';

import spawnIcon from '../../functions/spawnIcon';
import { generateUrl } from '../../../../domains/uploads';
import { RESULT_SPAWN_ICONS } from '../../../constants';

import appVersion from '../../../../helpers/appVersion';
import { writeFile, createFile } from '../../../../helpers/downloadFromBrowser';

let spawnElementsTimeout = [];

export default class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChart: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showChart !== this.state.showChart && this.$buttons) {
      for (let index = 0; index < RESULT_SPAWN_ICONS; index++) {
        this.spawn();
      }
    }
  }

  componentWillUnmount() {
    for (let index = 0; index < spawnElementsTimeout.length; index++) {
      clearTimeout(spawnElementsTimeout[index]);
    }
  }

  render() {
    const { showDatapools, datapoolName, showInformation, information, toggleInformation, onClose,
      restart, data, sentences } = this.props;
    const { showChart } = this.state;

    const element = document.createElement('a');
    const text = sentences.map(d => d.text).join('\r\n');
    const blob = new Blob(['\ufeff', text], { type: 'text/plain' });
    return (
      <div className={styles.resultContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          showInformation={showInformation}
          information={information}
          toggleInformation={toggleInformation}
          onClose={onClose}
        />
        <div className={styles.containerContent}>
          {showChart
            ? (
              <Fragment>
                <div className={styles.chart}>
                  <div className={styles.title}>Dein Ergebnis</div>
                  <DoughnutChart
                    data={data}
                  />
                  <div className={styles.circleContainer} >
                    {this.showResult()}
                  </div>
                </div>
                <div ref={buttons => this.$buttons = buttons} className={styles.finishedButtons}>
                  <Button icon="close-large" classes={styles.button} onClick={onClose} />
                  <Button icon="repeat" classes={styles.button} onClick={restart} />
                </div>
              </Fragment>
            )
            : (
              <Fragment>
                <div className={styles.results}>
                  <div className={styles.title}>Der richtige Lösungssatz lautet:</div>
                  <div className={styles.sentences}>
                    {sentences.map(d => <TextWithAudio className={styles.textWithAudio} key={d.id} text={d.text} audioSource={generateUrl(d.file_audio_id)} />)}
                  </div>
                </div>
                <div className={styles.downloadButton} onClick={() => this.downloadSentences(element, blob)}>
                  <Button icon="download" classes={styles.button} />
                  <div className={styles.downloadLabel}>Ergebnisse herunterladen</div>
                </div>
                <div className={styles.buttonsBottomRight}>
                  <Button icon="arrow-right" classes={styles.button} onClick={this.nextSentenceOrResult} />
                </div>
              </Fragment>
            )
          }
        </div>
      </div>
    );
  }

  nextSentenceOrResult = () => {
    const { finished, nextSentence } = this.props;
    return finished ? this.setState({ showChart: true }) : nextSentence();
  }

  showResult = () => {
    const { data, colors } = this.props;
    return data.map((d, i) => {
      let text = '';
      switch (i) {
        case 0:
          text = <span>richtig</span>;
          break;
        case 1:
          text = <span>richtig<br />(2. Versuch)</span>;
          break;
        case 2:
          text = <span>falsch</span>;
          break;
        default:
          break;
      }
      const color = colors[i % colors.length];
      return (
        <div key={`${i}_${d}`} className={styles.resultInfo}>
          <div className={`${styles.circle} ${styles[color]}`} />
          <div>
            {text}
          </div>
        </div>
      );
    });
  }

  downloadSentences = (element, blob) => {
    const { datapoolName } = this.props;

    if (appVersion.isIpad) {
      const filename = `${datapoolName}.txt`;
      const dir = appVersion.isIpad
        // eslint-disable-next-line
        ? cordova.file.cacheDirectory
        // eslint-disable-next-line
        : cordova.file.externalCacheDirectory;

      window.resolveLocalFileSystemURL(
        dir,
        async dirEntry => {
          const fileEntry = await createFile(dirEntry, filename);
          await writeFile(fileEntry, blob);

          /**
           * iOS, for some logical reason, does not show the dialog inside the screen, but somewhere next to your device
           * Because "rect is needed".
           * I shit you not: https://github.com/pwlin/cordova-plugin-file-opener2/issues/145 & https://github.com/pwlin/cordova-plugin-file-opener2/issues/167
           * Why?
           * Only god knows.
           */
          const method = appVersion.isIpad ? 'open' : 'showOpenWithDialog';

          // eslint-disable-next-line
          cordova.plugins.fileOpener2[method](fileEntry.toURL(), blob.type, {
            error: function (e) {
              console.log(
                e,
                'Error status: ' + e.status + ' - Error message: ' + e.message
              );
            },
            success: function () {
              console.log('file opened successfully');
            }
            // rect: [] here you could add an array of random numbers to position the ios native open dialogue, because why not.
            // maybe you want it top left, top right, bottom - who knows your flavor??
            // imagin this: you could show a frkn huge arrow pointing at the dialogue. aha? awesome yes.
          });
        });
    } else {
      element.target = '_blank';
      element.href = URL.createObjectURL(blob);
      element.download = `${datapoolName}.txt`;
      element.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      element.remove();
    }
  }

  spawn = () => {
    const element = spawnIcon(this.$buttons, styles.emoji);

    spawnElementsTimeout.push(setTimeout(() => {
      this.$buttons.removeChild(element.icon);
      this.spawn();
    }, element.duration * 1000 + 1000));  // remove element after duration + 1 second extra
  }
}

Result.propTypes = {
  restart: PropTypes.func,
  nextSentence: PropTypes.func,
  sentences: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    file_audio_id: PropTypes.string
  })).isRequired,
  finished: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string)
};

Result.defaultProps = {
  restart: () => { },
  nextSentence: () => { },
  finished: false,
  colors: ['green', 'yellow', 'red']
};