import { store } from '../../../domains/redux/store';

export function itemFromPoolSorted(items, currentUsedTasks) {
  return items[currentUsedTasks];
}

export function itemFromPoolUnsorted(datapoolId, items, currentUsedItems, clearUsedItems, setUsedItems) {
  const usedItems = store.getState().container.usedItems;
  let index = usedItems.findIndex(usedDP => usedDP.datapool === datapoolId); // find the array of items for the current datapool
  if (index > -1 && usedItems[index].items.length === items.length) {
    clearUsedItems(datapoolId);
    index = -1;
  }
  const openItems = items.reduce((acc, key) => {
    if (
      (index === -1 || usedItems[index].items.findIndex(id => id === key.id) === -1) &&
      (!currentUsedItems.length || currentUsedItems.findIndex(id => id === key.id) === -1)
    ) {
      acc.push(key);  // only push tasks that have not been used yet
    }
    return acc;
  }, []);
  const item = openItems[Math.floor(Math.random() * openItems.length)];
  setUsedItems(datapoolId, item.id);
  currentUsedItems.push(item.id);

  return item;
}