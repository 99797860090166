import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { matomo } from '../../../domains/matomo';
import { Popup } from '../../Popup';
import Datapools from '../Components/Datapools';
import Result from './Components/Result';
import TaskSequence from './Components/TaskSequence';

import { CONTAINER_SCREENS, ANIMATION_TYPE, CONTAINER_WAITING_TIME, ITEMS_TYPE, NEXT_TASK_TYPE, TASK_TYPE_MAP, HEADER_INFO_TYPE } from '../../constants';

import styles from './styles.less';
import verifyAnswer from '../functions/verifyAnswer';
import taskCanContinue from '../functions/taskCanContinue';
import nextTask from '../functions/nextTask';

class SequenceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenType: props.screenType,
      datapool: props.currentDatapool,
      task: props.item,
      showInformation: '',
      information: '',
      startAnimation: '',
      firstTry: true,
      taskProgress: [],
      backgroundColor: false,
      showPopup: false,
      setup: true,
    };
  }

  render() {
    const { screenType, datapool, task, showInformation, information, taskProgress, showPopup, backgroundColor, startAnimation, setup } = this.state;
    const { onClose, container, enteredAnswer, getClozeAnswers } = this.props;
    let contentComponent = null;
    switch (screenType) {
      case CONTAINER_SCREENS.DATAPOOLS:
        contentComponent = <Datapools container={container} onClose={onClose} setDatapool={this.setDatapool} />;
        break;
      case CONTAINER_SCREENS.RESULT:
        const resultData = taskProgress.reduce((acc, curr) => {
          acc[curr] ? acc[curr] += 1 : acc[curr] = 1;
          return acc;
        }, {});
        const data = resultData.first > 0 || resultData.second > 0 || resultData.wrong > 0 ? [resultData.first, resultData.second, resultData.wrong] : [resultData.answered];
        contentComponent =
					<Result
					  showDatapools={this.reset}
					  datapoolName={datapool.name}
					  showInformation={showInformation}
					  information={datapool.description}
					  toggleInformation={this.toggleInformation}

					  onClose={onClose}
					  restart={this.replay}
					  data={data}
					/>;
        break;
      case CONTAINER_SCREENS.CONTAINER_SEQUENCE:
        contentComponent = task
          ? <TaskSequence
            datapoolName={datapool.name}
            showDatapools={this.reset}
            togglePopup={this.togglePopup}
            showInformation={showInformation}
            information={information}
            toggleInformation={this.toggleInformation}

            backgroundColor={backgroundColor}
            rounds={datapool.items_per_session}
            taskProgress={taskProgress}
            task={task}
            enteredAnswer={enteredAnswer}

            finishSetup={this.finishSetup}
            setup={setup}
            description={datapool.description}
            imageId={datapool.file_image_id}

            verify={this.verify}
            canContinue={taskCanContinue(task.answerGroup, enteredAnswer, getClozeAnswers)}
            startAnimation={startAnimation}
          />
          : null;
        break;
      default:
        break;
    }
    return (
      <div className={styles.container}>
        {contentComponent}
        <Popup
          show={showPopup}
          title="Attention!"
          text={<span>Est-ce que tu veux vraiment arrêter le jeu?<br />Tous les progrès seront perdus si tu fermes le jeu maintenant.</span>}
          accept={this.accept}
          cancel={this.cancel}
        />
      </div>
    );
  }

  componentDidMount = () => {
    this.timeoutSlot = null;
    this.timeoutSlot2 = null;
  };

	togglePopup = () => {
	  const { showPopup } = this.state;
	  this.setState({
	    showPopup: !showPopup
	  });
	}

	accept = () => {
	  const { onClose } = this.props;
	  onClose();
	}

	cancel = () => {
	  this.togglePopup();
	}

	toggleInformation = type => {
	  const { showInformation, task, datapool } = this.state;
	  this.setState({
	    showInformation: showInformation !== type ? type : false,
	    information: type === HEADER_INFO_TYPE.TASK ? task.description : type === HEADER_INFO_TYPE.DATAPOOL ? datapool.description : ''
	  });
	  
	  if((showInformation && type !== showInformation) || !showInformation) {
	    if(type === HEADER_INFO_TYPE.TASK) {
	      matomo(['trackEvent', 'ClickedHelp', `${task.name} (${task.id})`]);
	    } else {
	      matomo(['trackEvent', 'ClickedBackgroundInfo', `${datapool.name} (${datapool.id})`]);
	    }
	  }
	}

	verify = () => {
	  const { enteredAnswer, getClozeAnswers } = this.props;
	  const { task, taskProgress, firstTry } = this.state;

	  const correct = verifyAnswer(task.answerGroup, enteredAnswer, getClozeAnswers);

	  let newTask = false;
	  if (task.answerGroup.type === TASK_TYPE_MAP.ANSWER_FACT_FICTION) {
	    if (correct) {
	      taskProgress.push('first');
	      this.setState({
	        backgroundColor: 'green'
	      });
	    } else {
	      taskProgress.push('wrong');
	      this.setState({
	        backgroundColor: 'red'
	      });
	    }
	    newTask = true;
	  } else if (correct === 'Speak') {
	    taskProgress.push('answered');
	    this.setState({
	      backgroundColor: 'grey'
	    });
	    newTask = true;
	  } else if (correct) {
	    if (firstTry) {
	      taskProgress.push('first');
	    }
	    else {
	      taskProgress.push('second');
	    }
	    this.setState({
	      backgroundColor: 'green'
	    });
	    newTask = true;
	  } else {
	    if (firstTry) {
	      setTimeout(() => this.setState({
	        firstTry: false,
	        startAnimation: ANIMATION_TYPE.ROTATION,
	        backgroundColor: ''
	      }), CONTAINER_WAITING_TIME);
	    } else {
	      taskProgress.push('wrong');
	      newTask = true;
	    }
	    this.setState({
	      backgroundColor: 'red'
	    });
	  }
	  this.continue(newTask);
	}

	continue = newTask => {
	  const { setAnswer, addToClozeAnswers, enteredAnswer } = this.props;
	  const { taskProgress, datapool } = this.state;
	  let funcAfterTimeout = null;
	  if (datapool.items_per_session === taskProgress.length) {
	    funcAfterTimeout = () => {
	      this.setState({
	      screenType: CONTAINER_SCREENS.RESULT,
	      backgroundColor: '',
	      task: null
	      });
				
	      matomo(['trackEvent', 'FinishedDatapool', `${datapool.name} (${datapool.id})`]);
	    };
	  } else if (newTask) {
	    funcAfterTimeout = () => this.setTask(enteredAnswer);
	  }
	  this.timeoutSlot = setTimeout(() => { setAnswer(); addToClozeAnswers(); }, CONTAINER_WAITING_TIME);
	  this.timeoutSlot2 = setTimeout(funcAfterTimeout, CONTAINER_WAITING_TIME);
	}

	replay = () => {
	  const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
	  const { datapool } = this.state;
	  resetCurrentUsedItems(datapool);
	  this.setState({
	    screenType: container.type,
	    startAnimation: '',
	    firstTry: true,
	    taskProgress: []
	  }, () => this.setState({ task: getItemFromPool(datapool, ITEMS_TYPE.TASKS) }));
	}

	reset = () => {
	  const { resetCurrentUsedItems } = this.props;
	  const { datapool } = this.state;

	  clearTimeout(this.timeoutSlot);
	  clearTimeout(this.timeoutSlot2);

	  resetCurrentUsedItems(datapool);
	  this.setState({
	    screenType: CONTAINER_SCREENS.DATAPOOLS,
	    startAnimation: '',
	    datapool: null,
	    task: null,
	    showInformation: false,
	    firstTry: true,
	    taskProgress: []
	  });
	}

	setDatapool = id => {
	  const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
	  const datapool = container.datapools.find(datapool => datapool.id === id);
	  resetCurrentUsedItems(datapool);
	  this.setState({
	    datapool: datapool,
	    screenType: container.type,
	    task: getItemFromPool(datapool, ITEMS_TYPE.TASKS),
	    taskProgress: [],
	    setup: true,
	    backgroundColor: ''
	  });
	}

	setTask = enteredAnswer => {
	  const { getItemFromPool } = this.props;
	  const { datapool, task } = this.state;
	  const hasDependency = task.answerGroup.config ? JSON.parse(task.answerGroup.config).dependencyMode : false;
	  this.setState({
	    task: hasDependency ? nextTask(datapool.tasks, task.answerGroup, enteredAnswer, NEXT_TASK_TYPE.TASK) : getItemFromPool(datapool, ITEMS_TYPE.TASKS),
	    firstTry: true,
	    backgroundColor: '',
	    startAnimation: ''
	  });
	}

	finishSetup = () => {
	  const { datapool } = this.state;
		
	  this.setState({
	    setup: false
	  });
		
	  matomo(['trackEvent', 'StartedDatapool', `${datapool.name} (${datapool.id})`]);
	}
}

SequenceContainer.propTypes = {
  setAnswer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  enteredAnswer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ])
};

SequenceContainer.defaultProps = {
};

export default SequenceContainer;