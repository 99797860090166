import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';

import client from './client';

class GraphqlContainer extends Component {
  render() {
    const { children, client } = this.props;

    return (
      <ApolloProvider client={client}>
        {children || <div />}
      </ApolloProvider>
    );
  }
}

GraphqlContainer.defaultProps = {
  client
};

GraphqlContainer.propTypes = {
  client: PropTypes.object // eslint-disable-line
};

export default GraphqlContainer;