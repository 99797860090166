import { batchActions } from 'redux-batched-actions';
import { actions } from '../redux';
import parseJWT from './parseJwt';
import { store } from '../../redux/store';

export default async function login(
  options = { username: '', email: '', password: '', token: false },
  getToken = require('./getToken').default
) {
  const token = await getToken(options);
  
  if (token) {
    const payload = parseJWT(token);
    
    store.dispatch(batchActions([
      actions.setUser(payload),
      actions.setToken(token)
    ]));

    return true;
  }

  return false;
}
