import React, { Component } from 'react';

import styles from './styles.less';


class ContactOverlay extends Component {

  render() {

    return (
      <div className={styles.overlay}>
        <div className={styles.container}>
          <h1>Kontakt</h1>
          <h2>Schulverlag plus AG</h2>
          Belpstrasse 48<br />
          CH-3000 Bern 14<br />
          <a href="mailto:info@schulverlag.ch">info@schulverlag.ch</a><br />
          <a href="www.schulverlag.ch" target="_blank">www.schulverlag.ch</a><br />
          <br /><br />
          Telefon +41 58 268 14 14<br />
          Fax +41 58 268 14 15<br />
          <a href="mailto:info@schulverlag.ch">info@schulverlag.ch</a><br />
          <a href="www.schulverlag.ch" target="_blank">www.schulverlag.ch</a><br /><br />
          <img className={styles.logo} src="./svp-logo.png" alt="" />

          <h2>Support</h2>
          Antworten zu den häufig gestellten Fragen finden Sie in unseren FAQ:<br />
          <a href="https://shop.schulverlag.ch/faq">https://shop.schulverlag.ch/faq</a><br /><br />
          Wurde Ihre Frage nicht beantwortet? Dann wenden Sie sich bitte an unser Support- und Serviceteam unter support@schulverlag.ch
        </div>
      </div>
    );
  }
}

export default ContactOverlay;
