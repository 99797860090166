import React, { Component } from 'react';

import BasicHotspot from './BasicHotspot';
import EditorHotspot from './EditorHotspot';

import styles from './stylesHotspots.less';

class Hotspots extends Component {

  render() {
    const { hotspots, showHotspots, onHotspotClick, editMode, isScene, deactivateHotspots } = this.props;

    const hotspotsParsedPosition = hotspots.map(hotspot => {
      const position = JSON.parse(hotspot.position);

      return ({ ...hotspot, position });

    });

    return (
      <div className={`${styles.hotspots} ${deactivateHotspots ? styles.deactivate : '' }`}>
        {!editMode ? hotspotsParsedPosition.map(hotspot =>
          <BasicHotspot
            key={`${hotspot.id}${editMode}`}
            hotspot={hotspot}
            onHotspotClick={onHotspotClick}
            showHotspot={showHotspots}
            isScene={isScene}
          />
        ) :
          hotspotsParsedPosition.map(hotspot =>
            <EditorHotspot
              key={`${hotspot.id}${editMode}`}
              hotspot={hotspot}
            />
          )
        }
      </div>
    );
  }
}

Hotspots.defaultProps = {
  hotspots: [],
  showHotspots: false,
  editMode: false,
  onHotspotClick: () => { },
  isScene: false
};

export default Hotspots;