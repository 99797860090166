import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as authActions } from '../../domains/auth/redux';
import { actions as licenseActions } from '../../domains/license/redux';
import { actions as magazinesActions } from '../../domains/magazines/redux';

import appVersion from '../../helpers/appVersion';
import { openUrl } from '../../helpers/openUrlHandler';
import { version } from '../../../config/version';
import { remoteLogin, login } from '../../domains/auth/functions';
import { ButtonWithText } from '../../components';

import styles from './styles.less';

class AuthOverlay extends Component {

  constructor() {
    super();

    this.state = {
      username: '',
      password: '',
      isLoggingIn: false,
      useNativeLogin: false
    };
  }

  render() {
    const { user, showNoLicenseWarning } = this.props;

    if (!user) {
      return (
        <div className={styles.authOverlay}>
          <div className={styles.container}>
            <div className={styles.header}>
              <img className={styles.logo} src="./svp-logo.png" alt="" />
              <span className={styles.version}>Version {version}</span>
            </div>
            <div className={styles.authBox}>
              {showNoLicenseWarning && <div className={styles.noLicencesAlert}>
                Autorisierung fehlgeschlagen. Bitte melden Sie sich bei Ihrer Organisation.
              </div>}
              <ButtonWithText color="blue-dark" icon="enter" className={styles.loginButton} onClick={() => this.login()}>Anmelden</ButtonWithText>
            </div>
          </div>
        </div >
      );
    }
    return null;
  }

  login = () => {
    // for ios to recognize it should open the app after logging in with the
    // Safariviewcontroller, the service needs to send the custom scheme
    // schulverlag. And in order for the service to know when to send the
    // custom scheme and when to send the normal url, we need to pass
    // the platform information to the service here
    const platform = Object.keys(appVersion).find(key => appVersion[key]);

    // this is used so that on ios we use the safariviewcontroller, other platforms
    // will fallback to open the url on the same page
    openUrl(`${process.env.REACT_APP_AUTHENTICATION_URL}/?p=${platform}`, false, '_self');
  }

  loginFn = e => {
    const { clearAuth, clearUnlocked } = this.props;
    const { username, password, useNativeLogin } = this.state;

    clearAuth();
    clearUnlocked();

    //e.preventDefault();

    this.setState({ isLoggingIn: true });

    if (useNativeLogin) {
      login({
        username,
        password
      });
    } else {
      remoteLogin({
        username,
        password
      });
    }

    // dirty fix
    setTimeout(() => {
      this.setState({ isLoggingIn: false, errorMessage: 'Authentifizierung fehlgeschlagen. Benutzername oder Kennwort falsch eingegeben. Bitte versuchen Sie es erneut.' });
    }, 3000);
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user: auth.user,
    showNoLicenseWarning: auth.showNoLicenseWarning
  };
};

const mapDispatchToProps = {
  clearAuth: authActions.clearAuth,
  clearUnlocked: licenseActions.clearUnlocked,
  setOverlay: magazinesActions.setOverlay
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthOverlay);
