import { TASK_TYPE_MAP } from '../../constants';

export default function taskCanContinue(answerGroup, enteredAnswer, getClozeAnswers) {
  switch (TASK_TYPE_MAP[answerGroup.type]) {
    case 'Array':
    case 'String':
      return !!enteredAnswer;
    case TASK_TYPE_MAP.ANSWER_FACT_FICTION:
    case 'Speak':
    case 'DualOrder':
      return enteredAnswer !== null;
    case 'Cloze':
      return getClozeAnswers.length === JSON.parse(JSON.parse(answerGroup.config).solutions)[0].length;
    default:
      return false;
  }
}
