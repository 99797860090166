import { DUPLICATED_MAGS } from '../domains/magazines/constants';

export const getDuplicatedMagIfExists = id => {
  let otherId = null;
  DUPLICATED_MAGS.forEach(d => {
    const newList = d.filter(i => i !== id);
    if (newList.length === 1) {
      otherId = newList[0];
    }
  });
  return otherId;
};