import { store } from '../../redux';

// Polyfill require.context for jest
if (process.env.NODE_ENV === 'test') {
  const fs = require('fs');
  const path = require('path');

  require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {};

    function readDirectory(directory) {
      fs.readdirSync(directory).forEach(file => {
        const fullPath = path.resolve(directory, file);

        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath);

          return;
        }

        if (!regularExpression.test(fullPath)) return;

        files[fullPath] = true;
      });
    }

    readDirectory(path.resolve(__dirname, base));

    function Module(file) {
      return require(file);
    }

    Module.keys = () => Object.keys(files);

    return Module;
  };
}

/**
 * Dynamically loads reducers which are exported from "preload.js" files.
 */
function getDynamicPreloaders(ctxRequire) {
  const reduxCtx = require.context('../../../', true, /preload\.js$/i);

  return reduxCtx.keys().reduce((acc, key) => {
    const preloader = reduxCtx(key).default;

    return [
      ...acc,
      preloader
    ];
  }, []);
}

export default function preload() {
  const preloaders = getDynamicPreloaders();
  return Promise.all(preloaders.map(loader => loader(store.dispatch, true)));
}