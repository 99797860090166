const defaultState = {
  files: {},
  blobLoadingProgress: 0,
  blobFilesToLoad: 0,
  blobLoadedFiles: 0
};

const SET_FILE_BLOB_URL = 'SET_FILE_BLOB_URL';
const SET_BLOB_LOADING_PROGRESS = 'SET_BLOB_LOADING_PROGRESS';
const DELETE_FILE_BLOB_URL = 'DELETE_FILE_BLOB_URL';
const RESET_CACHE = 'RESET_CACHE';

const setFileBlobUrl = ({ name, url }) => ({
  type: SET_FILE_BLOB_URL,
  payload: { name, url }
});

const deleteFileBlobUrl = name => ({
  type: DELETE_FILE_BLOB_URL,
  payload: { name }
});

const assetsReducer = (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case SET_BLOB_LOADING_PROGRESS: 
      return {
        ...state,
        blobLoadingProgress: payload.blobLoadingProgress,
        blobFilesToLoad: payload.blobFilesToLoad,
        blobLoadedFiles: payload.blobLoadedFiles
      };
    case SET_FILE_BLOB_URL:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.name]: payload.url
        }
      };
    case DELETE_FILE_BLOB_URL:
      delete state.files[payload.name];
      return {
        ...state,
        files: state.files
      };
    case RESET_CACHE:
      return defaultState;
    default: return state;
  }
};

export const reducerKey = 'assets';
export const reducer = assetsReducer;

export const actions = {
  setFileBlobUrl,
  deleteFileBlobUrl
};
