import { store } from '../redux';

import appVersion from '../../helpers/appVersion';
import downloadAsset from './downloadAsset';
import getThumbnailPath from './functions/getThumbnailPath';

const BASE_URL = process.env.REACT_APP_SERVICE_URL;

/**
 * This function generates a URL for the upload server
 * 
 * By default it will check whether the given "id" already exists
 * in the application states assets (e.g. blob url), and return this if possible.
 * 
 * If not, it will check whether the passed "id" is actually a path/url and
 * trigger the download and return the id as is.
 * 
 * If the id is not a path/url it will generate a URL to the upload service
 * and also download the file.
 * 
 * @param {*String} id The file_id or URL 
 * @param {*Boolean} forceUrl Whether an existing local file should be ignored and a URL should be produced
 * @param {*Boolean} preventDownload  Whether downloading the file should be prevented
 */
export default function generateUrl(id, thumbnailProps = null, forceUrl = false, preventDownload = false) {
  // in case the id is falsey, just return empty string
  if (id) {
    // if id is not a string, cast to string first
    if (typeof id !== 'string') {
      id = id.toString();
    }

    const fileId = id + getThumbnailPath(thumbnailProps);

    if (!forceUrl) {
      // if file was downloaded, return current bloburl
      const { files } = store.getState().assets;

      if (files[fileId]) {
        if (appVersion.isIpad || appVersion.isMobile) {
          return window.cordova.file.dataDirectory +  files[fileId];
        }
        return files[fileId];
      }
    }

    if (fileId.indexOf('/') > -1) {
      if (!preventDownload) {
        downloadAsset({ url: fileId, name: fileId });
      }

      return fileId;
    }

    // try to request file from upload service directly
    const url = `${BASE_URL}/upload/file/${fileId}`;

    if (!preventDownload) {
      downloadAsset({ url, name: fileId });
    }

    return url;
  }

  return '';
}
