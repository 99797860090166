import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Header, Page, PagesOverlay, MagazinesOverlay, AuthOverlay, ImpressumOverlay, ContactOverlay, HelpOverlay, DataProtectionOverlay } from '..';
import { actions as magazinesActions } from '../../domains/magazines/redux';
import { actions as notificationsActions } from '../../domains/notifications/redux';
import { actions as darkenSurroundingsActions } from '../../domains/magazines/blacklist/redux';
import AndroidBackbuttonHandler from '../../domains/backbutton/AndroidBackbuttonHandler';

import downloadMagazineContent from '../../domains/magazines/functions/downloadMagazineContent';

import appVersion from '../../helpers/appVersion';

import CustomizedToastContainer from '../Toast';
import { OVERLAY_TYPES } from '../constants.js';

import styles from './styles.less';

class OverlaySwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orientation: window.innerWidth < window.innerHeight
    };

    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    // window.addEventListener('beforeunload', this.beforeUnload);
  }

  componentDidUpdate(prevProps) {
    const { downloadMagazines, queuedMagazines, offlineMagazinesList, magazinesList } = this.props;
    this.startDownloadForNextMagazineInQueue(queuedMagazines, prevProps.queuedMagazines, downloadMagazines);
    this.startContentDownloadForNextMagazineInQueue(queuedMagazines, offlineMagazinesList, magazinesList);
  }

  startDownloadForNextMagazineInQueue = (queuedMagazines, prevQueuedMagazines, downloadMagazines) => {
    if (queuedMagazines.length > 0 && (!prevQueuedMagazines.length > 0 || queuedMagazines[0] !== prevQueuedMagazines[0])) {
      downloadMagazines(queuedMagazines[0]);
    }
  }

  startContentDownloadForNextMagazineInQueue = (queuedMagazines, offlineMagazinesList, magazinesList) => {
    const offlineMagazineIds = offlineMagazinesList.map(mag => mag.id);
    if (queuedMagazines.length > 0) {
      const offlineMagazine = offlineMagazinesList[offlineMagazinesList.findIndex(mag => mag.id === queuedMagazines[0])];
      if (offlineMagazine && offlineMagazineIds.includes(queuedMagazines[0]) && offlineMagazineIds.length > 0 && offlineMagazine.downloading && offlineMagazine.progress === 0) {
        downloadMagazineContent(magazinesList.find(mag => mag.id === queuedMagazines[0]));
      }
    }
  }

  componentWillMount() {
    this.setState({
      orientation: window.innerWidth < window.innerHeight
    });
  }

  componentDidMount() {
    const { notifications, removeNotificationToastIds } = this.props;
    window.addEventListener('orientationchange', this.handleOrientationChange);
    // window.addEventListener('beforeunload', this.beforeUnload);

    /* 
     If you are in magazines overlay, we show the toasts for the notifications.
     If you then go to another page and back, we would create new toasts for already shown notifications.
     Thus we save a toastId to every notification and only create a new toast if it has none.
     If you reload the page, the notification will keep its previous toastId.
     So in this case it will not create a toast, even though it should be recreated.
     So we remove the toastId here, so a new toast will be created.
     */
    removeNotificationToastIds(notifications);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationChange);
    // window.removeEventListener('beforeunload', this.beforeUnload);
  }

  // Ask the user if he really wants to leave this page.
  beforeUnload = event => {
    if (appVersion.isWeb) {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    }
  }

  handleOrientationChange = e => {
    this.setState({
      orientation: window.innerWidth < window.innerHeight // do not delete, even if not used, it triggers a rerender
    });
  }

  renderIsSmartphoneWarning = () => {
    return (
      <div className={styles.warningBackground}>
        <div className={styles.warning}>
          <div className={styles.warningTextSmarthphone}>Diese Seite kann am iPhone nicht dargestellt werden - probiere es doch auf einem iPad aus!</div>
        </div>
      </div>
    );
  }

  renderIsPortraitWarning = () => {
    return (
      <div className={styles.warningBackground}>
        <div className={styles.warning}>
          <div className={styles.warningText}>Diese Seite kann nicht im Hochkantmodus verwendet werden - drehe dein iPad um 90 Grad!</div>
          <div className={styles.warningDeviceOrientationImage} />
        </div>
      </div>
    );
  }

  render() {
    const { overlay, setOverlay, setDarkenSurroundings, loggedIn, imprint, sourceDirectory } = this.props;
    let overlayComponent;

    switch (overlay) {
      case OVERLAY_TYPES.MAGAZINES:
        overlayComponent = <MagazinesOverlay />;
        break;
      case OVERLAY_TYPES.AUTH:
        loggedIn && setOverlay(OVERLAY_TYPES.MAGAZINES);
        overlayComponent = <AuthOverlay />;
        break;
      case OVERLAY_TYPES.PAGES:
        overlayComponent = <PagesOverlay />;
        break;
      case OVERLAY_TYPES.IMPRESSUM:
        overlayComponent = <ImpressumOverlay setOverlay={setOverlay} imprint={imprint} />;
        break;
      case OVERLAY_TYPES.CONTACT:
        overlayComponent = <ContactOverlay setOverlay={setOverlay} imprint={imprint} />;
        break;
      case OVERLAY_TYPES.DATA_PROTECTION:
        overlayComponent = <DataProtectionOverlay setOverlay={setOverlay} imprint={imprint} />;
        break;
      case OVERLAY_TYPES.HELP:
        overlayComponent = [<HelpOverlay setOverlay={setOverlay} setDarkenSurroundings={setDarkenSurroundings} key="1" />, <Page key="" />];
        break;
      default:
        overlayComponent = <Page />;
        break;
    }

    const isSmartphone = navigator.platform === 'iPhone';
    const isPortrait = window.innerWidth < window.innerHeight; // the values in chrome debug view are false

    return (
      <div className={styles.container}>
        <CustomizedToastContainer />
        {appVersion.isMobile && <AndroidBackbuttonHandler />}
        {isSmartphone ? this.renderIsSmartphoneWarning() :
          isPortrait && appVersion.isIosWebApp ? this.renderIsPortraitWarning() :
            <Fragment>
              {overlay !== OVERLAY_TYPES.AUTH && <Header sourceDirectory={sourceDirectory} />}
              {overlayComponent}
            </Fragment>
        }
      </div>
    );
  }
}


const mapStateToProps = ({ magazines, auth, notifications: { notifications } }) => {
  const currentMagazine = magazines.currentMagazineId ? magazines.magazines.filter(x => x.id === magazines.currentMagazineId)[0] : null;
  return {
    overlay: magazines.overlay,
    imprint: currentMagazine ? currentMagazine.imprint : null,
    sourceDirectory: currentMagazine ? currentMagazine.file_source_directory_id : null,
    loggedIn: !!auth.user,

    magazinesList: magazines.magazines,
    offlineMagazinesList: magazines.offlineMagazinesList,
    queuedMagazines: magazines.queuedMagazines,
    notifications
  };
};

const mapDispatchToProps = {
  setOverlay: magazinesActions.setOverlay,
  downloadMagazines: magazinesActions.downloadMagazines,
  queueMagazinesForDownload: magazinesActions.queueMagazinesForDownload,
  removeNotificationToastIds: notificationsActions.removeToastIds,
  setDarkenSurroundings: darkenSurroundingsActions.setDarkenSurroundings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverlaySwitch);