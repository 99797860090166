import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../redux';
import localforage from 'localforage';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { getFileIds } from '../../uploads';

import preloadData from '../functions/preloadData';
import updateBlobUrls from '../functions/updateBlobUrls';

import performanceChecks from '../../../helpers/performanceChecks';
import appVersion from '../../../helpers/appVersion';

import { downloadAsset } from '../../uploads';
import deleteItems from '../../uploads/deleteItems';
import { actions as magazinesActions } from '../../magazines/redux';

import styles from './styles.less';

const mapStateToProps = ({ assets, magazines }) => {
  return {
    magazines: magazines.magazines,
    offlineMagazinesList: magazines.offlineMagazinesList,
    blobLoadingProgress: assets.blobLoadingProgress,
    blobFilesToLoad: assets.blobFilesToLoad,
    blobLoadedFiles: assets.blobLoadedFiles,
    files: assets.files,
  };
};

const mapDispatchToProps = {
  deleteMagazine: magazinesActions.deleteMagazine,
  resetQueuedMagazinesForDownload: magazinesActions.resetQueuedMagazinesForDownload,
  setClosedWithOngoingDownloads: magazinesActions.setClosedWithOngoingDownloads
};

class ConfigProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      migrating: false
    };
  }

  componentDidMount() {
    this.updateBlobUrls();
    this.preload();
  }

  preload = () => {
    this.setState(
      {
        loading: true
      },
      async () => {
        await preloadData();

        this.setState({
          loading: false
        });
      }
    );
  };

  updateBlobUrls = async () => {
    const { magazines } = this.props;

    await this.migrateOldFiles();
    this.checkForOngoingDownloadsAndDeleteThem();

    if (appVersion.isWeb || appVersion.isIosWebApp) {
      if (performanceChecks.enabled) {
        console.info('LOADING BLOBS START');
        performanceChecks.blobsStart = new Date();
      }
      // we only want to update blob urls for magazine thumbnails and audio zips if available at the start
      const filter = getFileIds(magazines, { depth: 1, testKey: (/(file_image_id|file_audio_archive_id)/) });

      await updateBlobUrls(filter);

      if (performanceChecks.enabled) {
        performanceChecks.blobsEnd = new Date();
        console.info('LOADING BLOBS END');
        console.info(performanceChecks.blobsDuration());
      }

      setTimeout(() => {
        this.setState({ ready: true });
        store.dispatch({
          type: 'SET_BLOB_LOADING_PROGRESS',
          payload: {
            blobLoadingProgress: 0,
            blobFilesToLoad: 0,
            blobLoadedFiles: 0
          }
        });
      }, 1000);
    } else {
      this.setState({
        ready: true
      });
    }
  };

  migrateOldFiles = async () => {
    const { files } = this.props;
    if (appVersion.isDesktop) {
      const filesToMigrate = {};

      this.setState({
        migrating: true
      });

      Object.entries(files).forEach(([key, value]) => {
        if (value.startsWith('blob')) {
          filesToMigrate[key] = value;
        }
      });

      await updateBlobUrls(false, true, filesToMigrate);
      localforage.clear();

      this.setState({
        migrating: false,
      });
    }
  }

  checkForOngoingDownloadsAndDeleteThem = () => {
    const { magazines, offlineMagazinesList, deleteMagazine, resetQueuedMagazinesForDownload, setClosedWithOngoingDownloads } = this.props;

    resetQueuedMagazinesForDownload();
    let deletedMagazinesMidDownload = 0;
    offlineMagazinesList.forEach(magazine => {
      if (magazine.progress < 100) {
        downloadAsset.stop([magazine.id]);
        const normalMag = magazines.find(mag => mag.id === magazine.id);
        deleteMagazine(magazine.id, normalMag.year.id);
        deleteItems(normalMag, magazine.id);
        ++deletedMagazinesMidDownload; // eslint-disable-line
      }
    });

    if (deletedMagazinesMidDownload > 0) {
      setClosedWithOngoingDownloads(true);
    }
  }

  render() {
    const {
      children,
      blobLoadingProgress,
      blobFilesToLoad,
      blobLoadedFiles
    } = this.props;
    const { ready, migrating } = this.state;

    if (!ready) {
      return (
        <div>
          <div className={styles.loadingProgress}>
            <LoadingSpinner width={100} progress={Math.floor(blobLoadingProgress)} caption={blobFilesToLoad > 0 ? `${blobLoadedFiles}/${blobFilesToLoad} ${migrating ? 'Migrating Assets - Please do not close the App' : 'Overview Assets'}` : ''} />
          </div>
        </div>
      );
    }

    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigProvider);
