import { batchActions } from 'redux-batched-actions';
import { actions } from '../redux';
import { actions as licenseActions } from '../../license/redux';
import locationHash from './locationHash';
import parseJWT from './parseJwt';
import { store } from '../../redux/store';

export default async function login(url) {
  const hashParams = locationHash(url);
  const { l } = hashParams;

  if (l) {
    window.location.hash = '';

    if (l === 'empty') {
      store.dispatch(batchActions([
        licenseActions.setUnlocked([]),
        actions.setShowNoLicenseWarning(true)
      ]));
    } else {
      const payload = parseJWT(l);
  
      if (payload) {
        store.dispatch(batchActions([
          actions.setUser(payload),
          actions.setToken(payload.idToken),
          licenseActions.setUnlocked(payload.licences),
          actions.setShowNoLicenseWarning(false)
        ]));
  
        return true;
      } else {
        return true;
      }
    }
  }

  return false;
}
