import React from 'react';
import { Slideshow } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';
import { IMAGE_THUMBNAIL } from '../components/constants';

export default ({ ...props }) => {
  return (<Slideshow
    {...props}
    slides={props.children.map(slide => {
      const isVideo = JSON.parse(slide.config).type === 'video';
      const file = isVideo ? slide.file_video_id : slide.file_image_id;
      return ({
        id: slide.id,
        path: generateUrl(file, isVideo ? null : { width: IMAGE_THUMBNAIL.PAGE.WIDTH }),
        name: slide.name,
        type: slide.config && JSON.parse(slide.config).type,
        scaling: slide.config && JSON.parse(slide.config).scaling,
        text: slide.text,
        audioSource: generateUrl(slide.file_audio_id)
      });
    })}
  />);
};