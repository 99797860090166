import React from 'react';
import { IndexSingle } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';
import { IMAGE_THUMBNAIL } from '../components/constants';

export default ({ file_image_id, file_background_image_id, ...props }) => {
  return (<IndexSingle
    {...props}
    imageSource={generateUrl(file_image_id, { width: IMAGE_THUMBNAIL.CONTENT.WIDTH })}
    backgroundImageSource={generateUrl(file_background_image_id, { width: IMAGE_THUMBNAIL.PAGE.WIDTH })}
    textElements={props.children.map(textElement => ({
      id: textElement.id,
      text: textElement.text,
      audioSource: generateUrl(textElement.file_audio_id)
    }))}
  />);
};