import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAnswerEntered, withClozeHandler } from '@ovos-media/templates/dist/sp';

import { CONTAINER_SCREENS, CONTAINER_TYPE_MAP, ITEMS_TYPE } from '../constants';
import { itemFromPoolSorted, itemFromPoolUnsorted } from './functions/itemFromPool';

import * as ContainerType from './index';

import { actions as containerActions } from '../../domains/container/redux';

class Container extends Component {
  constructor(props) {
    super(props);
    const datapools = props.container.datapools;

    this.currentUsedItems = datapools.length > 1 ? null : datapools[0].is_sorted ? 0 : [];

    const itemsType = props.container.type === CONTAINER_SCREENS.CONTAINER_COMBINATION ? ITEMS_TYPE.SENTENCES : ITEMS_TYPE.TASKS;
    this.screenType = datapools.length > 1 ? CONTAINER_SCREENS.DATAPOOLS : props.container.type;
    this.currentDatapool = datapools.length > 1 ? null : datapools[0];
    this.currentItem = datapools.length > 1 ? null : this.getItemFromPool(this.currentDatapool, itemsType);
  }

  render() {
    const { container, onClose, setAnswer, enteredAnswer, addToClozeAnswers, getClozeAnswers } = this.props;
    return (
      React.createElement(
        ContainerType[CONTAINER_TYPE_MAP[container.type]],
        {
          container: container,
          onClose: onClose,
          screenType: this.screenType,
          currentDatapool: this.currentDatapool,
          item: this.currentItem,
          getItemFromPool: this.getItemFromPool,
          resetCurrentUsedItems: this.resetCurrentUsedItems,
          setAnswer: setAnswer,
          enteredAnswer: enteredAnswer,
          addToClozeAnswers: addToClozeAnswers,
          getClozeAnswers: getClozeAnswers
        }
      )
    );
  }

  // either get task or sentence
  getItemFromPool = (datapool, type) => {
    const { setUsedItems, clearUsedItems } = this.props;
    const items =
      type === ITEMS_TYPE.TASKS
        ? datapool.tasks
        : type === ITEMS_TYPE.SENTENCES
          ? datapool.sentences
          : console.warn('No items found in datapool with id ', datapool.id);
    let item = null;
    if (datapool.is_sorted) {
      item = itemFromPoolSorted(items, this.currentUsedItems);
      this.currentUsedItems += 1;
    } else {
      item = itemFromPoolUnsorted(datapool.id, items, this.currentUsedItems, clearUsedItems, setUsedItems);
    }
    return item;
  }

  resetCurrentUsedItems = datapool => {
    const { setAnswer, addToClozeAnswers } = this.props;
    if (datapool.is_sorted) {
      this.currentUsedItems = 0;
    } else {
      this.currentUsedItems = [];
    }
    setAnswer();
    addToClozeAnswers();
  }
}

Container.propTypes = {
  setAnswer: PropTypes.func
};

Container.defaultProps = {
  setAnswer: () => { }
};

const mapStateToProps = ({ container }) => {
  return {
    usedItems: container.usedItems
  };
};

const mapDispatchToProps = {
  setUsedItems: containerActions.setUsedItems,
  clearUsedItems: containerActions.clearUsedItems
};

const WithCloze = withClozeHandler(Container);
const WithAnswers = withAnswerEntered(WithCloze);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithAnswers);
