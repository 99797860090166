import gql from 'graphql-tag';

export const getOverrideLoginStatus = gql`
  query overrideLoginStatus {
    overrideLoginStatus {
      results {
        key
        value
      }
    }
  }
`;
