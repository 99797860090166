import { TASK_TYPE_MAP, SPECIAL_CHARACTERS } from '../../constants';
import sanitizeHtml from 'sanitize-html';

export default function verifyAnswer(answerGroup, enteredAnswer, getClozeAnswers) {
  const pattern = new RegExp(SPECIAL_CHARACTERS, 'g');
  switch (TASK_TYPE_MAP[answerGroup.type]) {
    case 'Array':
      // get all correct answers from the current task
      const correctAnswers = answerGroup.answers.reduce((acc, key) => {
        if (key.correct) {
          acc.push(key.id);
        }
        return acc;
      }, []);
      // in dependency mode, every single answer is correct
      if (answerGroup.config && JSON.parse(answerGroup.config).dependencyMode) {
        return enteredAnswer.some(item => correctAnswers.indexOf(item) > -1);
      } else {
        return enteredAnswer.length === correctAnswers.length && enteredAnswer.every(item => correctAnswers.indexOf(item) > -1);
      }
    case 'String':
      return enteredAnswer.replace(pattern, '').trim() === answerGroup.answers[0].text.replace(pattern, '').trim();
    case TASK_TYPE_MAP.ANSWER_DUAL_ORDER:
      let index = 0;
      return answerGroup.answers.reduce((acc, key) => {
        // get all answers from the right side
        if (key.config && JSON.parse(key.config).column === 'right') {
          // check if the order of answers is equal to the original array
          const equal = key.id === enteredAnswer[index];
          index += 1;
          if (!equal) {
            return false;
          }
        }
        return acc;
      }, true);
    case TASK_TYPE_MAP.ANSWER_CLOZE:
      const solutions = JSON.parse(JSON.parse(answerGroup.config).solutions);
      getClozeAnswers.map(c => {
        // if cloze type is a text input, set the correct id for the cloze answer, since default is set to -1
        if (c.type === 'ANSWER_TEXT_INPUT') {
          // strip punctuations
          const inputText = c.text.replace(pattern, '').trim();
          return answerGroup.answers.map(a => {
            // strip tags and punctuations
            const text = sanitizeHtml(a.text, { allowedTags: [] }).replace(pattern, '').trim();
            const answer = JSON.parse(a.config);
            // check all answer possibilities with the appropriate cloze id with the text that was entered
            if (c.id.split(':')[0] === answer.id && inputText === text) {
              // if a match was found, set the new id
              return c.id = answer.id + ':' + answer.sort;
            }
            return a;
          });
        }
        return c;
      });
      const answers = getClozeAnswers.map(a => a.id);
      return solutions.some(s => {
        const newArray = [...new Set([...answers, ...s])];  // merge two arrays and remove any duplicates
        return newArray.length === s.length && newArray.length === answers.length; // if length is the same between all three arrays, the solution should be correct
      });
    case TASK_TYPE_MAP.ANSWER_FACT_FICTION:
      return enteredAnswer === answerGroup.answers[0].correct;
    case TASK_TYPE_MAP.ANSWER_SPEAK:
      return 'Speak';
    default:
      break;
  }
}
