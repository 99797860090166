import { OVERLAY_TYPES } from '../../components/constants';
import { downloadAsset } from '../uploads';
import clearCacheOnIPad from '../../helpers/clearCacheOnIPad';
import appVersion from '../../helpers/appVersion';
import { matomo } from '../matomo';
import { getDuplicatedMagIfExists } from '../../helpers/getDuplicatedMagIfExists';
import equal from '../../helpers/equal';


const defaultState = {
  overlay: appVersion.isWeb || appVersion.isIpad || appVersion.isMobile ? OVERLAY_TYPES.AUTH : OVERLAY_TYPES.MAGAZINES,
  showHotspots: false,
  internalBackButton: { visible: false, originPageId: null },
  magazines: [],
  currentMagazineId: null,
  pageId: null,
  offlineMagazinesList: [],
  queuedMagazines: [],
  offlineYearsList: [],
  closedWithOngoingDownloads: false
};

const SET_OVERLAY = 'SET_OVERLAY';

const TOGGLE_HOTSPOTS = 'TOGGLE_HOTSPOTS';
const SET_INTERNAL_BACK_BUTTON = 'SET_INTERNAL_BACK_BUTTON';

const SET_CURRENT_MAGAZINE_ID = 'SET_CURRENT_MAGAZINE_ID';

const DOWNLOAD_MAGAZINE = 'DOWNLOAD_MAGAZINE';
const DOWNLOAD_ALL_MAGAZINES = 'DOWNLOAD_ALL_MAGAZINES';

const DOWNLOAD_CONTENT = 'DOWNLOAD_CONTENT';

const QUEUE_MAGAZINES = 'QUEUE_MAGAZINES';
const RESET_QUEUED_MAGAZINES = 'RESET_QUEUED_MAGAZINES';
const SET_MAGAZINE = 'SET_MAGAZINE';
const SET_MAGAZINES = 'SET_MAGAZINES';
const SET_ALL_MAGAZINES = 'SET_ALL_MAGAZINES';

const DELETE_MAGAZINE = 'DELETE_MAGAZINE';
const CANCEL_MAGAZINES = 'CANCEL_MAGAZINES';

const SET_PAGE_ID = 'SET_PAGE_ID';

const SET_CLOSED_WITH_ONGOING_DOWNLOADS = 'SET_CLOSED_WITH_ONGOING_DOWNLOADS';

const setOverlay = overlay => {
  return { payload: { overlay: overlay }, type: SET_OVERLAY };
};

const toggleHotspots = value => {
  return { payload: { showHotspots: value }, type: TOGGLE_HOTSPOTS };
};

const setInternalBackButton = (visibility, pageId) => {
  return { payload: { visible: visibility, originPageId: pageId }, type: SET_INTERNAL_BACK_BUTTON };
};

const setCurrentMagazineId = magazineId => {
  return { payload: { currentMagazineId: magazineId }, type: SET_CURRENT_MAGAZINE_ID };
};

const setPageId = value => {
  return { payload: { pageId: value }, type: SET_PAGE_ID };
};

const deleteMagazine = (magId, yearId) => {
  return { payload: { id: magId, yearId: yearId }, type: DELETE_MAGAZINE };
};

const cancelDownloads = value => {
  return { payload: { ids: typeof value === 'number' ? [value] : value }, type: CANCEL_MAGAZINES };
};

const queueMagazinesForDownload = (ids, origin) => {
  return { payload: { ids, origin }, type: QUEUE_MAGAZINES };
};

const resetQueuedMagazinesForDownload = () => {
  return { payload: {}, type: RESET_QUEUED_MAGAZINES };
};

const setMagazines = yearId => {
  return { payload: { yearId: parseInt(yearId, 10) }, type: SET_MAGAZINES };
};

const setClosedWithOngoingDownloads = value => {
  return { payload: { value }, type: SET_CLOSED_WITH_ONGOING_DOWNLOADS };
};

const downloadMagazines = (magazineIds, setOffline = true) => {
  return {
    type: DOWNLOAD_MAGAZINE,
    meta: {
      offline: {
        effect: {
          type: 'graphql',
          fetchPolicy: 'network-only',
          query: `
            query getMagazine($filters: FilterInput) {
              magazines(filters: $filters) {
                results {
                  id
                  name
                  file_image_id
                  file_source_directory_id
                  file_audio_archive_id
                  published
                  subtitle
                  imprint
                  sort

                  year {
                    id
                    name
                    active
                    sort
                  }

                  sections { 
                    id
                    name
                    fichier_link
                    sort

                    pages {
                      id
                      name
                      sort
                      file_image_id
                      page_number_left
                      page_number_right

                      hotspots {
                        id
                        name
                        type
                        position

                        content {
                          id
                          name
                          text
                          file_audio_id
                          file_music_id
                          file_image_id
                          file_video_id
                          file_any_id
                          file_background_image_id
                          type

                          internal_link_page {
                            id
                          }

                          children {
                            id
                            name
                            text
                            file_audio_id
                            file_music_id
                            file_image_id
                            file_video_id
                            file_any_id
                            file_background_image_id
                            type
                            config
                          }
                        }

                        # could be a scene
                        container {
                          id
                          name
                          description
                          type

                          datapools {
                            id
                            name
                            description
                            text
                            type
                            file_image_id
                            file_audio_id
                            is_sorted
                            items_per_session
                            difficulty
                            
                            sentences {
                              id
                              text
                              file_audio_id

                              tasks {
                                id
                                name
                                description
                                snippet

                                question {
                                  id
                                  text
                                  file_audio_id
                                  file_image_id
                                  file_video_id
                                  type
                                }

                                answerGroup {
                                  id
                                  type
                                  config
                                  
                                  answers {
                                    id
                                    text
                                    file_audio_id
                                    file_image_id
                                    config
                                    correct
                                    sort

                                    nextTask {
                                      id
                                    }
                                  }
                                }
                              }
                            }

                            tasks {
                              id
                              name
                              description
                              snippet

                              question {
                                id
                                text
                                file_audio_id
                                file_image_id
                                file_video_id
                                type
                              }

                              answerGroup {
                                id
                                type
                                config

                                answers {
                                  id
                                  text
                                  file_audio_id
                                  file_image_id
                                  config
                                  correct
                                  sort

                                  nextTask {
                                    id
                                  }
                                }
                              }
                            }

                            hotspots {
                              id
                              name
                              type
                              position

                              content {
                                id
                                name
                                text
                                file_audio_id
                                file_music_id
                                file_image_id
                                file_video_id
                                file_any_id
                                file_background_image_id
                                type

                                # possible link to another scene
                                datapool_id

                                internal_link_page {
                                  id
                                }

                                children {
                                  id
                                  name
                                  text
                                  file_audio_id
                                  file_music_id
                                  file_image_id
                                  file_video_id
                                  file_any_id
                                  file_background_image_id
                                  type
                                  config
                                }
                              }

                              # the scene could have containers within itself
                              container {
                                id
                                name
                                description
                                type
      
                                datapools {
                                  id
                                  name
                                  description
                                  type
                                  text
                                  file_image_id
                                  file_audio_id
                                  is_sorted
                                  items_per_session
                                  difficulty
                                  
                                  sentences {
                                    id
                                    text
                                    file_audio_id

                                    tasks {
                                      id
                                      name
                                      description
                                      snippet
  
                                      question {
                                        id
                                        text
                                        file_audio_id
                                        file_image_id
                                        file_video_id
                                        type
                                      }
  
                                      answerGroup {
                                        id
                                        type
                                        config
                                        
                                        answers {
                                          id
                                          text
                                          file_audio_id
                                          file_image_id
                                          config
                                          correct
                                          sort
                                        }
                                      }
                                    }
                                  }

                                  tasks {
                                    id
                                    name
                                    description
                                    snippet

                                    question {
                                      id
                                      text
                                      file_audio_id
                                      file_image_id
                                      file_video_id
                                      type
                                    }

                                    answerGroup {
                                      id
                                      type
                                      config
                                      
                                      answers {
                                        id
                                        text
                                        file_audio_id
                                        file_image_id
                                        config
                                        correct
                                        sort
                                      }
                                    }
                                  }
      
                                  hotspots {
                                    id
                                    name
                                    type
                                    position

                                    content {
                                      id
                                      name
                                      text
                                      file_audio_id
                                      file_music_id
                                      file_image_id
                                      file_video_id
                                      file_any_id
                                      file_background_image_id
                                      type
                                      
                                      internal_link_page {
                                        id
                                      }

                                      children {
                                        id
                                        name
                                        text
                                        file_audio_id
                                        file_music_id
                                        file_image_id
                                        file_video_id
                                        file_any_id
                                        file_background_image_id
                                        type
                                        config
                                      }
                                    }

                                    # but there are no scenes in scenes
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { filters: { where: [{ field: 'id', operator: 'in', value: { intValue: magazineIds } }] } },
          operationName: 'downloadMagazines'
        },
        commit: { meta: { setOffline: setOffline }, type: SET_MAGAZINE }
      }
    }
  };
};

const downloadAllMagazines = () => {
  return {
    type: DOWNLOAD_ALL_MAGAZINES,
    meta: {
      offline: {
        effect: {
          type: 'graphql',
          fetchPolicy: 'network-only',
          query: `
            query getMagazines {
              magazines {
                magazines:
                  results {
                    id
                    name
                    file_image_id
                    file_source_directory_id
                    file_audio_archive_id
                    published
                    subtitle
                    imprint
                    sort

                    year {
                      id
                      name
                      active
                      sort
                    }
                  }
              }
            }
          `,
          operationName: 'downloadAllMagazines'
        },
        commit: { type: SET_ALL_MAGAZINES }
      }
    }
  };
};


function getYearIdsFromMagazineIds(ids, magazines) {
  const yearIds = [];
  ids.forEach(id => {
    const magazine = magazines.find(m => m.id === id);
    const yearId = magazine.year.id;

    if (!yearIds.includes(yearId)) {
      yearIds.push(yearId);
    }
  });
  return yearIds;
}

// When we cancel a magazine, we also have to handle the case if it was triggered with the year slide.
// So there we have to check if one of the magazines is in a year where the slide is activated
// and remove the year from the offlineyears list
function removeYearFromOfflineYearsListIfMagazineOfThisYearIsIncluded(payloadIds, magazines, offlineYearsList) {
  const yearIds = getYearIdsFromMagazineIds(payloadIds, magazines);

  let offlineYearsListIndex;
  yearIds.forEach(yearId => {
    offlineYearsListIndex = offlineYearsList.findIndex(id => id === yearId);

    if (offlineYearsListIndex > -1) {
      offlineYearsList.splice(offlineYearsListIndex, 1);
    }
  });

  return offlineYearsList;
}

// dont remove a queued Magazine from the list if it is in the offline magazine list, because if you deactivate a year
// it would also remove the current magazine from the queuedMagazines.
// it would and should however still remain in the offlineMagazine list and continue to download
// if you now queue another magazine, it will be the only entry there, so it will start to download
// which would lead to more than one magazine currenlty downloading.
// (it can still be removed by cancelling the single magazine instead of the year slider)
// !offlineMagazinesList.find(m => m.id === id)
function removeQueuedMagazines(payloadIds, queuedMagazines, offlineMagazinesList) {
  payloadIds.forEach(id => {
    const index = queuedMagazines.findIndex(queuedId => queuedId === id);

    if (index > -1 && !offlineMagazinesList.find(m => m.id === id)) {
      queuedMagazines.splice(index, 1);
    }
  });
  return queuedMagazines;
}

function removeMagazinesFromOfflineMagazinesList(ids, offlineMagazinesList) {
  ids.forEach(id => {
    let offlineIndex = offlineMagazinesList.findIndex(mag => mag.id === id);
    if (offlineIndex >= 0) {
      offlineMagazinesList.splice(offlineIndex, 1);
    }
  });

  return offlineMagazinesList;
}

function sortMagazineIDsOfAYear(unsortedIds, magazines) {
  const magazinesToSort = [];
  const sortedIds = [];
  unsortedIds.forEach(id => {
    magazinesToSort.push(magazines.find(mag => mag.id === id));
  });

  magazinesToSort.sort((a, b) => {
    if (a.sort < b.sort) return -1;
    if (a.sort > b.sort) return 1;
    return 0;
  });
  magazinesToSort.forEach(mag => sortedIds.push(mag.id));
  return sortedIds;
}


export const reducer = (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case SET_OVERLAY:
      return { ...state, overlay: payload.overlay };
    case TOGGLE_HOTSPOTS:
      return { ...state, showHotspots: payload.showHotspots };
    case SET_INTERNAL_BACK_BUTTON:
      return { ...state, internalBackButton: { visible: payload.visible, originPageId: payload.originPageId } };
    case SET_PAGE_ID:
      if (payload.pageId !== false && payload.pageId !== state.pageId) {
        matomo(['setReferrerUrl', `/magazine/${state.currentMagazineId}/page/${state.pageId}`]);
        matomo(['setCustomUrl', `/magazine/${state.currentMagazineId}/page/${payload.pageId}`]);
        matomo(['setGenerationTimeMs', 0]);
        matomo(['trackPageView']);
      }

      return { ...state, pageId: payload.pageId };
    case SET_CURRENT_MAGAZINE_ID:
      return { ...state, currentMagazineId: payload.currentMagazineId };
    case DOWNLOAD_MAGAZINE:
    case DOWNLOAD_ALL_MAGAZINES:
      return {
        ...state
      };
    case QUEUE_MAGAZINES:
    {
      const queuedMagazines = [...state.queuedMagazines];
      const offlineMagazinesList = [...state.offlineMagazinesList];

      // since we want to download the magazines of a year according to their order
      const unsortedQueuedMagazines = [];
      payload.ids.forEach(id => {
        // dont queue magazines which are in the offlineMagazines list
        // (can happen when magazine is downloaded and you toggle the year button)
        if (payload.origin === 'list') {
          if (!queuedMagazines.includes(id) && !offlineMagazinesList.find(m => m.id === id)) {
            unsortedQueuedMagazines.push(id);
          }
        } else {
          if (!queuedMagazines.includes(id)) {
            unsortedQueuedMagazines.push(id);
          }
        }
      });

      const sortedIds = sortMagazineIDsOfAYear(unsortedQueuedMagazines, state.magazines);

      // filter duplicates if triggered by list for example
      const unduplicatedIds = [];
      const duplicateIds = [];
      sortedIds.forEach(id => {
        if (duplicateIds.includes(id)) return; // dont add a duplicate again

        const duplicateId = getDuplicatedMagIfExists(id);
        if (duplicateId) {
          if (sortedIds.includes(duplicateId)) {
            duplicateIds.push(duplicateId);
          }
        }
        unduplicatedIds.push(id);
      });

      unduplicatedIds.forEach(id =>
        payload.origin === 'update' ? // if you want to view a magazine while one is downloading, put it forward in queue
          queuedMagazines.splice(1, 0, id) :
          queuedMagazines.push(id)
      );

      return {
        ...state,
        queuedMagazines,
      };
    }
    case RESET_QUEUED_MAGAZINES:
    {
      return {
        ...state,
        queuedMagazines: [],
      };
    }
    case SET_CLOSED_WITH_ONGOING_DOWNLOADS:
    {
      return {
        ...state,
        closedWithOngoingDownloads: payload.value,
      };
    }
    case SET_MAGAZINE:
    {
      const offlineMagazinesList = [...state.offlineMagazinesList];
      const queuedMagazines = [...state.queuedMagazines];
      const magazines = [...state.magazines];

      if (payload.data.magazines.results) {
        payload.data.magazines.results.map(magazine => {
          let index = magazines.findIndex(i => i.id === magazine.id);
          if (index > -1) {
            const offlineIndex = offlineMagazinesList.findIndex(mag => mag.id === magazine.id);
            if (queuedMagazines[0] === magazine.id || !meta.setOffline) {
              // this equal function should ignore different order in arrays, to do that the arrays get sorted
              // we dont want this changed object with the sorted arrays, so we have to make a copy
              let areMagazinesEqual = equal({ ...magazines[index] }, { ...magazine }, false, false, true);
              if (offlineIndex > -1 && !areMagazinesEqual && meta.setOffline) {
                offlineMagazinesList[offlineIndex].downloading = true;
                offlineMagazinesList[offlineIndex].progress = 0;
              } else if (offlineIndex > -1 && areMagazinesEqual &&
                  offlineMagazinesList[offlineIndex].downloading === false &&
                  offlineMagazinesList[offlineIndex].progress === 100) {
                const indexToSplice = queuedMagazines.indexOf(magazines[index].id);
                queuedMagazines.splice(indexToSplice, 1);
              } else if (offlineIndex === -1 && meta.setOffline) {
                offlineMagazinesList.push({ id: magazine.id, downloading: true, progress: 0 });
              }
              magazines[index] = magazine;

              const duplicateId = getDuplicatedMagIfExists(magazine.id);
              if (duplicateId) {
                const offlineIndex = offlineMagazinesList.findIndex(mag => mag.id === duplicateId);
                if (offlineIndex > -1 && !areMagazinesEqual && meta.setOffline) {
                  offlineMagazinesList[offlineIndex].downloading = true;
                  offlineMagazinesList[offlineIndex].progress = 0.1;
                } else if (offlineIndex > -1 && areMagazinesEqual &&
                    offlineMagazinesList[offlineIndex].downloading === false &&
                    offlineMagazinesList[offlineIndex].progress === 100) {
                  const indexToSplice = queuedMagazines.indexOf(magazines[index].id);
                  queuedMagazines.splice(indexToSplice, 1);
                } else if (offlineIndex === -1 && meta.setOffline) {
                  offlineMagazinesList.push({ id: duplicateId, downloading: true, progress: 0.1 });
                }
                index = magazines.findIndex(i => i.id === duplicateId);
                magazines[index] = {
                  ...magazines[index],
                  sections: magazine.sections
                };
              }
            }

          } else {
            magazines.push(magazine);
          }
          return null;
        });
      }

      return {
        ...state,
        magazines,
        queuedMagazines,
        offlineMagazinesList,
        _loaded: Date.now()
      };
    }
    case SET_MAGAZINES:
    {
      const offlineYearsList = [...state.offlineYearsList];

      let offlineIndex = offlineYearsList.findIndex(year => year === payload.yearId);
      if (offlineIndex < 0) {
        offlineYearsList.push(payload.yearId);
      }

      return {
        ...state,
        offlineYearsList,
        _loaded: Date.now()
      };
    }
    case SET_ALL_MAGAZINES:
    {
      let magazinesPayload = payload.data.magazines.magazines;
      if (magazinesPayload) {

        const magazines = [...state.magazines];

        magazinesPayload.forEach(item => {
          const index = magazines.findIndex(i => i.id === item.id);
          if (index > -1) {
            // keep infos like sections and pages
            Object.keys(item).forEach(function (key) {
              magazines[index][key] = item[key];
            });
          } else {
            magazines.push(item);
          }
        });

        return {
          ...state,
          magazines: magazines.filter(mag => magazinesPayload.findIndex(i => i.id === mag.id) > -1), // filter obsolete magazines
          _loaded: Date.now()
        };
      }
      return { ...state };
    }
    case DOWNLOAD_CONTENT:
    {
      const queuedMagazines = [...state.queuedMagazines];
      const offlineMagazinesList = [...state.offlineMagazinesList];

      let offlineIndex = offlineMagazinesList.findIndex(mag => mag.id === payload.id);

      // prevent from re-adding things to the offlineMagazines list if it was cancelled but this arrives afterwards
      if (queuedMagazines[0] !== payload.id) {
        if (offlineIndex > 1) {
          offlineMagazinesList.splice(offlineIndex, 1);
        }

        return {
          ...state,
          offlineMagazinesList
        };
      }


      if (offlineIndex > -1) {
        offlineMagazinesList[offlineIndex].downloading = payload.downloading;
        offlineMagazinesList[offlineIndex].progress = payload.progress;
      } else if (payload.downloading && payload.progress === 0) {
        offlineMagazinesList.push({ id: payload.id, downloading: payload.downloading, progress: payload.progress });
      }
      let duplicateId = getDuplicatedMagIfExists(payload.id);
      if (duplicateId) {
        offlineIndex = offlineMagazinesList.findIndex(mag => mag.id === duplicateId);
        if (offlineIndex > -1) {
          offlineMagazinesList[offlineIndex].downloading = payload.downloading;
          offlineMagazinesList[offlineIndex].progress = payload.progress;
        } else if (payload.downloading && payload.progress === 0.1) {
          offlineMagazinesList.push({ id: duplicateId, downloading: payload.downloading, progress: payload.progress });
        }
      }

      if (payload.progress === 100) {
        clearCacheOnIPad();

        return {
          ...state,
          queuedMagazines: queuedMagazines.slice(1),
          offlineMagazinesList
        };
      }

      return {
        ...state,
        offlineMagazinesList
      };
    }
    case DELETE_MAGAZINE: {
      let queuedMagazines = [...state.queuedMagazines];
      let offlineYearsList = [...state.offlineYearsList];
      let offlineMagazinesList = [...state.offlineMagazinesList];
      const magazines = [...state.magazines];

      const magazinesToRemove = [payload.id];
      let duplicateId = getDuplicatedMagIfExists(payload.id);
      if (duplicateId) {
        magazinesToRemove.push(duplicateId);
      }

      offlineMagazinesList = removeMagazinesFromOfflineMagazinesList(magazinesToRemove, offlineMagazinesList);
      offlineYearsList = removeYearFromOfflineYearsListIfMagazineOfThisYearIsIncluded(magazinesToRemove, magazines, offlineYearsList);
      queuedMagazines = removeQueuedMagazines(magazinesToRemove, queuedMagazines, offlineMagazinesList);

      clearCacheOnIPad();

      return {
        ...state,
        offlineMagazinesList,
        offlineYearsList,
        queuedMagazines
      };
    }
    case CANCEL_MAGAZINES:
    {
      const offlineMagazinesList = [...state.offlineMagazinesList];
      const magazines = [...state.magazines];

      let queuedMagazines = [...state.queuedMagazines];
      let offlineYearsList = [...state.offlineYearsList];

      downloadAsset.stop(payload.ids);

      offlineYearsList = removeYearFromOfflineYearsListIfMagazineOfThisYearIsIncluded(payload.ids, magazines, offlineYearsList);
      queuedMagazines = removeQueuedMagazines(payload.ids, queuedMagazines, offlineMagazinesList);

      return {
        ...state,
        queuedMagazines,
        offlineYearsList
      };
    }
    default:
      return state;
  }
};

export const reducerKey = 'magazines';
export const actions = {
  setOverlay,
  toggleHotspots,
  setInternalBackButton,
  setCurrentMagazineId,

  setClosedWithOngoingDownloads,
  setMagazines,

  queueMagazinesForDownload,
  resetQueuedMagazinesForDownload,
  downloadMagazines,
  downloadAllMagazines,

  deleteMagazine,
  cancelDownloads,

  setPageId
};