import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import Header from '../../Components/Header';
import DoughnutChart from '../../../Doughnut';
import Button from '../../../Button';

import spawnIcon from '../../functions/spawnIcon';

import styles from '../styles/result.less';
import { RESULT_SPAWN_ICONS } from '../../../constants';

let spawnElementsTimeout = [];

export default class Result extends Component {
  componentDidMount() {
    if (this.$buttons) {
      for (let index = 0; index < RESULT_SPAWN_ICONS; index++) {
        this.spawn();
      }
    }
  }

  componentWillUnmount() {
    for (let index = 0; index < spawnElementsTimeout.length; index++) {
      clearTimeout(spawnElementsTimeout[index]);
    }
  }

  render() {
    const { showDatapools, datapoolName, showInformation, toggleInformation, information, onClose, restart, data } = this.props;
    return (
      <div className={styles.resultContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          toggleInformation={toggleInformation}
          showInformation={showInformation}
          information={information}
          onClose={onClose}
        />
        <div className={styles.resultContent} >
          <div className={styles.containerContent}>
            <div className={styles.chart}>
              <div className={styles.title}>Dein Ergebnis</div>
              {data.length > 1
                ? <Fragment>
                  <DoughnutChart
                    data={data}
                  />
                  <div className={styles.circleContainer} >
                    {this.showResult()}
                  </div>
                </Fragment>
                : <Fragment>
                  <div className={styles.iconBorder}>
                    <div className={`icon-check ${styles.icon}`} />
                  </div>
                  <div className={styles.text}>Übung abgeschlossen</div>
                </Fragment>
              }
            </div>
            <div ref={buttons => this.$buttons = buttons} className={styles.buttons}>
              <Button icon="close-large" classes={styles.button} onClick={onClose} />
              <Button icon="repeat" classes={styles.button} onClick={restart} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  showResult = () => {
    const { data, colors } = this.props;
    return data.map((d, i) => {
      let text = '';
      switch (i) {
        case 0:
          text = <span>richtig</span>;
          break;
        case 1:
          text = <span>richtig<br />(2. Versuch)</span>;
          break;
        case 2:
          text = <span>falsch</span>;
          break;
        default:
          break;
      }
      const color = colors[i % colors.length];
      return (
        <div key={`${i}_${d}`} className={styles.resultInfo}>
          <div className={`${styles.circle} ${styles[color]}`} />
          <div>
            {text}
          </div>
        </div>
      );
    });
  }

  spawn = () => {
    const { data } = this.props;
    const element = spawnIcon(this.$buttons, data.length > 1 ? styles.emojiSmall : styles.emojiTall);

    spawnElementsTimeout.push(setTimeout(() => {
      this.$buttons.removeChild(element.icon);
      this.spawn();
    }, element.duration * 1000 + 1000));  // remove element after duration + 1 second extra
  }
}

Result.propTypes = {
  restart: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.number),
  colors: PropTypes.arrayOf(PropTypes.string)
};

Result.defaultProps = {
  restart: () => { },
  data: [3, 3, 2],
  colors: ['green', 'yellow', 'red']
};