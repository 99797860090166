import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';
import { Tooltip, Button } from '../../components';
import { OVERLAY_TYPES } from '../constants';


class HelpOverlay extends Component {
  render() {
    return (
      <div className={styles.outerhelpOverlay}>
        <div className={styles.headerOverlay}>
          <Button icon="interaction" color="yellow" smaller classes="forceTooltip" styles={styles.interactionBtn} disabled={true}>
            <Tooltip string="Hotspots hervorheben" vertical="bottom" horizontal="right" />
          </Button>
          <Button icon="close-large" color="yellow" smaller classes={styles.closeBtn} onClick={() => {
            this.props.setDarkenSurroundings(false);
            this.props.setOverlay(OVERLAY_TYPES.NONE);
          }} />
        </div>
        <div className={styles.helpOverlay}>
          <div className={styles.btnContainer}>
            {/* <Button color="yellow" icon="grid" classes="forceTooltip" styles={styles.gridBtn} disabled={true}> */}
            <div className={styles.invisibleAnchor}>
              <Tooltip string="Navigation" vertical="bottom" />
            </div>
            {/* </Button> */}
            <Button color="yellow" icon="arrow-left" classes="forceTooltip" styles={styles.backBtn} disabled={true}>
              <Tooltip string="Zurück zur letzten Seite" />
            </Button>
            <Button color="yellow" icon="chevron-left" classes="forceTooltip" styles={styles.chevronLeftBtn} disabled={true}>
              <Tooltip string="Vorherige Seite" />
            </Button>
            <Button color="yellow" icon="chevron-right" classes="forceTooltip" styles={styles.chevronRightBtn} disabled={true}>
              <Tooltip string="Nächste Seite" horizontal="right" />
            </Button>
          </div>
        </div>
      </div >
    );
  }
}


HelpOverlay.propTypes = {
  setOverlay: PropTypes.func.isRequired,
  setDarkenSurroundings: PropTypes.func.isRequired
};

export default HelpOverlay;