import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';

export default function LoadingSpinner({ width, percentageStyle, captionStyle, progress, caption }) {
  return (
    <div className={styles.loadingContainer}>
      <div style={{ width: `${width}px` }}>
        <div className={styles.loader} style={{ width: `${width}px`, height: `${width}px` }} />
        {progress >= 0 && <div className={`${styles.progress} ${percentageStyle}`}>{`${progress}%`}</div>}
        <div className={`${styles.caption} ${captionStyle}`}>{caption}</div>
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  width: PropTypes.number,
  progress: PropTypes.number,
  caption: PropTypes.string,
  percentageStyle: PropTypes.string,
  captionStyle: PropTypes.string
};

LoadingSpinner.defaultProps = {
  width: 50,
  progress: undefined,
  caption: '',
  percentageStyle: null,
  captionStyle: null
};
