import React from 'react';

const FichierIcon = <svg width="24px" height="24px" viewBox="0 0 160.22324 138.29488" version="1.1">
  <g id="layer1">
    <g id="g87" transform="translate(-0.28732126,-4.2493506)">
      <circle style={{ fill:'#ffffff', fillOpacity: 1, strokeWidth: 1.0482 }} id="path837" cx="88.977707" cy="73.398308" r="66.532906" />
      <path id="circle77" style={{ fill: '#83d0f5', fillOpacity: 1, strokeWidth: 13.7229, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }} d="M 89.029706,4.2493506 A 69.147591,69.147591 0 0 0 19.88199,73.396538 69.147591,69.147591 0 0 0 89.029706,142.54423 69.147591,69.147591 0 0 0 158.17742,73.396538 69.147591,69.147591 0 0 0 89.029706,4.2493506 Z m 0,13.5242374 A 55.623287,55.623287 0 0 1 144.65318,73.396538 55.623287,55.623287 0 0 1 89.029706,129.02 55.623287,55.623287 0 0 1 33.406746,73.396538 55.623287,55.623287 0 0 1 89.029706,17.773588 Z" />
      <path id="circle70" style={{ fill: '#5bc5f2', fillOpacity: 1, strokeWidth: 8.60999, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }} d="M 88.972863,30.012633 A 43.384223,43.384223 0 0 0 45.645792,73.396538 43.384223,43.384223 0 0 0 89.029706,116.78095 43.384223,43.384223 0 0 0 132.41414,73.396538 43.384223,43.384223 0 0 0 89.029706,30.012633 a 43.384223,43.384223 0 0 0 -0.05684,0 z m 0.04134,13.937652 a 29.446312,29.446312 0 0 1 0.0155,0 A 29.446312,29.446312 0 0 1 118.47597,73.396538 29.446312,29.446312 0 0 1 89.029701,102.84278 29.446312,29.446312 0 0 1 59.583439,73.396538 29.446312,29.446312 0 0 1 89.014199,43.950285 Z" />
      <circle style={{ fill: '#009fe3', fillOpacity: 1, strokeWidth: 3.15439, strokeLinecap: 'round', strokeLinejoin: 'round', paintOrder: 'stroke fill markers' }} id="path66" cx="89.029808" cy="73.396713" r="15.894443" />
    </g>
  </g>
</svg>;

export default FichierIcon;