import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { compose, graphql } from 'react-apollo';

import { getOverrideLoginStatus } from '../graphql';

import { store } from '../../redux/store';

import autoLogin from '../functions/autoLogin';
import locationHash from '../functions/locationHash';
import login from '../functions/login';
import signUri from '../functions/signUri';
import appVersion from '../../../helpers/appVersion';

import { actions } from '../redux';
import { actions as authActions } from '../../auth/redux';
import { actions as licenseActions } from '../../license/redux';
import { actions as magazineActions } from '../../magazines/redux';
import { openIdConnectLogin } from '../functions';

import LoadingSpinner from '../../../components/LoadingSpinner';

import { OVERLAY_TYPES } from '../../../components/constants';
import { REFRESH_TOKEN_INTERVAL } from '../constants';

import styles from './styles.less';

class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMaintainingJWTToken: false,
      isMaintainingServiceTokens: false,
    };

    this.refreshInterval = false;
    this.refreshTokenIntervals = [];
    this._preventServiceTokenDuplication = false;
  }

  componentDidMount() {
    // const { token, user } = this.props.auth;

    // if (token) {
    //   this.setupTokenMaintainance();
    // }

    // this.setupServiceTokenMaintenance();

    // if (appVersion.isWeb || appVersion.isIpad || ((appVersion.isIosWebApp || appVersion.isMobile) && user && user.user)) {
    //   this.checkAutoLogin();
    // } else if (appVersion.isDesktop || appVersion.isMobile || appVersion.isIosWebApp) {
    //   this.performAppLogin();
    // }
  }

  componentWillReceiveProps(nextProps, { isMaintainingJWTToken, isMaintainingServiceTokens }) {
    // if (typeof this.props.auth.token === 'undefined' && nextProps.auth.token) {
    //   this.tearDownTokenMaintenance();
    // }
  }

  componentDidUpdate(prevProps) {
    const { overrideLoginStatusLoading, overrideLoginStatus, auth: { user } } = this.props;

    if(prevProps.overrideLoginStatusLoading && !overrideLoginStatusLoading) {
      if(overrideLoginStatus === false) {
        if(!user) {
          store.dispatch(magazineActions.setOverlay(OVERLAY_TYPES.AUTH));
        }

        const savedToken = store.getState().auth.user;

        if(!savedToken ) {
          openIdConnectLogin();
        }
        store.dispatch(licenseActions.setOverrideLogin(overrideLoginStatus));
      } else if (overrideLoginStatus) {
        store.dispatch(batchActions([
          licenseActions.setOverrideLogin(overrideLoginStatus),
          magazineActions.setOverlay(OVERLAY_TYPES.MAGAZINES)
        ]));
      }
    }

    // const { token } = this.props.auth;
    // const { isMaintainingJWTToken, isMaintainingServiceTokens } = this.state;

    // if (token && !isMaintainingJWTToken) {
    //   this.setupTokenMaintainance();
    // } else if (!isMaintainingServiceTokens) {
    //   this.setupServiceTokenMaintenance();
    // }
  }

  componentWillUnmount() {
    // this.tearDownTokenMaintenance(false);
  }

  /**
   * Initializes an interval which will update necessary tokens every
   * REFRESH_TOKEN_INTERVAL ms
   * 
   * Tokens are required to access certain content, such as uploaded files.
   */
  setupTokenMaintainance = async () => {
    const { auth } = this.props;

    // just in case teardown didnt happen.
    clearInterval(this.refreshInterval);

    this.refreshInterval = setInterval(() => {
      const { auth: { token } } = this.props;

      if (token) {
        login({ token: this.props.auth.token });
      } else {
        clearInterval(this.refreshInterval);
      }
    }, REFRESH_TOKEN_INTERVAL);

    login({ token: auth.token });

    this.setState({
      isMaintainingJWTToken: true
    });
  }

  setupServiceTokenMaintenance = () => {
    if (this._preventServiceTokenDuplication) return;

    this._preventServiceTokenDuplication = true;

    const { maintainTokensFor, setServiceToken } = this.props;

    // make sure not to create multiple intervals
    this.refreshTokenIntervals.forEach(clearInterval);

    const { tokens, intervals } = maintainTokensFor.reduce((acc, { service, uri, options }) => {
      const getToken = async () => {
        try {
          const token = await signUri(uri, options);

          if (token) {
            setServiceToken(service, token);
          } else {
            setServiceToken(service, '');
          }
        } catch (err) {
          console.error(err);
        }
      };

      acc.tokens.push(getToken());
      acc.intervals.push(setInterval(
        getToken,
        options.duration
          ? (options.duration - 30) * 1000
          : 5 * 60 * 1000
      ));

      return acc;
    }, { tokens: [], intervals: [], errors: [] });

    this.refreshTokenIntervals = intervals;

    Promise.all(tokens)
      .then(() => {
        this.setState({
          isMaintainingServiceTokens: true
        });
      });
  }

  tearDownTokenMaintenance = (withState = true) => {
    clearInterval(this.refreshInterval);

    this.refreshTokenIntervals.forEach(clearInterval);
    this._preventServiceTokenDuplication = false;

    this.setState({
      isMaintainingJWTToken: false,
      isMaintainingServiceTokens: false
    });
  }

  checkAutoLogin = () => {
    const { auth, license, setUser, setUnlocked } = this.props;
    const loginId = locationHash().loginId;
    if (loginId) {
      // reset hash
      window.location.hash = '';
      const token = this.props.auth && this.props.auth.token;
      autoLogin({ loginId, token })
        .then(res => {
          if (!license || !license.unlocked || (license && license.unlocked && !license.unlocked.length)) {
            license.unlocked = [];
          }

          res.unlocked = res.unlocked.filter(newUnlocked =>
            !license.unlocked.find(oldUnlocked =>
              oldUnlocked.svp_magazine_id === newUnlocked.svp_magazine_id
            ));

          if (!auth.user) {
            setUser(res.user);
          }
          setUnlocked(res.unlocked);
        });
    }
  }

  performAppLogin = () => {
    const { setUser } = this.props;

    setUser({
      clientLogin: true
    });
  }

  render() {
    const { children, overrideLoginStatusLoading } = this.props;
    // console.log('OVERRIDE', overrideLoginStatus);
    // const { isMaintainingJWTToken, isMaintainingServiceTokens } = this.state;

    // return !isMaintainingJWTToken && !isMaintainingServiceTokens
    //   ? null
    //   : children;


    return !overrideLoginStatusLoading ? children : this.renderLoadingSpinner();
  }

  renderLoadingSpinner = () => {
    return (
      <div>
        <div className={styles.loadingProgress}>
          <LoadingSpinner width={100} caption={'Loading'} />
        </div>
      </div>
    );
  }
}

Provider.propTypes = {
  maintainTokensFor: PropTypes.arrayOf(PropTypes.object)
};

Provider.defaultProps = {
  maintainTokensFor: []
};

const mapStateToProps = ({ auth, license }) => ({
  auth,
  license
});

const mapDispatchToProps = {
  setUser: authActions.setUser,
  setServiceToken: actions.setServiceToken,
  setUnlocked: licenseActions.setUnlocked,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(getOverrideLoginStatus, {
    props: ({ data }) => {
      const overrideLoginStati = data.overrideLoginStatus && data.overrideLoginStatus.results && data.overrideLoginStatus.results.reduce((acc, result) => {
        acc[result.key] = result.value;
        return acc;
      }, {});

      let overrideLoginStatus;
      if(overrideLoginStati) {
        Object.keys(appVersion).forEach(key => {
          if(appVersion[key]) {
            overrideLoginStatus = overrideLoginStati[key];
          }
        });
      }
    
      return ({
        overrideLoginStatus,
        overrideLoginStatusLoading: data.loading
      });
    }
  })
)(Provider);
