import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';


class Tooltip extends Component {
  render() {
    return (
      <div className={
        `${styles.tooltip}
        ${styles[this.props.vertical]}
        ${styles[this.props.horizontal]}
        ${styles[this.props.alignmentVertical]}
        ${styles[this.props.alignmentHorizontal]}
        tooltip`
      }>
        {this.props.string}
      </div>
    );
  }
}

Tooltip.propTypes = {
  string: PropTypes.string.isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string
};

export default Tooltip;