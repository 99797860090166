import gql from 'graphql-tag';
import { client } from '../../../containers/Graphql';

import { 
  USERNAME_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH
} from '../constants';

function minChars(minChars, str) {
  return str.length >= minChars;
}

function maxChars(maxChars, str) {
  return str.length <= maxChars;
}

function validateUsername(username) {
  let error;

  if (!minChars(USERNAME_MIN_LENGTH, username)) {
    error = 'ERR_MIN_LENGTH';
  } else if (!maxChars(USERNAME_MAX_LENGTH, username)) {
    error = 'ERR_MAX_LENGTH';
  }

  return error ? error : true;
}

function validatePassword(password) {
  let error;

  if (!minChars(PASSWORD_MIN_LENGTH, password)) {
    error = 'ERR_MIN_LENGTH';
  }

  return error ? error : true;
}

function validateEmail(email) {
  let error;

  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email)) {
    error = 'ERR_EMAIL_INVALID';
  }

  return error ? error : true;
}

const usernameQuery = gql`
  query isUsernameAvailable($username: String!) {
    isAvailable: isUsernameAvailable(username: $username)
  }
`;

const emailQuery = gql`
  query isEmailAvailable($email: String!) {
    isAvailable: isEmailAvailable(email: $email)
  }
`;

async function isUsernameAvailable(username) {
  const res = await client.query({
    query: usernameQuery,
    variables: { username },
    fetchPolicy: 'network-only'
  });

  return res.data.isAvailable;
}

async function isEmailAvailable(email) {
  const res = await client.query({
    query: emailQuery,
    variables: { email },
    fetchPolicy: 'network-only'
  });

  return res.data.isAvailable;
}

async function validateToken(token) {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/validateToken`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  );

  return res.status === 200;
}

export { 
  isEmailAvailable,
  isUsernameAvailable,
  validateEmail, 
  validateUsername, 
  validatePassword, 
  validateToken
};