const defaultState = {
  list: []
};

const SET_MAGAZINES_WITH_UPDATED_BLOBS = 'SET_MAGAZINES_WITH_UPDATED_BLOBS';

const setMagazinesWithUpdatedBlobs = id => {
  return { payload: { id: id }, type: SET_MAGAZINES_WITH_UPDATED_BLOBS };
};

export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_MAGAZINES_WITH_UPDATED_BLOBS:
      let index = state.list.findIndex(id => id === payload.id);
      if (index < 0) {
        state.list.push(payload.id);
      } else {
        state.list.splice(index, 1);
      }
      return {
        ...state,
        list: [...state.list]
      };
    default:
      return state;
  }
};

export const reducerKey = 'magazineBlobs';
export const actions = {
  setMagazinesWithUpdatedBlobs
};