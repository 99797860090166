import queueable from '@ovos-media/simple-promise-queue';

import appVersion from '../../helpers/appVersion';
import DownloadHandler from './DownloadHandler/DownloadHandler';
import CordovaDownloadHandler from './DownloadHandler/CordovaDownloadHandler';
import ElectronDownloadHandler from './DownloadHandler/ElectronDownloadHandler';

let downloadHandler;

if (appVersion.isDesktop) {
  downloadHandler = new ElectronDownloadHandler();
} else if (document.IS_CORDOVA) {
  downloadHandler = new CordovaDownloadHandler();
} else {
  downloadHandler = new DownloadHandler();
}

const concurrencyAdaption = document.IS_CORDOVA ? 5 : 10;

/**
 * In order to prevent loading the same file multiple times due to
 * concurrent requests, we queue our download requests and perform them one by one
 */
export default queueable(
  downloadHandler.downloadAsset,
  { concurrency: concurrencyAdaption, unshift: false }
);

const updateBlobsDownload = queueable(
  downloadHandler.downloadAsset,
  { concurrency: concurrencyAdaption, unshift: true }
);

const migrateBlobs = queueable(
  downloadHandler.downloadAsset,
  { concurrency: 1, unshift: true }
);

export {
  updateBlobsDownload,
  migrateBlobs,
};