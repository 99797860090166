import { batchActions } from 'redux-batched-actions';
import { actions } from '../redux';
import { actions as licenseActions } from '../../license/redux';
import parseJWT from './parseJwt';
import { store } from '../../redux/store';

export default async function login(
  options = { username: '', email: '', password: '', token: false },
  getRemoteToken = require('./getRemoteToken').default
) {
  const token = await getRemoteToken(options);

  if (token) {
    const payload = parseJWT(token);

    store.dispatch(batchActions([
      actions.setUser(payload.user),
      actions.setToken(token),
      licenseActions.setUnlocked(payload.unlocked)
    ]));

    return true;
  }

  return false;
}
