import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import LoadingSpinner from '../../../LoadingSpinner';
import { Popup } from '../../../Popup';

import { matomo } from '../../../../domains/matomo';
import appVersion from '../../../../helpers/appVersion';
import { downloadAsset } from '../../../../domains/uploads';
import downloadFromBrowser from '../../../../helpers/downloadFromBrowser';

import { generateUrl } from '../../../../domains/uploads';
import deleteItems from '../../../../domains/uploads/deleteItems';


import { OVERLAY_TYPES, IMAGE_THUMBNAIL } from '../../../constants';

import styles from './styles.less';
import { DOWNLOADS_CURRENTLY_RUNNING_TOAST_ID } from '../../constants';

class MagazineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmDeletion: false,
      lastCheckForUpdates: false
    };
  }

  componentDidUpdate(prevProps) {
    const {
      downloadStatus, magazine: { id, }, setCurrentMagazineId, setOverlay,
      currentMagazineId, setPage, magazinesLastLoaded, queuedMagazines
    } = this.props;
    const { lastCheckForUpdates } = this.state;

    const isQueuedForDownload = queuedMagazines.includes(id);
    if (lastCheckForUpdates !== false && lastCheckForUpdates < magazinesLastLoaded && !downloadStatus.downloading && downloadStatus.progress === 100 && !isQueuedForDownload) {
      this.setState({
        lastCheckForUpdates: false
      });

      setCurrentMagazineId(id);
      setOverlay(OVERLAY_TYPES.PAGES);
      parseInt(id, 10) !== parseInt(currentMagazineId, 10) && setPage(false);
    }
  }


  render() {
    const {
      license: { overrideUnlock, overrideLogin }, offline, downloadStatus,
      magazine: { id, file_audio_archive_id, name, file_image_id, published },
      queuedMagazines, hasInternet
    } = this.props;

    const isQueuedForDownload = queuedMagazines.includes(id);
    // whether the magazine is unlocked
    const hasLicense = this.checkHasLicense();

    const notDownloadedAndNoInternet = !hasInternet && !offline;
    const allowPublished = ((!isQueuedForDownload && !downloadStatus.downloading && published) && (hasLicense || overrideUnlock || overrideLogin));
    const allowUnpublished = !isQueuedForDownload && !downloadStatus.downloading && overrideUnlock && !published;

    return (
      <div
        className={`${styles.magazine} ${this.getItemStyle(allowUnpublished, allowPublished, notDownloadedAndNoInternet)}`}>
        <div className={styles.titleAndProgress}>
          <div
            className={styles.title}
            onClick={(hasInternet && !isQueuedForDownload) || offline ? this.onMagazineClick : null}
          >{name}</div>
          {(allowUnpublished || allowPublished || downloadStatus.downloading || isQueuedForDownload) &&
            <div className={styles.downloadArea} >
              {this.renderDownloadButtonStates(isQueuedForDownload, offline, hasInternet, downloadStatus)}
              {this.renderAudioArchiveDownloadButton(file_audio_archive_id, hasInternet)}
            </div>}
        </div>
        {this.renderCover(file_image_id, isQueuedForDownload, allowUnpublished, offline, hasInternet)}
        {this.renderShowConfirmDeletion()}
      </div>
    );
  }

  getItemStyle = (allowUnpublished, allowPublished, notDownloadedAndNoInternet) => {
    const {
      license: offline, magazine: { published },
    } = this.props;


    if (allowUnpublished) {
      return styles.nonPublishedAdmin;
    } else if (!allowPublished) {
      if (offline && !published) {
        return styles.disabled;
      }
      return styles.notAvailable;
    } else if (notDownloadedAndNoInternet) {
      return styles.notAvailable;
    }

    return '';
  }

  renderCover = (file_image_id, isQueuedForDownload, allowUnpublished, offline, hasInternet) => {
    return <div
      className={styles.cover}
      onClick={(hasInternet && !isQueuedForDownload) || offline ? this.onMagazineClick : null}
    >
      <img draggable={false} src={generateUrl(file_image_id, { width: IMAGE_THUMBNAIL.MAGAZINES_OVERLAY.WIDTH })} className={styles.img} alt="" />
      {allowUnpublished ? <div className={styles.nonPublishedAdminMark}>!Unveröffentlicht!</div> : null}
    </div>;
  }

  renderDownloadButtonStates = (isQueuedForDownload, offline, hasInternet, downloadStatus) => {
    return (appVersion.isDesktop || appVersion.isMobile || appVersion.isIpad || appVersion.isIosWebApp) && hasInternet ?
      !offline && !isQueuedForDownload ?
        this.renderDownloadButton() :
        downloadStatus.downloading || isQueuedForDownload ?
          this.renderDownloadProgress(downloadStatus) :
          this.renderDownloadCancelButton()
      : null;
  }

  renderDownloadButton = () => {
    return <span
      className={`${styles.downloadBtn} icon-download`}
      onClick={this.onDownloadClick}
    />;
  }

  renderDownloadProgress = downloadStatus => {
    return <div className={styles.downloading}>
      <div className={styles.noHover}>
        <LoadingSpinner percentageStyle={styles.downloadProgress} width={30} progress={Math.floor(downloadStatus.progress || 0)} />
      </div>
      <span
        className={`${styles.onHover} ${styles.downloadBtn} ${styles.check} icon-close-large`}
        onClick={this.onShowConfirmDeletionDialogue}
      />
    </div>;
  }

  renderDownloadCancelButton = () => {
    return <span
      className={`${styles.downloadBtn} ${styles.check} icon-check`}
      onClick={this.onShowConfirmDeletionDialogue}
    />;
  }

  renderAudioArchiveDownloadButton = (file_audio_archive_id, hasInternet) => {
    return file_audio_archive_id && hasInternet
      ? <span
        className={`${styles.downloadBtn} icon-album`}
        onClick={this.onDownloadAudioClick}
      />
      : null;
  }

  renderShowConfirmDeletion = () => {
    const { showConfirmDeletion } = this.state;
    return <Popup
      className={styles.popup}
      classNameContent={styles.popupContent}
      show={showConfirmDeletion}
      title="Löschen?"
      text="Wollen Sie dieses magazine wirklich löschen?"
      accept={this.onConfirmDeletion}
      cancel={this.onDeclineDeletion}
    />;
  }

  checkHasLicense = () => {
    const { license: { unlocked, overrideLogin }, magazine: { id } } = this.props;
    return (overrideLogin ||
      (unlocked && unlocked.some(item => item.magazine && item.magazine.id === id)) || // rev. 1
      // below line could lead to unlock the magazines based on ids from the database
      //(unlocked && unlocked.some(item => item.svp_product_ids && item.svp_product_ids.includes(id))) || // rev. 2
      (unlocked && unlocked.some(item => item.magazines && item.magazines.some(magazine => magazine.id === id)))); // rev. 2
  }

  onMagazineClick = () => {
    const { license: { overrideUnlock, overrideLogin }, downloadStatus, magazine: { id, name, published },
      currentMagazineId, setCurrentMagazineId, setOverlay, setPage, downloadMagazines,
      queueMagazinesForDownload, offline, hasInternet, magazinesLastLoaded } = this.props;
    const hasLicense = this.checkHasLicense();

    if (!offline || !hasInternet) {
      if (overrideUnlock || ((published && !downloadStatus.downloading) && (hasLicense || overrideLogin))) {
        downloadMagazines(id, false);
        setCurrentMagazineId(id);
        setOverlay(OVERLAY_TYPES.PAGES);
        parseInt(id, 10) !== parseInt(currentMagazineId, 10) && setPage(false);
      }
    } else if (overrideUnlock || (published && (hasLicense || overrideLogin))) {
      if (!downloadStatus.downloading) {
        console.log(`magazine_${id}`);
        toast(
          'Es laufen zur Zeit Downloads. Das Beenden der App beendet auch den Downloadprozess und löscht den Fortschritt',
          { toastId: DOWNLOADS_CURRENTLY_RUNNING_TOAST_ID }
        );
        queueMagazinesForDownload([id], 'update');
        this.setState(
          { lastCheckForUpdates: magazinesLastLoaded }
        );
      }
    }

    matomo(['trackEvent', 'ClickedMagazine', `${name} (${id})`]);
  }

  onDownloadClick = () => {
    const { magazine: { id }, queueMagazinesForDownload } = this.props;
    toast(
      'Es laufen zur Zeit Downloads. Das Beenden der App beendet auch den Downloadprozess und löscht den Fortschritt',
      { toastId: DOWNLOADS_CURRENTLY_RUNNING_TOAST_ID }
    );
    queueMagazinesForDownload([id]);
  }

  onDownloadAudioClick = () => {
    const { magazine: { name, file_audio_archive_id } } = this.props;
    downloadFromBrowser(file_audio_archive_id, `${name}_Audioarchiv`);
  }

  onShowConfirmDeletionDialogue = () => {
    this.setState({
      showConfirmDeletion: true
    });
  }

  onConfirmDeletion = () => {
    const { magazine: { id }, downloadStatus, isQueuedForDownload } = this.props;

    if (downloadStatus.downloading || isQueuedForDownload) {
      downloadAsset.stop([id]);
    }
    this.onClearCache();

    this.setState({
      showConfirmDeletion: false
    });
  }

  onDeclineDeletion = () => {
    this.setState({
      showConfirmDeletion: false
    });
  }

  onClearCache = () => {
    const { magazine, deleteMagazine } = this.props;
    deleteMagazine(magazine.id, magazine.year.id);
    deleteItems(magazine, magazine.id);
  }
}

MagazineItem.propTypes = {
  magazine: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    pageId: PropTypes.number,
    file_image_id: PropTypes.string,
    file_audio_archive_id: PropTypes.string,
    published: PropTypes.bool.isRequired
  }),
  setCurrentMagazineId: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setOverlay: PropTypes.func.isRequired,
  downloadStatus: PropTypes.shape({
    downloading: PropTypes.bool,
    progress: PropTypes.number
  })
};

const mapStateToProps = ({ license, magazines, offline }) => ({
  license,
  currentMagazineId: magazines.currentMagazineId,
  hasInternet: offline.online
});

export default connect(mapStateToProps)(MagazineItem);