import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';

import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4';

import thunkMiddleware from 'redux-thunk';

import { createOffline } from '@redux-offline/redux-offline';
import defaultOfflineEffect from '@redux-offline/redux-offline/lib/defaults/effect';
import { NetworkError } from '@redux-offline/redux-offline/lib/defaults/effect';

import { enableBatching } from 'redux-batched-actions';

import localforage from 'localforage';

import gql from 'graphql-tag';

import { gqlClient } from '../../containers';
import getStoredStateFromOtherStorage from '../../helpers/getStoredStateFromOtherStorage';
import appVersion from '../../helpers/appVersion';

import migrations from './migrations';
import reducers from './reducers';

// accessing process.env is expensive
const env = process.env.NODE_ENV;

let storage;
let oldStorage;
let store;
let persistor;

let usesOldV5;

async function prepareStore() {
  if (appVersion.isDesktop) {
    const remote = window.require('electron').remote;

    const es = remote.require('electron-store');
    const createElectronStorage = remote.require('redux-persist-electron-storage');

    const electronStore = new es({
      cwd: window.navigator.platform.match(/(win)/i) ? 'C:/ProgramData/SVP/config' : '/Library/Application Support/SVP/config'
    });

    storage = createElectronStorage({
      electronStore
    });

    // some users will have a version where they already upgraded to v5, but are still on localforage.
    // this means we cant use the getStoredStateMigrateV4 function.
    // We need one which just moves the redux data from one storage to another
    const firstWriteToFilesystem = !await storage.getItem('persist:root');
    if (firstWriteToFilesystem) {
      usesOldV5 = await localforage.getItem('persist:root');
    }

    oldStorage = localforage;
  } else {
    if (appVersion.isIpad) {
      // migrate old data from indexeddb to websql, also migrate from v4 to v5 should kick in
      oldStorage = await localforage.createInstance({
        name: 'localforage',
        driver: [localforage.INDEXEDDB]
      });

      // have ios devices use websql because, indexed db creates a gigantic wal file, which grows forever
      await localforage.setDriver([localforage.WEBSQL]);
    } else {
      oldStorage = localforage;
    }
    storage = localforage;
  }

  const filterEffectType = (queue, type) =>
    queue.filter(a => a.type !== type);

  // initialize redux-persist migrations
  const migrate = createMigrate(migrations, { debug: env === 'development' });

  /**
   * Configuration object for V4 compatability redux-persist (used under the hood by redux-offline)
   */
  const persistConfig = {
    key: 'root',
    storage,
    timeout: 0, // never delete this, otherwise random store wipes will happen in the app see https://github.com/rt2zz/redux-persist/issues/717#issuecomment-437589374
    blacklist: ['magazineBlobs', 'darkenSurroundings'],
    version: 6,
    migrate
  };

  /**
   * OLD Configuration object for redux-persist (used under the hood by redux-offline)
   */
  const persistConfigOld = {
    key: 'root',
    storage: oldStorage,
    timeout: 0, // never delete this, otherwise random store wipes will happen in the app see https://github.com/rt2zz/redux-persist/issues/717#issuecomment-437589374
    blacklist: ['persist', 'magazineBlobs']
  };

  /**
   * redux-offline configuration
   */
  const offlineConfig = {
    effect: (effect, action) => {
      switch (typeof effect) {
        case 'undefined': return Promise.resolve();
        case 'function':
          return effect()
            .catch(err => {
              console.error('Caught error during redux-offline functional effect:', { effect, err });

              return Promise.reject(
                err.networkError
                  ? err.networkError
                  : new NetworkError(
                    err,
                    err.message.indexOf('Network error') > -1 ? 500 : 0
                  )
              );
            });
        case 'object':
          const { url, type, ...opts } = effect;
          if (type === 'graphql') {
            const query = gql(opts.query || opts.mutation);
            return gqlClient[opts.query ? 'query' : 'mutate']({
              ...opts,
              [opts.query ? 'query' : 'mutation']: query
            })
              .catch(err => {
                console.error('Caught error during redux-offline graphql effect:', { effect, err });

                return Promise.reject(
                  err.networkError
                    ? err.networkError
                    : new NetworkError(
                      err,
                      err.message.indexOf('NetworkError') > -1 ? 500 : 0
                    )
                );
              });
          }

          return defaultOfflineEffect(effect, action);
        default: return defaultOfflineEffect(effect, action);
      }
    },
    discard: (error, action, retries) => {
      const status = error.status || error.statusCode;

      if (typeof status === 'undefined') {
        return true;
      }

      return status >= 400 && status < 500;
    },
    queue: {
      enqueue(array, action) {
        const { enqueueFirst, onlyKeepLatest } = action.meta.offline;
        let newQueue = [...array];

        if (onlyKeepLatest) {
          newQueue = filterEffectType(newQueue, action.type);
        }

        if (enqueueFirst) {
          newQueue.unshift(action);
        } else {
          newQueue.push(action);
        }

        return newQueue;
      },
      dequeue(array, action) {
        const [, ...rest] = array;
        return rest;
      },
      peek(array) {
        return array[0];
      }
    }
  };

  const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore
  } = createOffline({
    ...offlineConfig,
    persist: false
  });

  const persistedReducer = persistReducer({
    ...persistConfig,
    getStoredState: usesOldV5 ? getStoredStateFromOtherStorage(persistConfigOld) : getStoredStateMigrateV4(persistConfigOld)
  },
  offlineEnhanceReducer(reducers)
  );

  function createMiddleware(env) {
    const middlewares = [thunkMiddleware, offlineMiddleware];

    if (env === 'development') {
      const logger = createLogger({
        collapsed: true,
      });

      middlewares.push(logger);
    }

    return applyMiddleware(...middlewares);
  }

  store = createStore(
    enableBatching(persistedReducer),
    composeWithDevTools(
      offlineEnhanceStore,
      createMiddleware(env),
    ),
  );

  persistor = persistStore(store);

  // set __clear helper function on window object to clear the store
  window.__clear = storage.clear;

  window.localforage = localforage;
  window.storage = storage;
  window.oldStorage = oldStorage;
  window.store = store;
  window.persistor = persistor;
};
prepareStore();

export {
  store,
  storage,
  oldStorage,
  persistor
};
