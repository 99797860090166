import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

import { store } from '../../domains/redux/store';
import dataIdFromObject from './dataIdFromObject';

const tokenMiddleware = setContext(req => {
  const headers = req.headers || {};
  const token = store.getState().auth.token;

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  return { headers };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.warn(
        `[Network error]: (${operation.operationName}) ${networkError}`
      );
    }
  }
);

const client = new ApolloClient({
  link: ApolloLink.from([
    tokenMiddleware,
    errorLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URL
    })
  ]),
  cache: new InMemoryCache({
    dataIdFromObject
  }).restore(window.__APOLLO_STATE__)
});

export default client;
