const appVersion = {
  isDesktop: false,
  isMobile: false,
  isWeb: false,
  isIpad: false,
  isIosWebApp: false
};

if (!!~window.location.href.indexOf('app.asar')) {
  appVersion.isDesktop = true;
} else if (document.IS_CORDOVA) {
  if (navigator.appVersion.match(/(iPad)/i)) {
    appVersion.isIpad = true;
  } else {
    appVersion.isMobile = true; // if android
  }
} else if (navigator.appVersion.match(/(iPad)/i)) {
  appVersion.isIosWebApp = true;
} else {
  appVersion.isWeb = true;
}

module.exports = appVersion;
