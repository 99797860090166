const IMAGES = [
  require('../../../static/images/icons/heart.png'),
  require('../../../static/images/icons/rocket.png'),
  require('../../../static/images/icons/smiley-cool.png'),
  require('../../../static/images/icons/smiley.png'),
  require('../../../static/images/icons/star.png'),
  require('../../../static/images/icons/thumbsup.png'),
  require('../../../static/images/icons/tick.png')
];

export default function spawnIcon(parent, styles) {
  const icon = document.createElement('div');

  const dimension = Math.round(Math.random() * 50) + 50;
  const startPos = Math.round(Math.random() * 300) + 100;
  const leftPos = Math.round(Math.random() * (parent.offsetWidth - dimension));
  const imageIndex = Math.round(Math.random() * (IMAGES.length - 1));
  const duration = Math.random() * 3 + 1;

  icon.style.transform = `translateY(${startPos}%)`;
  icon.style.width = `${dimension}px`;
  icon.style.height = `${dimension}px`;
  icon.style.left = `${leftPos}px`; // range: 0 - 100% of parent - iconWidth
  icon.style.backgroundImage = `url(${IMAGES[imageIndex]})`;
  icon.style.animationDuration = `${duration}s`;
  icon.className = styles;
  parent.appendChild(icon);
  return { icon, duration };
}