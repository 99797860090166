import React, { Component } from 'react';
import { connect } from 'react-redux';

import { openUrl } from '../../../helpers/openUrlHandler';
import { Tooltip, Button } from '../../../components';

import { actions as authActions } from '../redux';
import { actions as licenseActions } from '../../license/redux';
import { actions as magazinesActions } from '../../magazines/redux';

import styles from './styles.less';
class LoginButton extends Component {

  render() {
    return (
      <div className={styles.wrapper}>
        <Button
          color="gray"
          icon={'exit'}
          onClick={this.onClick}
          classes="has-tooltip"
        >
          <Tooltip string={'Ausloggen'} />
        </Button>
      </div>
    );
  }

  onClick = () => {
    const { auth, clearAuth, clearUnlocked, setOverlay, setShowNoLicenseWarning } = this.props;
    const { user: { idToken } } = auth;

    setShowNoLicenseWarning(false);
    clearAuth();
    clearUnlocked();
    setOverlay('AUTH');

    // this is used so that on ios we use the safariviewcontroller, other platforms
    // will fallback to open the url on the same page
    openUrl(`${process.env.REACT_APP_AUTHENTICATION_URL}/logout?idToken=${idToken}`, false, '_self');
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth
  };
};

const mapDispatchToProps = {
  setShowNoLicenseWarning: authActions.setShowNoLicenseWarning,
  clearAuth: authActions.clearAuth,
  clearUnlocked: licenseActions.clearUnlocked,
  setOverlay: magazinesActions.setOverlay
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginButton);
