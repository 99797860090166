import React, { Component } from 'react';
import { connect } from 'react-redux';
import equal from 'lodash/isEqual';
import Swipe from 'react-easy-swipe';
import { store } from '../../domains/redux';

import performanceChecks from '../../helpers/performanceChecks';
import appVersion from '../../helpers/appVersion';

import { matomo } from '../../domains/matomo';
import BackgroundImage from './components/BackgroundImage';
import Hotspots from './components/Hotspots';
import { Button } from '../../components';
import LoadingSpinner from '../LoadingSpinner';

import updateBlobUrls from '../../domains/config/functions/updateBlobUrls';
import { getFileIds } from '../../domains/uploads';

import {
  TEMPLATE_TYPE_MAP, SMALL_TEMPLATE_TYPES, LARGE_TEMPLATE_TYPES, FULLSCREEN_TEMPLATE_TYPES,
  CONTAINER_TYPES, CONTAINER_TYPE_MAP
} from '../constants';

// template
import * as Templates from '../../templates';

import Container from '../Container';

import { actions as magazinesActions } from '../../domains/magazines/redux';
import { actions as magazineBlobsActions } from '../../domains/magazineBlobs/redux';
import { actions as darkenSurroundingsActions } from '../../domains/magazines/blacklist/redux';
import styles from './styles.less';

class Page extends Component {
  constructor(props) {
    super(props);
    const { currentMagazine, currentPageId } = props;

    const currentPage = currentMagazine &&
      currentMagazine.sections &&
      currentPageId
      ? currentMagazine.sections.reduce((acc, section) => (
        !acc
          ? section.pages.find(page => page.id === currentPageId)
          : acc
      ), false)
      : null;

    const sceneAndHotspots = this.calculateScenesAndHotspots(currentPage);

    // blob loading is only needed for environments which use blobs
    this.state = {
      currentPage,
      currentPageIndex: -1,
      showTemplate: false,
      componentName: '',
      templateContent: {},
      imageLoaded: false,
      imageLoadedScene: false,
      blobLoaded: !(appVersion.isWeb || appVersion.isIosWebApp),

      currentScene: false,
      scenes: sceneAndHotspots.scenes,
      allHotspots: sceneAndHotspots.allHotspots
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !equal(nextProps, this.props) || !equal(nextState, this.state);
  }

  componentWillReceiveProps(nextProps) {
    const { pageList, currentPageId } = this.props;

    if (currentPageId !== nextProps.currentPageId) {
      const currentPage = pageList.find(x => x.id === nextProps.currentPageId);
      const sceneAndHotspots = this.calculateScenesAndHotspots(currentPage);
      this.setState({
        currentPage,
        imageLoaded: false,
        scenes: sceneAndHotspots.scenes,
        allHotspots: sceneAndHotspots.allHotspots
      });
    }
  }

  componentWillMount() {
    const { hasUpdatedBlobs } = this.props;

    if (appVersion.isWeb || appVersion.isIosWebApp) {
      if (!hasUpdatedBlobs) {
        this.updateBlobUrls();
      } else {
        this.setState({
          blobLoaded: true
        });
      }
    }
  }

  updateBlobUrls = async () => {
    const { currentMagazine, setMagazinesWithUpdatedBlobs } = this.props;
    if (performanceChecks.enabled) {
      console.info('LOADING BLOBS PAGE START');
      performanceChecks.blobsPageStart = new Date();
    }

    // update blob urls for magazine content (only already downloaded files will get updated!)
    const filter = getFileIds(currentMagazine, { testKey: /file_/ });
    await updateBlobUrls(filter);
    setTimeout(() => {
      this.setState({ blobLoaded: true });
      store.dispatch({
        type: 'SET_BLOB_LOADING_PROGRESS',
        payload: {
          blobLoadingProgress: 0,
          blobFilesToLoad: 0,
          blobLoadedFiles: 0
        }
      });
      setMagazinesWithUpdatedBlobs(currentMagazine.id);

      if (performanceChecks.enabled) {
        performanceChecks.blobsPageEnd = new Date();
        console.info('LOADING BLOBS PAGE END + 1second');
        console.info(performanceChecks.blobsPageDuration());
      }

    }, 1000);
  }

  componentDidUpdate() {
    // go to first page is 
    const { currentMagazine, pageList, currentPageId, setPage, darkenSurroundings } = this.props;

    if (currentMagazine && pageList.length && !currentPageId) {
      setPage(pageList[0].id);
      this.setState({
        currentPage: pageList[0]
      });
    }

    if (darkenSurroundings) {
      document.body.classList.add(styles.darkenBody);
    } else {
      document.body.classList.remove(styles.darkenBody);
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('mousedown', this.closeCurrentHotspot);
  }

  componentWillUnmount() {
    const { internalBackButton } = this.props;

    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('mousedown', this.closeCurrentHotspot);

    if (internalBackButton.visible) {
      this.setInternalBackButton();
    }
  }

  nextPage(index) {
    const { internalBackButton, pageList, setPage } = this.props;

    this.setState({
      showTemplate: false
    });

    if (internalBackButton.visible) {
      this.setInternalBackButton();
    }
    index < pageList.length - 1 &&
      setPage(pageList[index + 1].id);
  }

  prevPage(index) {
    const { internalBackButton, pageList, setPage } = this.props;

    this.setState({
      showTemplate: false
    });

    if (internalBackButton.visible) {
      this.setInternalBackButton();
    }
    index - 1 >= 0 &&
      setPage(pageList[index - 1].id);
  }

  handleKeyUp(e) {
    switch (e.keyCode) {
      case 37:
        this.handlePageChange(-1);
        break;
      case 39:
        this.handlePageChange(1);
        break;
      case 27:
        this.handleClose();
        break;
      default:
    }
  }


  handleRightSwipe = e => {
    this.handlePageChange(-1);
  }

  handleLeftSwipe = e => {
    this.handlePageChange(1);
  }

  handlePageChange = direction => {
    const { pageList } = this.props;
    const { currentPage, showTemplate } = this.state;
    if (showTemplate && !this.isSmallTemplate) return;

    let currentPageIndex = pageList.findIndex(x => x.id === currentPage.id);
    //console.log(direction, direction >= 0);

    if (direction >= 0) {
      //console.log('next');
      this.nextPage(currentPageIndex);
    } else {
      //console.log('prev');
      this.prevPage(currentPageIndex);
    }
  }

  setInternalBackButton = () => {
    const { internalBackButton, setInternalBackButton } = this.props;
    const { currentPage } = this.state;

    if (internalBackButton.visible) {
      setInternalBackButton(false, null);
    } else {
      setInternalBackButton(true, currentPage.id);
    }
  }

  setDarkenSurroundings = darkenSurroundings => {
    const { setDarkenSurroundings } = this.props;

    setDarkenSurroundings(darkenSurroundings);
  }

  render() {
    const {
      pageList, setPage, internalBackButton, showHotspots, toggleHotspots,
      blobLoadingProgress, blobFilesToLoad, blobLoadedFiles
    } = this.props;
    const {
      currentPage, showTemplate, templateContent, imageLoaded, currentScene, imageLoadedScene,
      componentName, blobLoaded
    } = this.state;

    if (!blobLoaded) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingProgress}>
            <LoadingSpinner width={100} progress={blobFilesToLoad && Math.floor(blobLoadingProgress)} caption={blobFilesToLoad > 0 ? `${blobLoadedFiles}/${blobFilesToLoad} Magazine Assets` : ''} />
          </div>
        </div>
      );
    }

    let currentPageIndex = -1;
    let hotspots = [];
    let backgroundImageScene = false;

    this.isSmallTemplate = showTemplate && templateContent && SMALL_TEMPLATE_TYPES.indexOf(templateContent.type) >= 0;
    const isLargeTemplate = showTemplate && templateContent && LARGE_TEMPLATE_TYPES.indexOf(templateContent.type) >= 0 && !templateContent.file_background_image_id;

    if (currentPage) {
      currentPageIndex = pageList.findIndex(x => x.id === currentPage.id);

      if (currentScene) {
        hotspots = currentScene.hotspots;
        backgroundImageScene = currentScene.file_image_id;
      } else {
        hotspots = currentPage.hotspots;
      }
    }

    // if we show a container and it is open, we want to be able
    // to close the container by clicking in the dark area
    // but it should not open hotspots underneath the dark area
    // so we have to prevent this here.
    let deactivateHotspots = false;
    if (showTemplate && (componentName === CONTAINER_TYPES.CONTAINER_SEQUENCE ||
      componentName === CONTAINER_TYPES.CONTAINER_PAIR ||
      componentName === CONTAINER_TYPES.CONTAINER_COMBINATION)) {
      deactivateHotspots = true;
    }

    return (
      <div className={`${styles.page} ${!imageLoaded ? styles.pageLoading : ''}`}
        tabIndex="0">
        {currentPage &&
          <Swipe
            tolerance={200}
            onSwipeRight={this.handleRightSwipe}
            onSwipeLeft={this.handleLeftSwipe} >
            <BackgroundImage file_image_id={currentPage.file_image_id} onImgLoad={this.handleImgLoad} />
          </Swipe>
        }

        {currentScene &&
          <div ref={node => this.currentSceneRef = node}>
            <div className={styles.sceneMenu}>
              <Button
                color="hotspot"
                icon="interaction"
                shadow
                active={showHotspots}
                onClick={() => { return toggleHotspots(!showHotspots); }}
              />
              <Button
                color="yellow"
                icon="close-large"
                shadow
                onClick={() => {
                  this.setDarkenSurroundings(false);
                  this.setState({ currentScene: false });
                }}
              />
            </div>
            <div className={styles.sceneContainer}>
              <div className={styles.sceneRelativeContainer}>
                <div className={styles.sceneNowAbleToScrollNicely}>
                  <BackgroundImage file_image_id={backgroundImageScene} forScene onImgLoad={this.handleSceneImgLoad} />
                  <Hotspots
                    hotspots={hotspots}
                    showHotspots={showHotspots && imageLoadedScene}
                    onHotspotClick={this.hotspotClick}
                    isScene
                    deactivateHotspots={deactivateHotspots}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {!currentScene &&
          <Hotspots
            hotspots={hotspots}
            showHotspots={showHotspots && imageLoaded}
            onHotspotClick={this.hotspotClick}
            deactivateHotspots={deactivateHotspots}
          />
        }

        {this.showTemplate(this.isSmallTemplate, isLargeTemplate)}

        {!imageLoaded && <div className={styles.preloader}>
          <LoadingSpinner width={100} />
        </div>}


        {internalBackButton.visible &&
          <Button
            color="yellow"
            icon="arrow-left"
            shadow
            position="back"
            onClick={() => { this.setInternalBackButton(); setPage(internalBackButton.originPageId); }}
          />
        }

        {currentPageIndex !== -1 &&
          <div className={styles.pagination}>
            <Button
              icon="chevron-left"
              color="yellow"
              pageNumber={currentPage.page_number_left || 'false'}
              onClick={() => { this.prevPage(currentPageIndex); }}
              disabled={currentPageIndex <= 0}
              styles={`${styles.paginationButton} ${styles.prev}`}
              shadow
            />
            <Button
              icon="chevron-right"
              color="yellow"
              pageNumber={currentPage.page_number_right || 'false'}
              onClick={() => { this.nextPage(currentPageIndex); }}
              disabled={currentPageIndex >= this.props.pageList.length - 1}
              styles={`${styles.paginationButton} ${styles.next}`}
              shadow
            />
          </div>
        }
      </div >
    );
  }

  closeCurrentHotspot = e => {
    const { overlay } = this.props;
    const { currentScene, showTemplate } = this.state;
    if (this.currentTemplate && this.currentTemplate.contains(e.target)) return;
    if (overlay === 'HELP') return;

    this.setState({
      showTemplate: false
    });

    if (!currentScene) {
      this.setDarkenSurroundings(false);
      return;
    }

    if (currentScene && !showTemplate && !this.currentSceneRef.contains(e.target)) {
      this.setState({
        currentScene: false
      });

      this.setDarkenSurroundings(false);
    }
  }

  showTemplate = (isSmallTemplate, isLargeTemplate) => {
    const { setPage } = this.props;
    const { showTemplate, componentName, templateContent } = this.state;

    if (showTemplate) {
      if (componentName === CONTAINER_TYPES.CONTAINER_SEQUENCE ||
        componentName === CONTAINER_TYPES.CONTAINER_PAIR ||
        componentName === CONTAINER_TYPES.CONTAINER_COMBINATION) {
        return (
          <div className={styles.fullscreenTemplateContainer} ref={node => this.currentTemplate = node}>
            <Container container={templateContent} onClose={this.handleClose} />
          </div>
        );
      } else {
        return (
          <div className={
            `${isSmallTemplate ?
              styles.smallTemplateContainer :
              isLargeTemplate ?
                styles.largeTemplateContainer :
                ''
            }`
          }>
            {componentName && <div className={styles.templateContainer} ref={node => this.currentTemplate = node}>
              {React.createElement(
                Templates[componentName],
                {
                  ...templateContent,
                  onClose: this.handleClose,
                  setPage: setPage,
                  setInternalBackButton: this.setInternalBackButton
                }
              )}
            </div>
            }
          </div>
        );
      }
    }
  }

  hotspotClick = e => {
    const { scenes, allHotspots } = this.state;
    const id = parseInt(e.currentTarget.dataset.id, 10);

    const hotspot = allHotspots.find(h => h.id === id);

    // darken surroundings if correct hotspot type
    const validElementToApplyDarkening = hotspot.container ||
      FULLSCREEN_TEMPLATE_TYPES.indexOf(hotspot.content.type) >= 0 ||
      (LARGE_TEMPLATE_TYPES.indexOf(hotspot.content.type) >= 0 && hotspot.content.file_background_image_id);
    if (validElementToApplyDarkening) {
      this.setDarkenSurroundings(true);
    }

    // if the hotspot is a link to a scene - link to that scene
    if (hotspot.content) {
      if (TEMPLATE_TYPE_MAP[hotspot.content.type] === TEMPLATE_TYPE_MAP.CONTENT_GO_TO_SCENE &&
        hotspot.content.datapool_id) {
        const currentScene = scenes.find(s => s.id === hotspot.content.datapool_id);

        this.setState({
          currentScene,
          imageLoadedScene: false
        });
        matomo(['trackEvent', 'ClickedHotspot', TEMPLATE_TYPE_MAP[hotspot.content.type]]);
        return;
      }
    }

    // if the hotspot is a scene itself - show the first scene ofthe container, bc we dont have another info
    if (hotspot.container) {
      if (hotspot.container.type === CONTAINER_TYPES.CONTAINER_SCENE &&
        hotspot.container.datapools && hotspot.container.datapools[0]) {

        this.setState({
          currentScene: hotspot.container.datapools[0],
          imageLoadedScene: false
        });
        matomo(['trackEvent', 'ClickedHotspot', CONTAINER_TYPE_MAP[hotspot.container.type]]);
        return;
      }
    }

    if (!hotspot.content && !hotspot.container) {
      console.warn('Clicked hotspot does not have any content!', hotspot);
      return;
    }

    this.setState({
      showTemplate: true,
      componentName: hotspot.content && hotspot.content.type ? TEMPLATE_TYPE_MAP[hotspot.content.type] : hotspot.container.type,
      templateContent: hotspot.content || hotspot.container
    });
    matomo(['trackEvent', 'ClickedHotspot', hotspot.content && hotspot.content.type ? TEMPLATE_TYPE_MAP[hotspot.content.type] : CONTAINER_TYPE_MAP[hotspot.container.type]]);
  }

  handleClose = () => {
    const { currentScene } = this.state;
    this.setState({
      showTemplate: false
    });

    if (!currentScene) {
      this.setDarkenSurroundings(false);
    }
  }

  handleImgLoad = e => {
    this.setState({
      imageLoaded: true
    });
  };

  handleSceneImgLoad = e => {
    this.setState({
      imageLoadedScene: true
    });
  };

  calculateScenesAndHotspots = currentPage => {
    let scenes = [];
    let allHotspots = [];
    if (currentPage) {
      // get all hotspots of this page (including the ones in scenes) and all scene datapools
      allHotspots = [...currentPage.hotspots];

      currentPage.hotspots.forEach(hotspot => {
        if (hotspot && hotspot.container && hotspot.container.type === CONTAINER_TYPES.CONTAINER_SCENE) {

          hotspot.container.datapools.forEach(datapool => {
            if (datapool && datapool.hotspots) {

              allHotspots = [...allHotspots, ...datapool.hotspots];
              scenes.push(datapool);
            }
          });
        };
      });
    }
    return { allHotspots, scenes };
  }
}

const mapStateToProps = ({ magazines, magazineBlobs, assets, darkenSurroundings: { darkenSurroundings } }) => {
  const currentMagazine = magazines.magazines.find(mag => mag.id === magazines.currentMagazineId);
  return {
    currentMagazine: currentMagazine,
    pageList: currentMagazine && currentMagazine.sections ? currentMagazine.sections.reduce((acc, key) => {
      return [
        ...acc,
        ...key.pages
      ];
    }, []) : [],
    currentPageId: magazines.pageId,
    showHotspots: magazines.showHotspots,
    internalBackButton: magazines.internalBackButton,
    hasUpdatedBlobs: magazineBlobs.list.findIndex(m => m === currentMagazine.id) > -1,
    blobLoadingProgress: assets.blobLoadingProgress,
    blobFilesToLoad: assets.blobFilesToLoad,
    blobLoadedFiles: assets.blobLoadedFiles,
    darkenSurroundings,
    overlay: magazines.overlay
  };
};

const mapDispatchToProps = {
  setPage: magazinesActions.setPageId,
  setInternalBackButton: magazinesActions.setInternalBackButton,
  toggleHotspots: magazinesActions.toggleHotspots,
  setMagazinesWithUpdatedBlobs: magazineBlobsActions.setMagazinesWithUpdatedBlobs,
  setDarkenSurroundings: darkenSurroundingsActions.setDarkenSurroundings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);