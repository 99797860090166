import { batchActions } from 'redux-batched-actions';
import { storage, store } from '../redux';
import appVersion from '../../helpers/appVersion';

import { actions } from './redux';

let deleteFileActionBuffer = [];
let deleteFileTimeout = false;

let ipcRenderer;
if (appVersion.isDesktop) {
  ipcRenderer = window.require('electron').ipcRenderer;
}


const DELETE_FILE = 'deletefile';
const DELETED_FILE = 'deletedfile';

/**
 * Batch multiple delete file actions into one batched action
 */
function deleteFile(file) {
  clearTimeout(deleteFileTimeout);

  deleteFileActionBuffer.push(actions.deleteFileBlobUrl(file));

  deleteFileTimeout = setTimeout(() => {
    store.dispatch(batchActions(deleteFileActionBuffer));
    deleteFileActionBuffer = [];
  }, 250);
}

/**
 * @param {*string} name the name for this file, will be used to delete from localstorage
 */
async function deleteAsset(name) {
  if (appVersion.isWeb || appVersion.isIosWebApp) {
    await storage.removeItem(name);
  } else if (document.IS_CORDOVA) {
    const path = store.getState().assets.files[name];

    if (path) {
      // kinda unsafe if this directory ever changes
      const filename = path.replace(window.cordova.file.dataDirectory, '');

      const fileEntry = await getFileEntry(resolveFilename(filename));
      await deleteFileOnCordova(fileEntry);
    } else {
      // console.error('Error finding path to file for deletion', name);
    }
  } else if (appVersion.isDesktop) {
    const path = store.getState().assets.files[name];
    if (path) {
      ipcRenderer.setMaxListeners(500); // since we delete all at the same time and we dont know how many there are this is a bit futile
      await deleteFileOnElectron(path);
    }
  }

  deleteFile(name);
  return;
}

// helper function for cordova
async function getFileEntry(completeFilename) {
  return new Promise((resolve, reject) => {
    window.dirEntry.getFile(completeFilename, { create: false }, fileEntry => {
      resolve(fileEntry);
    }, err => {
      // HANDLE ERROR
      console.error('Error acquiring file writer for file:', completeFilename, err);
      reject();
    });
  });
};

async function deleteFileOnCordova(fileEntry) {
  return new Promise((resolve, reject) => {
    fileEntry.remove(function () {
      // console.info('File has been deleted successfully.');
      resolve();
    }, function (err) {
      console.error('Error deleting file:', err);
      reject();
    });
  });
};

// helper function for electron
async function deleteFileOnElectron(path) {
  return new Promise(async function (resolve, reject) {
    ipcRenderer.send(DELETE_FILE, path);

    ipcRenderer.once(DELETED_FILE, (event, fileUrl, err) => {
      if (err) {
        console.warn('Error: Delete File on Electron:', path, err);
        resolve();
      } else {
        // console.log('Saved file ' + fileUrl);
        resolve(fileUrl);
      }
    });
  });
};

function resolveFilename(filename) {
  return filename.replace(/\?/g, '_');
}

export default deleteAsset;
