import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import mime from 'mime';
import clearCacheOnIPad from './helpers/clearCacheOnIPad';
import App from './App';
import openIdConnectLogin from './domains/auth/functions/openIdConnectLogin';
import appVersion from './helpers/appVersion';

/* define audio/mpeg as mp3
 when uploading an mp3 file, the mime type will be set to
 audio/mpeg, when downloading this file, to get the extension we use the mime.getExtension function
 since mime uses some dependencies for the mapping and the mapping in
 mime-db looks like this:

  "audio/mpeg": {
    "source": "iana",
    "compressible": false,
    "extensions": ["mpga","mp2","mp2a","mp3","m2a","m3a"]
  },

  mime will apparently only return "mpga". which means the file gets saved as .mpga.
  This will cause issues on ios, since ios does not support mpga files
  (even though just renaming them to .mp3 will make them work again...)

  So in order to fix this, we redefine audio/mpeg to just mp3

  I tried to overwrite the mime type when uploading to audio/mp3 but this does not work
  since the file variable is not a normal javascript object where we can overwrite the file.

  I also did not want to change anything in the upload service, since it is used in multiple projects
  and received a lot of updates in the meantime, which would make it unclear if everything would still work
*/
mime.define({ 'audio/mpeg': ['mp3'] }, true);

if (document.IS_CORDOVA) {
  document.addEventListener('deviceready', prepareForCordova, false);
} else {
  renderApp();
}

async function prepareForCordova() {
  // clearCacheOnIPad() used in other places too, but does only work here, even though there is a success callback called on all of them
  // also calling window.CacheClear(...) in the console when the app is open seems to have no effect on the size.
  clearCacheOnIPad();

  await new Promise(resolve => {
    window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, async fs => {
      window.resolveLocalFileSystemURL(window.cordova.file.dataDirectory, async dirEntry => {
        window.dirEntry = dirEntry;
        resolve();
      }, err => {
        console.warn('resolveLocalFileSystemURL failed:', err);
      });
    }, err => {
      console.warn('requestFileSystem failed:', err);
    });
  });

  window.IonicDeeplink.route({
    '/': {
      target: '',
      parent: ''
    }
  }, function(match) {
    console.info('MATCH', match);

    // this approach does not work, since when not calling with the schulverlag
    // scheme, the app wont even get this event. Except for the very first time.
    // Thanks Apple
    // if(match.$link.scheme !== 'schulverlag') {
    //   const redirectLink = `schulverlag://${match.$link.path}${match.$link.fragment ? `#${match.$link.fragment}` : ''}`;
    //   console.info(`redirect to schulverlag scheme: ${redirectLink}`);
    //   window.location.href = redirectLink;
    // }

    // Since we are now triggering this event with schulverlag custom scheme
    // after login in service, we then have to hide the Safariviewcontroller,
    // so the user sees the app.
    if (appVersion.isIpad) {
      window.SafariViewController.isAvailable(function (available) {
        if (available) {
          setTimeout(function () {
            window.SafariViewController.hide();
          }, 10);
        }
      });
    }

    openIdConnectLogin(match.$link.url);

  }, function(nomatch) {
    console.info('NO MATCH');
  });

  renderApp();
}

async function renderApp() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

registerServiceWorker();
// unregister();