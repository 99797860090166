import React, { Component } from 'react';
import PropTypes from 'prop-types';

import sanitizeHtml from 'sanitize-html';

import styles from './styles.less';


class ImpressumOverlay extends Component {

  render() {
    const allowedTags = ['h1', 'h2', 'h3', 'p', 'br', 'em', 'strong', 'u', 'a'];
    const allowedAttributes = { a: ['href'] };

    let displayText = sanitizeHtml(this.props.imprint, { allowedTags, allowedAttributes });
    if (displayText === 'null') {
      displayText = 'Kein Impressum vorhanden.';
    }
    return (
      <div className={styles.overlay}>
        <div className={styles.container} dangerouslySetInnerHTML={{ __html: displayText }} />
      </div>
    );
  }
}


ImpressumOverlay.propTypes = {
  setOverlay: PropTypes.func.isRequired,
  imprint: PropTypes.string.isRequired
};

export default ImpressumOverlay;
