export default async function getToken({ username, email, password, token = false }) {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/remoteLogin`,
    { 
      method: 'POST',
      body: JSON.stringify({
        username,
        email,
        password,
        token
      }),
      headers: { 
        'Authorization': token ? `Bearer ${token}` : 'anonymous',
        'Content-Type': 'application/json',
      }
    }
  );

  if (res.status !== 200) {
    return false;
  }

  return await res.text();
}
