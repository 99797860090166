import { combineReducers } from 'redux';

import getDynamicReducers from './getDynamicReducers';

/**
 * Before adding your reducer here, think about whether it's possible to
 * write it so that it can be picked up by "getDynamicReducers()".
 * 
 * For this to work, you just have to create a file "redux.js" which exports
 * {string} reducerKey - this will be the key in your application state under which this reducers state is stored
 * {function} reducer - the reducer function
 */
export default combineReducers({
  ...getDynamicReducers()
});