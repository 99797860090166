import React from 'react';

import { Button } from '../../../';

const ToastCloseButton = ({ closeToast }) => (
  <Button
    icon="close"
    onClick={() => closeToast()}
  />
);

export default ToastCloseButton;