import React from 'react';
import * as Templates from './';
import { TEMPLATE_TYPE_MAP } from '../components/constants';

// check what type of answer it should be
export default ({ inputId, answers, ...props }) => {
  const answer = answers.find(a => (a.config && JSON.parse(a.config).id === inputId));
  return (
    React.createElement(
      Templates[TEMPLATE_TYPE_MAP[JSON.parse(answer.config).type]],
      {
        answers,
        ...props
      })
  );
};