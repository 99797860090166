import { toast } from 'react-toastify';

import { NOTIFICATION_STATE } from './constants';

const defaultState = {
  notifications: []
};

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const UPDATE_TOAST_ID = 'UPDATE_TOAST_ID';
const REMOVE_TOAST_IDS = 'REMOVE_TOAST_IDS';
const MARK_AS_READ = 'MARK_AS_READ';

const download = () => {
  return {
    type: GET_NOTIFICATIONS,
    meta: {
      offline: {
        effect: {
          type: 'graphql',
          fetchPolicy: 'network-only',
          query: `
            query getNotifications {
              notifications {
                results {
                  id
                  message
                }
              }
            }
          `,
          operationName: 'getNotifications'
        },
        commit: { type: SET_NOTIFICATIONS }
      }
    }
  };
};

const markAsRead = notification => {
  return {
    type: MARK_AS_READ,
    payload: { notification }
  };
};

const updateToastId = notifications => {
  return {
    type: UPDATE_TOAST_ID,
    payload: { notifications }
  };
};

const removeToastIds = notifications => {
  return {
    type: REMOVE_TOAST_IDS,
    payload: { notifications }
  };
};

export const reducer = (state = defaultState, { type, payload }) => {
  let notifications;
  switch (type) {
    case SET_NOTIFICATIONS:
      let notificationsPayload = payload.data.notifications.results;
      if (notificationsPayload) {
        notifications = [...payload.data.notifications.results.map(notification => ({ ...notification }))];

        // if notifications in admin got removed, remove shown notifications
        const notificationsToRemove = state.notifications.filter(notification => !notifications.find(n => n.id === notification.id));
        notificationsToRemove.forEach(notification => {
          toast.dismiss(notification.toastId);
        });

        // add new notifications, keep toastId and state for already existing notifications
        notifications.forEach(notification => {
          state.notifications.forEach(n => {
            if (n.id === notification.id) {
              notification.toastId = n.toastId;
              notification.state = n.state || NOTIFICATION_STATE.SHOWN;
            }
          });
        });

        return {
          ...state,
          notifications,
          _loaded: Date.now()
        };
      }

      return { ...state };
    case REMOVE_TOAST_IDS:
      notifications = [...state.notifications];
      const notificationsWhereToRemoveToastId = payload.notifications.map(notification => notifications.find(n => notification.id === n.id));
      notificationsWhereToRemoveToastId.forEach(notification => delete notification.toastId);

      return {
        ...state,
        notifications
      };
    case UPDATE_TOAST_ID:
      return {
        ...state,
        notifications: payload.notifications,
      };
    case MARK_AS_READ:
      notifications = [...state.notifications];
      const notification = notifications.find(notification => notification.id === payload.notification.id);
      if (notification) {
        notification.state = NOTIFICATION_STATE.READ;
      }

      return {
        ...state,
        notifications
      };
    default:
      return state;
  }
};

export const reducerKey = 'notifications';
export const actions = {
  download,
  updateToastId,
  removeToastIds,
  markAsRead
};