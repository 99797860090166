import React, { Component } from 'react';

import BasicHotspot from './BasicHotspot';

import styles from './stylesEditorHotspot.less';

class EditorHotspot extends Component {

  render () {
    const { hotspot } = this.props;

    const draggablePosition = {
      left: hotspot.position.left + 'px',
      top: hotspot.position.top + 'px',
      width: hotspot.position.width + 'px',
      height: hotspot.position.height + 'px',
      transform: 'rotate(' + hotspot.position.angle + 'deg)',
      borderRadius: hotspot.position.type === 'circle' ? '50%' : 0
    };

    const hotspotPosition = {
      ...hotspot.position,
      left: 0,
      top: 0,
    };

    hotspot.position = hotspotPosition;


    // this is the structure how the editor stuff should be separated from the basic component, but still have it as its base
    // most of the editing stuff should happen here
    // if really necessary things can get adapted in the basic hotspot with the edit flag, but this should be avoided if possible
    return (
      <div
        className={styles.draggable}
        style={{
          ...draggablePosition
        }}
        draggable={true}
        onDragStart={this.onDragStart}
        onDrag={this.onDrag}
      >
        <BasicHotspot
          hotspot={hotspot}
          showHotspot={true}
          editMode={true}
        />
        <div className={styles.topLeft} />
      </div>
    );
  }
}

EditorHotspot.defaultProps = {
};
  

export default EditorHotspot;