import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Header from '../../Components/Header';
import Button from '../../../Button';

import styles from '../styles/result.less';

import spawnIcon from '../../functions/spawnIcon';
import { RESULT_SPAWN_ICONS } from '../../../constants';

let spawnElementsTimeout = [];

export default class Result extends Component {
  componentDidMount() {
    if (this.$buttons) {
      for (let index = 0; index < RESULT_SPAWN_ICONS; index++) {
        this.spawn();
      }
    }
  }

  componentWillUnmount() {
    for (let index = 0; index < spawnElementsTimeout.length; index++) {
      clearTimeout(spawnElementsTimeout[index]);
    }
  }

  render() {
    const { resultData, showDatapools, datapoolName, toggleInformation, information, showInformation, onClose, restart, player } = this.props;
    return (
      <div className={styles.resultContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          toggleInformation={toggleInformation}
          information={information}
          showInformation={showInformation}
          onClose={onClose}
        />
        <div className={styles.resultContent}>
          <div className={styles.circleContainer} >
            {this.renderCircles(player.player1, resultData[player.player1] > 0 && resultData[player.player1] >= resultData[player.player2])}
            <div className={styles.result}>
              <div className={styles.text}>
                {`${resultData[player.player1]}:${resultData[player.player2]}`}
              </div>
            </div>
            {this.renderCircles(player.player2, resultData[player.player2] > 0 && resultData[player.player2] >= resultData[player.player1])}
          </div>
          <div ref={buttons => this.$buttons = buttons} className={styles.buttons}>
            <Button icon="close-large" classes={styles.button} onClick={onClose} />
            <Button icon="repeat" classes={styles.button} onClick={restart} />
          </div>
        </div>
      </div>
    );
  }

  renderCircles = (player, hasTrophy) => {
    const { data } = this.props;
    const states = [];
    const playerProgress = data.filter(progress => progress.player === player);
    for (let i = 0; i < playerProgress.length; i++) {
      states.push(
        <div key={`full_${i}`} className={`${styles.progressCircle} ${this.getColor(playerProgress[i].status)} `} />
      );
    }
    return (
      <div className={styles.playerContainer} >
        <div className={`${styles.trophyContainer} ${hasTrophy && styles.trophy}`} />
        <div className={styles.playerName}>{player}</div>
        <div className={`${styles.progressContainer} ${states.length <= 5 && styles.center}`}>{states}</div>
      </div>
    );
  }

  getColor = status => {
    if (status === 'right') {
      return styles.green;
    } else if (status === 'answered') {
      return styles.grey;
    } else if (status === 'wrong') {
      return styles.red;
    }
  };

  spawn = () => {
    const element = spawnIcon(this.$buttons, styles.emoji);

    spawnElementsTimeout.push(setTimeout(() => {
      this.$buttons.removeChild(element.icon);
      this.spawn();
    }, element.duration * 1000 + 1000));  // remove element after duration + 1 second extra
  }
}

Result.propTypes = {
  restart: PropTypes.func.isRequired,
  showDatapools: PropTypes.func.isRequired,
  toggleInformation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  datapoolName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  resultData: PropTypes.objectOf(PropTypes.number).isRequired,
  player: PropTypes.objectOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string)
};

Result.defaultProps = {
  datapoolName: '',
  colors: ['green', 'yellow', 'red']
};