import appVersion from './appVersion';

export default function clearCacheOnIPad() {
  if (appVersion.isIpad) {
    window.CacheClear(success => {
      console.info('CLEARED CACHE', success);
    }, error => {
      console.error('Error clearing cache', error);
    });
  }
}
