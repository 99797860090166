import { TASK_TYPE_MAP, NEXT_TASK_TYPE } from '../../constants';

export default function nextTask(tasks, answerGroup, enteredAnswer, type) {
  let nextTask = null;
  switch (TASK_TYPE_MAP[answerGroup.type]) {
    case 'Array':
      // get all correct answers from the current task
      nextTask = answerGroup.answers.find(answer => answer.id === enteredAnswer[0]).nextTask.id;
      break;
    case 'String':
    case 'ANSWER_FACT_FICTION':
    case 'DualOrder':
    case 'Cloze':
    case 'Speak':
      nextTask = answerGroup.answers[0].nextTask.id;
      break;
    default:
      console.warn('Answer Type not found for ', answerGroup.type);
      break;
  }
  switch (type) {
    case NEXT_TASK_TYPE.INDEX:
      return tasks.findIndex(task => task.id === nextTask);
    case NEXT_TASK_TYPE.TASK:
      return tasks.find(task => task.id === nextTask);
    default:
      return console.warn('Return type not found for ', type);
  }
}
