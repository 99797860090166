import mime from 'mime';

import DownloadHandler, { ERROR_MESSAGES } from './DownloadHandler';


class CordovaDownloadHandler extends DownloadHandler {
  getPathToFile = (name, ext = 'txt', omitDataDirectory = true) => {
    const dataDirectory = !omitDataDirectory ?
      window.cordova.file.dataDirectory :
      '';
    return `${dataDirectory}${this.resolveFilename(name)}.${ext}`;
  }

  isFileDownloaded = async (name, ext) => {
    const fileName = this.getPathToFile(name, ext);
    return new Promise(async resolve => {
      window.dirEntry.getFile(fileName, { create: false }, () => {
        resolve(true); // file exists
      }, err => {
        if (err && err.code !== 1) {
          DownloadHandler.errorMessage(ERROR_MESSAGES.FILE_EXISTS, err);
        }
        resolve(false); // file does not exist
      });
    });
  }

  downloadFile = async (url, name) => {
    return new Promise(async (resolve, reject) => {
      const fileTransfer = new window.FileTransfer();
      const uri = encodeURI(url);
      const type = await this.getFileExtension(url);
      const path = this.getPathToFile(name, type, false);
      const pathToSaveInStore = this.getPathToFile(name, type);
      fileTransfer.download(uri, path, entry => {
        resolve(pathToSaveInStore);
      }, err => {
        DownloadHandler.errorMessage(ERROR_MESSAGES.DOWNLOAD, err);
        reject(err);
      });
    });
  }

  saveBlobToFilesystem = (blob, url, name) => {
    return new Promise(async (resolve, reject) => {
      const ext = mime.getExtension(blob.type);
      const completeFilename = this.getPathToFile(name, ext, true);
      const path = this.getPathToFile(name, ext);
      const fileEntry = await this.getFileEntry(completeFilename);
      await this.writeFileOnCordova(fileEntry, blob);
      resolve(path);
    });
  }

  writeFileOnCordova = async (fileEntry, dataObj) => {
    return new Promise((resolve, reject) => {
      fileEntry.createWriter(function (fileWriter) {
        fileWriter.onwriteend = function () {
          // console.log('Successful file write...');
          resolve();
        };

        fileWriter.onerror = function (err) {
          reject();
          DownloadHandler.errorMessage(ERROR_MESSAGES.WRITE_FILE_ON_CORDOVA, err);
        };

        fileWriter.write(dataObj);
      });
    });
  }

  getFileEntry = async completeFilename => {
    return new Promise((resolve, reject) => {
      window.dirEntry.getFile(completeFilename, { create: true, exclusive: false }, fileEntry => {
        resolve(fileEntry);
      }, err => {
        // HANDLE ERROR
        DownloadHandler.errorMessage(ERROR_MESSAGES.WRITING_BLOB_TO_FS, err);
        reject();
      });
    });
  };

  returnCorrectErrorObject = () => {
    return this.transparentGif;
  }

  downloadAsset = async ({ url, name, regenerateBlobUrl = false }) => {
    if (typeof url !== 'string') {
      return this.returnCorrectErrorObject();
    }

    let persistedUrl;

    persistedUrl = await this.downloadAsFile(url, name);
    if (persistedUrl) {
      this.queueSetFile({ name, url: persistedUrl });
    }

    return persistedUrl || this.returnCorrectErrorObject();
  }
}

export default CordovaDownloadHandler;