import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '../Button';

import styles from './styles.less';

export default class Popup extends Component {
  render() {
    const { className, classNameContent, show, accept, cancel, title, text } = this.props;
    if (!show) return null;
    return (
      <div className={`${styles.popupContainer} ${className}`}>
        <div className={`${styles.popupContent} ${classNameContent}`}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.text}>
            {text}
          </div>
          <div className={styles.buttons}>
            <Button icon="check" color="yellow" classes={styles.button} onClick={accept} />
            <Button icon="close-large" color="yellow" classes={styles.button} onClick={cancel} />
          </div>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

Popup.defaultProps = {
  className: '',
  show: false,
  title: 'Titel',
  text: 'Text'
};