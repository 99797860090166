import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TaskTemplate } from '@ovos-media/templates/dist/sp';

import { generateUrl } from '../../../../domains/uploads';
import * as Templates from '../../../../templates';
import Header from '../../Components/Header';
import Button from '../../../Button';
import { TextWithAudio } from '@ovos-media/templates/dist/sp';

import { TEMPLATE_TYPE_MAP, VALIDATION } from '../../../constants';

import styles from '../styles/taskSequence.less';

export default class TaskSequence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [props.task],
      startSlide: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.$taskTempalte && !nextProps.enteredAnswer) {
      this.$taskTempalte.resetScroll(); // set scrollTop position to 0
    }
    if (this.props.task.id !== nextProps.task.id) {
      this.state.tasks.push(nextProps.task);
      this.setState({
        startSlide: true
      });
    }
  }

  render() {
    const { showDatapools, datapoolName, backgroundColor, togglePopup, showInformation, toggleInformation,
      startAnimation, task, canContinue, verify, information, setup } = this.props;
    const { tasks, startSlide } = this.state;

    if (setup) {
      return this.setupScreen();
    }

    return (
      <div className={`${styles.sequenceContainer} ${styles[backgroundColor]}`}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          onClose={togglePopup}
          toggleInformation={toggleInformation}
          showInformation={showInformation}
          information={information}
          showTaskInformation={!!task.description}
        />
        <div className={`${styles.taskContentContainer} ${backgroundColor && styles.blockInput}`}>
          <div className={`${styles.border} ${startAnimation && styles[startAnimation]} ${startSlide && styles.animateLeft}`} onAnimationEnd={this.onAnimationEnd}>
            {tasks.map(task =>
              <div key={task.id} className={styles.borderContent}>
                <TaskTemplate
                  ref={taskTemplate => { this.$taskTempalte = taskTemplate; }}
                  className={styles.template}
                  question={React.createElement(
                    Templates[TEMPLATE_TYPE_MAP[task.question.type]],
                    {
                      ...task.question
                    })}
                  answers={React.createElement(
                    Templates[TEMPLATE_TYPE_MAP[task.answerGroup.type]],
                    {
                      inputId: this.context.getInputId,
                      multipleChoice: task.answerGroup.config ? JSON.parse(task.answerGroup.config).validation === VALIDATION.MULTIPLE : null,
                      ...task.answerGroup
                    })}
                />
              </div>
            )}
          </div>
          <div className={styles.bottomBar}>
            <div className={styles.bottomBarContent}>
              {this.renderCircles()}
              <Button icon="arrow-right" disabled={!canContinue} onClick={verify} />
            </div>
          </div>
        </div>
      </div >
    );
  }

  onAnimationEnd = () => {
    const { startSlide } = this.state;
    if (startSlide) {
      this.state.tasks.shift();
      this.setState({ startSlide: false });
    }
  }

  setupScreen = () => {
    const {
      imageId, datapoolName, description, showDatapools, information, showInformation,
      toggleInformation, togglePopup, finishSetup
    } = this.props;

    return (
      <div className={styles.sequenceContainer}>
        <Header
          showDatapoolsButton={true} // container.datapools.length > 1
          datapoolName={datapoolName}
          showDatapools={showDatapools}
          information={information}
          showInformation={showInformation}
          toggleInformation={toggleInformation}
          onClose={togglePopup}
        />
        <div className={styles.descriptionContainer}>
          <img src={generateUrl(imageId)} alt="datapool name" />
          <TextWithAudio className={styles.description} text={description} />
        </div>
        <div className={styles.setupBottom}>
          <Button icon="arrow-right" onClick={finishSetup} />
        </div>
      </div>
    );
  }

  renderCircles = () => {
    const { rounds, taskProgress } = this.props;
    const states = [];
    for (let i = 0; i < rounds; i++) {
      if (i <= taskProgress.length - 1) {
        states.push(
          <div key={`full_${i}`} className={`${styles.progressCircle} ${this.getColor(taskProgress[i])} `} />
        );
      } else {
        states.push(
          <div key={`empty_${i}`} className={styles.progressCircle} />
        );
      }
    }
    return <div className={styles.progressContainer}>{states}</div>;
  }

  getColor = status => {
    if (status === 'first') {
      return styles.green;
    } else if (status === 'second') {
      return styles.yellow;
    } else if (status === 'answered') {
      return styles.grey;
    } else if (status === 'wrong') {
      return styles.red;
    }
  };
}

TaskSequence.propTypes = {
  onClose: PropTypes.func,
  startAnimation: PropTypes.string,
  canContinue: PropTypes.bool
};

TaskSequence.defaultProps = {
  onClose: () => { },
  startAnimation: '',
  canContinue: false
};

TaskSequence.contextTypes = {
  getClozeAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string
    })
  ),
  getInputId: PropTypes.string
};
