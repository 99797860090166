import appVersion from '../helpers/appVersion';

export default ({ text, onClose, ...props }) => {
  if (appVersion.isDesktop) {
    const { shell } = window.require('electron');
    shell.openExternal(text);
  } else {
    window.open(text);
  }
  onClose();
  return null;
};