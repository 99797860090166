import React from 'react';
import { AnswerFactOrFiction } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ answers, ...props }) => {
  return (
    <AnswerFactOrFiction
      {...props}
      text={answers[0].text}
      audioSource={generateUrl(answers[0].file_audio_id)}
    />
  );
};