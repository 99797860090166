const defaultState = {
  usedItems: []
};

const SET_USED_ITEMS = 'SET_USED_ITEMS';
const CLEAR_USED_ITEMS = 'CLEAR_USED_ITEMS';

const setUsedItems = (datapoolId, itemId) => {
  return { payload: { datapool: datapoolId, item: itemId }, type: SET_USED_ITEMS };
};

const clearUsedItems = (datapoolId = null) => {
  return { payload: { datapool: datapoolId }, type: CLEAR_USED_ITEMS };
};

export const reducer = (state = defaultState, { type, payload, meta }) => {
  switch (type) {
    case SET_USED_ITEMS:
      const index = state.usedItems.findIndex(usedDP => usedDP.datapool === payload.datapool);
      if (index > -1) {
        state.usedItems[index].items.push(payload.item);
      } else {
        state.usedItems.push({ datapool: payload.datapool, items: [payload.item] });
      }
      return {
        ...state,
        usedItems: [...state.usedItems]
      };
    case CLEAR_USED_ITEMS:
      if (payload.datapool) {
        const index = state.usedItems.findIndex(usedDP => usedDP.datapool === payload.datapool);
        if (index > -1) {
          state.usedItems.splice(index, 1);
        }
      } else {
        state.usedItems.length = 0;
      }
      return {
        ...state,
        usedItems: [...state.usedItems]
      };
    default:
      return state;
  }
};

export const reducerKey = 'container';
export const actions = {
  setUsedItems,
  clearUsedItems
};