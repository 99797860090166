import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Magazine from '../Magazine';
import { Tooltip } from '../../../../components';

import appVersion from '../../../../helpers/appVersion';

import styles from './styles.less';
import { DOWNLOADS_CURRENTLY_RUNNING_TOAST_ID } from '../../constants';

class MagazinesList extends Component {
  render() {
    const { license: { unlocked, overrideUnlock, overrideLogin }, year, magazines, offlineYearsList, offlineMagazinesList, queuedMagazines, magazinesLastLoaded } = this.props;
    if (!overrideUnlock) {
      if(!year.active) {
        return null;
      } else {
        if (overrideLogin) {
          const atLeastOnePublishedMagazine = magazines && (
            magazines.filter(m => m.year.id === year.id).some(m => m.published)
          );
          if (!atLeastOnePublishedMagazine) {
            return null;
          }
        } else {
          const atLeastOneUnlockedMagazine = magazines && unlocked && (
            unlocked.some( // rev. 1
              item => item.magazine && magazines.some(magazine => item.magazine.id === magazine.id)
            )
            ||
            unlocked.some( // rev. 2
              item => item.svp_product_ids && item.magazine && magazines.some(
                magazine => magazine.id === item.magazine.id
              )
            )
            ||
            unlocked.some( // rev. 2
              item => item.magazines && item.magazines.some(itemMagazine =>
                magazines.some(
                  magazine => itemMagazine.id === magazine.id
                )
              )
            )
          );

          if (!atLeastOneUnlockedMagazine) {
            return null;
          }
        }
      }
    }

    return (
      <div className={styles.year}>
        <div className={styles.header}>
          <div className={styles.title}>{year.name}{!year.active ? ' (Unveröffentlicht)' : ''}</div>
          {(appVersion.isDesktop || appVersion.isMobile || appVersion.isIosWebApp || appVersion.isIpad) &&
            <div>
              <input className={styles.downloadInput} type="checkbox" id={year.id} checked={offlineYearsList.findIndex(id => id === year.id) > -1} onChange={this.toggleDownload} />
              <label className={`${styles.downloadBtn} has-tooltip`} htmlFor={year.id} >
                <Tooltip
                  string="Jahrgang herunterladen"
                  horizontal="right"
                  vertical="bottom"
                  alignmentHorizontal="alignmentRight"
                  alignmentVertical="alignmentBottom"
                />
              </label>
            </div>
          }
        </div>
        <div className={styles.magazines}>
          {magazines.sort((m1, m2) => m1.sort - m2.sort).map(magazine => {
            const offline = offlineMagazinesList.find(mag => mag.id === magazine.id);

            return <Magazine
              key={magazine.id}
              magazine={magazine}
              offline={!!offline}
              magazinesLastLoaded={magazinesLastLoaded}
              queuedMagazines={queuedMagazines}
              downloadStatus={offline ? { downloading: offline.downloading, progress: offline.progress } : { downloading: false }}
              setCurrentMagazineId={this.props.setCurrentMagazineId}
              downloadMagazines={this.props.downloadMagazines}
              queueMagazinesForDownload={this.props.queueMagazinesForDownload}
              deleteMagazine={this.props.deleteMagazine}
              setPage={this.props.setPage}
              setOverlay={this.props.setOverlay}
            />;
          })}
        </div>
      </div >
    );
  }

  toggleDownload = e => {
    const { year, queueMagazinesForDownload, cancelDownloads, setMagazines, magazines, license: { unlocked, overrideLogin } } = this.props;

    const unlockedIds = [];

    if (overrideLogin) {
      magazines.filter(m => m.year.id === year.id && m.published).forEach(m => unlockedIds.push(m.id));
    } else {
      unlocked.forEach( // rev. 2
        item => item.magazines && item.magazines.forEach(itemMagazine => {
          magazines.forEach(
            magazine => {
              if (itemMagazine.id === magazine.id && magazine.published) {
                unlockedIds.push(magazine.id);
              }
            }
          );
        })
      );
    }

    if (unlockedIds.length) {
      if (!e.target.checked) {
        cancelDownloads(unlockedIds);
      } else {
        setMagazines(e.target.id);
        toast(
          'Es laufen zur Zeit Downloads. Das Beenden der App beendet auch den Downloadprozess und löscht den Fortschritt',
          { toastId: DOWNLOADS_CURRENTLY_RUNNING_TOAST_ID }
        );
        queueMagazinesForDownload(unlockedIds, 'list');
      }
    }
  }
}

const mapStateToProps = ({ license }) => ({
  license,
});

MagazinesList.propTypes = {
  magazines: PropTypes.array.isRequired,
  year: PropTypes.object.isRequired,
  setCurrentMagazineId: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setOverlay: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(MagazinesList);

