// This is a list of changes to make to the state being rehydrated.
// The keys must be integers, and migrations will be performed in ascending key order.
// Note: blacklisted reducers will not be present in this state.
import localforage from 'localforage';
import { OVERLAY_TYPES } from '../../../components/constants';
import appVersion from '../../../helpers/appVersion';

export default {
  0: state => {
    return { ...state };
  },
  1: state => {
    return { ...state };
  },
  2: state => {
    // this app.version check is needed, to only run the cleaning of assets on old clients, if they never did it in the past (version < 2)
    // and not on every client, because when changing redux persist v4 to v5 it runs all migratios again, which would delete
    // the assets for everyone again.
    if (state.app && state.app.version && state.app.version < 2 &&
      (navigator.appVersion.indexOf('Mac') !== -1 || appVersion.isIosWebApp)) {
      let overlay = OVERLAY_TYPES.MAGAZINES;

      if (!state.magazines && (appVersion.isWeb || appVersion.isIpad)) {   // if never set
        overlay = OVERLAY_TYPES.AUTH;
      } else if (state.magazines && state.magazines.overlay === 'AUTH') {  // if on auth page, stay there
        overlay = OVERLAY_TYPES.AUTH;
      }

      return {
        ...state,
        assets: {
          blobLoadingProgress: 0,
          blobFilesToLoad: 0,
          blobLoadedFiles: 0,
          files: {}
        },
        magazines: { ...state.magazines, currentMagazineId: null, pageId: null, offlineMagazinesList: [], offlineYearsList: [], overlay }
      };
    } else {
      return { ...state };
    }
  },
  3: state => {
    // download and migration crashes due to ram issues on android and iOS, which force us to remove all previous files
    if (document.IS_CORDOVA) {
      let overlay = OVERLAY_TYPES.MAGAZINES;
      if (!state.magazines) {
        overlay = OVERLAY_TYPES.AUTH;
      } else if (state.magazines && state.magazines.overlay === 'AUTH') {
        overlay = OVERLAY_TYPES.AUTH;
      }

      // would prefer to do this things with await, but since we cant use async in a migration, it has to be done this way
      async function cleanLocalforage() {
        // delete all linked asset files in the localforage
        const temporaryFiles = { ...state.assets.files };
        Object.keys(temporaryFiles).forEach(key => {
          localforage.removeItem(key);
        });

        // delete the remaining chunked blobs
        (await localforage.keys()).forEach(key => {
          if (/\.part\d+$/.test(key)) {
            localforage.removeItem(key);
          }
        });
      }
      cleanLocalforage();

      return {
        ...state,
        assets: {
          blobLoadingProgress: 0,
          blobFilesToLoad: 0,
          blobLoadedFiles: 0,
          files: {}
        },
        magazines: {
          ...state.magazines,
          currentMagazineId: null,
          pageId: null,
          offlineMagazinesList: [],
          offlineYearsList: [],
          overlay,
          queuedMagazines: [],
          closedWithOngoingDownloads: false
        }
      };
    }

    return {
      ...state,
      magazines: { ...state.magazines, queuedMagazines: [], closedWithOngoingDownloads: false }
    };
  },
  4: state => {
    // on ios we saved the whole path in the redux store, inclusive the application id.
    // This id should not change on updating the app. But it did (maybe because of certificates?)
    // so to make the saved things independent of the store, we now save only the file name in the store
    // and so we have to remove the file path from all old entries in the redux store.
    // we apply that for android too, to have the same behaviour
    if (appVersion.isIpad || appVersion.isMobile) {
      const oldFilePaths = Object.entries(state.assets.files);

      const files = {};
      oldFilePaths.forEach(([key, entry]) => {
        let newFilePath = entry.replace(/file:\/\/\/var\/mobile\/Containers\/Data\/Application\/.*\/Library\/NoCloud\//, '');
        if (appVersion.isIpad) {
          newFilePath = entry.replace(/file:\/\/\/var\/mobile\/Containers\/Data\/Application\/.*\/Library\/NoCloud\//, '');
        } else {
          newFilePath = entry.replace(/file:\/\/\/data\/user\/.*\/ch.schulverlagplus.millefeuilles\/files\//, '');
        }
        
        files[key] = newFilePath;
      });

      return {
        ...state,
        assets: {
          files
        }
      };
    }

    return { ...state };
  },
  5: state => {
    // desktop app should not have an authentication anymore, so we need to put everyone on the magazines screen
    if (appVersion.isDesktop) {
      let overlay = OVERLAY_TYPES.MAGAZINES;
      if (state.magazines && state.magazines.overlay !== 'AUTH') {
        overlay = state.magazines.overlay;
      }

      return {
        ...state, 
        magazines: {
          ...state.magazines,
          overlay
        }
      };
    }

    return { ...state };
  },
  6: state => {
    // client login means we only activated licenses, which should still count as beeing logged in
    if (!appVersion.isMobile || (appVersion.isMobile && state.auth && state.auth.user && !state.auth.user.clientLogin)) {

      const magazines = {
        ...state.magazines,
      };

      if(!appVersion.isDesktop) {
        magazines.overlay =  OVERLAY_TYPES.AUTH;
        magazines.currentMagazineId = null;
        magazines.pageId = null;
      }

      return {
        ...state,
        auth: { }, // we dont need the default state here, since all values there are not used anymore
        license: {
          ...state.license,
          unlocked: []
        },
        magazines: { ...magazines }
      };
    }

    return { ...state };
  }
};