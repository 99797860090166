export const OVERLAY_TYPES = {
  AUTH: 'AUTH',
  MAGAZINES: 'MAGAZINES',
  IMPRESSUM: 'IMPRESSUM',
  PAGES: 'PAGES',
  HELP: 'HELP',
  CONTACT: 'CONTACT',
  DATA_PROTECTION: 'DATA_PROTECTION',
  NONE: 'NONE'
};

export const FILE_KEYS = [
  'file_id',
  'file_any_id',
  'file_audio_id',
  'file_music_id',
  'file_image_id',
  'file_video_id',
  'file_source_directory_id',
  'file_audio_archive_id'
];

export const TEMPLATE_TYPE_MAP = {
  CONTENT_TOOLTIP_TEXT: 'Text',
  CONTENT_TOOLTIP_IMAGE: 'Image',
  CONTENT_TOOLTIP_TEXT_IMAGE: 'Image',
  CONTENT_TEXT_SHORT: 'ImageTextShort',
  CONTENT_TEXT_LONG: 'ImageTextLong',
  CONTENT_AUDIO: 'Audio',
  CONTENT_MUSIC: 'Music',
  CONTENT_VIDEO: 'Video',
  CONTENT_SLIDESHOW: 'Slideshow',
  CONTENT_INDEX_SINGLE: 'IndexSingle',
  CONTENT_INDEX_DUAL: 'IndexDual',
  CONTENT_DOWNLOAD: 'Download',
  CONTENT_LINK_INTERNAL: 'LinkInternal',
  CONTENT_LINK_EXTERNAL: 'LinkExternal',
  CONTENT_LINK_FICHIER: 'LinkExternal',

  CONTENT_GO_TO_SCENE: 'GoToScene',

  CONTAINER_SEQUENCE: 'ContainerSequence',
  CONTAINER_PAIR: 'ContainerPair',

  QUESTION_TEXT_SHORT: 'QuestionShort',
  QUESTION_TEXT_LONG: 'QuestionLong',
  QUESTION_IMAGE: 'QuestionImage',
  QUESTION_TEXT_IMAGE: 'QuestionImageText',
  QUESTION_AUDIO: 'QuestionAudio',
  QUESTION_VIDEO: 'QuestionVideo',
  QUESTION_CLOZE: 'QuestionCloze',

  ANSWER_TEXT: 'AnswerText',
  ANSWER_CLOZE: 'AnswerCloze',
  ANSWER_IMAGE: 'AnswerImage',
  ANSWER_TEXT_IMAGE: 'AnswerTextImage',
  ANSWER_AUDIO_IMAGE: 'AnswerAudio',
  ANSWER_TEXT_INPUT: 'AnswerTextInput',
  ANSWER_TEXT_SELECTION: 'AnswerTextSelection',
  ANSWER_FACT_FICTION: 'AnswerFactOrFiction',
  ANSWER_SPEAK: 'AnswerSpeak',
  ANSWER_DUAL_ORDER: 'AnswerDualOrder'
};

export const SMALL_TEMPLATE_TYPES = [
  'CONTENT_TOOLTIP_TEXT',
  'CONTENT_TOOLTIP_IMAGE',
  'CONTENT_TOOLTIP_TEXT_IMAGE',
  'CONTENT_AUDIO',
  'CONTENT_MUSIC'
];

export const LARGE_TEMPLATE_TYPES = [
  'CONTENT_TEXT_SHORT',
  'CONTENT_TEXT_LONG',
  'CONTENT_INDEX_SINGLE',
  'CONTENT_INDEX_DUAL',
];

export const FULLSCREEN_TEMPLATE_TYPES = [
  'CONTENT_VIDEO',
  'CONTENT_SLIDESHOW',
];

// also check relpaceFileIdsWithUrls for new things to take effect
export const IMAGE_THUMBNAIL = {
  PAGE: {
    WIDTH: window.devicePixelRatio > 1 ? 2048 : 1024,
    QUALITY: 80
  },
  SCENE: {
    HEIGHT: window.devicePixelRatio > 1 ? 1536 : 768,
    QUALITY: 80
  },
  PAGES_OVERLAY: {
    WIDTH: window.devicePixelRatio > 1 ? 240 : 120,
  },
  MAGAZINES_OVERLAY: {
    WIDTH: window.devicePixelRatio > 1 ? 332 : 166,
  },
  CONTENT: {
    WIDTH: window.devicePixelRatio > 1 ? 1024 : 512,
  },
  DATAPOOL_IMAGE: {
    WIDTH: window.devicePixelRatio > 1 ? 512 : 256,
    HEIGHT: window.devicePixelRatio > 1 ? 362 : 181,
  },
};

/**
/* CONTAINER START
*/

export const CONTAINER_TYPE_MAP = {
  CONTAINER_SEQUENCE: 'SequenceContainer',
  CONTAINER_COMBINATION: 'CombinationContainer',
  CONTAINER_PAIR: 'PairContainer',
  CONTAINER_SCENE: 'SceneContainer',
};

export const CONTAINER_TYPES = {
  CONTAINER_SEQUENCE: 'CONTAINER_SEQUENCE',
  CONTAINER_COMBINATION: 'CONTAINER_COMBINATION',
  CONTAINER_PAIR: 'CONTAINER_PAIR',
  CONTAINER_SCENE: 'CONTAINER_SCENE',
};

export const CONTAINER_SCREENS = {
  DATAPOOLS: 'DATAPOOLS',
  RESULT: 'RESULT',
  CONTAINER_SEQUENCE: 'CONTAINER_SEQUENCE',
  CONTAINER_PAIR: 'CONTAINER_PAIR',
  CONTAINER_COMBINATION: 'CONTAINER_COMBINATION'
};

export const TASK_TYPE_MAP = {
  ANSWER_TEXT: 'Array',
  ANSWER_IMAGE: 'Array',
  ANSWER_TEXT_IMAGE: 'Array',
  ANSWER_AUDIO_IMAGE: 'Array',
  ANSWER_TEXT_INPUT: 'String',
  ANSWER_TEXT_SELECTION: 'Array',
  ANSWER_FACT_FICTION: 'ANSWER_FACT_FICTION',
  ANSWER_SPEAK: 'Speak',
  ANSWER_DUAL_ORDER: 'DualOrder',
  ANSWER_CLOZE: 'Cloze',
};

export const VALIDATION = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE'
};

export const ANIMATION_TYPE = {
  ROTATION: 'rotation',
  SLIDE: 'slide'
};

export const ITEMS_TYPE = {
  TASKS: 'TASKS',
  SENTENCES: 'SENTENCES'
};

export const NEXT_TASK_TYPE = {
  INDEX: 'INDEX',
  TASK: 'TASK'
};

export const HEADER_INFO_TYPE = {
  TASK: 'TASK',
  DATAPOOL: 'DATAPOOL'
};

export const CONTAINER_WAITING_TIME = 1000; // in ms
export const CONTAINER_FLIP_TIME = 250; // in ms

export const RESULT_SPAWN_ICONS = 15;
export const SPECIAL_CHARACTERS = '[.,/#!?$%^&*;:{}=-_`~()]';

/**
/* CONTAINER END
*/