import { store } from '../redux/';
import { actions } from './redux';

const refreshInterval = 1000 * 60;

export default function preloadMagazines(dispatch, force = false) {
  const { _loaded, offlineMagazinesList } = store.getState().magazines;

  if (force || Date.now() - _loaded >= refreshInterval) {
    dispatch(actions.downloadAllMagazines()); // download magazines overlay data
    if (offlineMagazinesList.length) {
      dispatch(actions.downloadMagazines(offlineMagazinesList.map(mag => mag.id), false));  // download offline magazines
    }
  }
}

