import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../domains/redux';

import { actions as magazinesActions } from '../../domains/magazines/redux';
import { actions as magazineBlobsActions } from '../../domains/magazineBlobs/redux';
import performanceChecks from '../../helpers/performanceChecks';
import appVersion from '../../helpers/appVersion';

import { OVERLAY_TYPES, IMAGE_THUMBNAIL } from '../constants';

import styles from './styles.less';
import { generateUrl, getFileIds } from '../../domains/uploads';

import { Button, SimpleButton } from '../../components';
import LoadingSpinner from '../LoadingSpinner';
import updateBlobUrls from '../../domains/config/functions/updateBlobUrls';

class PagesOverlay extends Component {
  constructor(props) {
    super(props);

    // blob loading is only needed for environments which use blobs
    this.state = {
      blobLoaded: !(appVersion.isWeb || appVersion.isIosWebApp)
    };
  }

  componentWillMount() {
    const { currentPageId, currentMagazine, fetchMagazine, setPage, hasUpdatedBlobs } = this.props;

    !currentPageId && currentMagazine && currentMagazine.sections && currentMagazine.sections.length && !fetchMagazine && setPage(currentMagazine.sections[0].pages[0].id);
    document.body.className = styles.bodyBackground;

    if (appVersion.isWeb || appVersion.isIosWebApp) {
      if (!hasUpdatedBlobs) {
        this.updateBlobUrls();
      } else {
        this.setState({
          blobLoaded: true
        });
      }
    }
  }

  updateBlobUrls = async () => {
    const { currentMagazine, setMagazinesWithUpdatedBlobs } = this.props;
    if (performanceChecks.enabled) {
      console.info('LOADING BLOBS PAGE OVERVIEW START');
      performanceChecks.blobsPageOverviewStart = new Date();
    }

    // update blob urls for magazine content (only already downloaded files will get updated!)
    const filter = getFileIds(currentMagazine, { testKey: /file_/ });
    await updateBlobUrls(filter);
    setTimeout(() => {
      this.setState({ blobLoaded: true });
      store.dispatch({
        type: 'SET_BLOB_LOADING_PROGRESS',
        payload: {
          blobLoadingProgress: 0,
          blobFilesToLoad: 0,
          blobLoadedFiles: 0
        }
      });
      setMagazinesWithUpdatedBlobs(currentMagazine.id);

      if (performanceChecks.enabled) {
        performanceChecks.blobsPageOverviewEnd = new Date();
        console.info('LOADING BLOBS PAGE OVERVIEW END + 1second');
        console.info(performanceChecks.blobsPageOverviewDuration());
      }

    }, 1000);
  }

  componentWillUnmount() {
    document.body.className = '';
  }

  componentDidUpdate() {
    const { currentPageId, currentMagazine, fetchMagazine, setPage } = this.props;

    !currentPageId && currentMagazine && currentMagazine.sections && currentMagazine.sections.length && !fetchMagazine && setPage(currentMagazine.sections[0].pages[0].id);
  }

  render() {
    const {
      blobLoadingProgress,
      blobFilesToLoad,
      blobLoadedFiles,
      currentMagazine
    } = this.props;
    const { blobLoaded } = this.state;

    if (!blobLoaded) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingProgress}>
            <LoadingSpinner width={100} progress={Math.floor(blobLoadingProgress)} caption={`${blobLoadedFiles}/${blobFilesToLoad} Magazine Assets`} />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.pagesOverlay}>
        <div className={styles.container}>
          <div className={styles.headline}>
            <div className={styles.title}>
              {currentMagazine.name}
            </div>
            <div className={styles.subtitle}>
              {currentMagazine.subtitle}
            </div>
          </div>
          {this.props.currentMagazine && this.props.currentMagazine.sections && !this.props.fetchMagazine ?
            this.props.currentMagazine.sections.map(section => {
              return (
                <div key={section.id}>
                  <div className={styles.header}>
                    <div className={styles.name}>{section.name}</div>
                    {section.fichier_link ?
                      <div
                        className={styles.fichierLink}
                        onClick={() => {
                          if (appVersion.isDesktop) {
                            const { shell } = window.require('electron');
                            shell.openExternal(section.fichier_link);
                          } else {
                            window.open(section.fichier_link);
                          }
                        }}>
                        <SimpleButton icon="fichier" />
                        <span className={styles.fichierLinkText}>vocabulaire parcours {section.sort + 1}</span>
                      </div> : null}
                  </div>
                  <div className={styles.list}>
                    {section.pages.map((page, index) => (
                      <div className={styles.item}
                        key={page.id}
                        id={page.id}
                        data-current={page.id === this.props.currentPageId}
                        onClick={() => {
                          this.props.setPage(page.id);
                          this.props.setOverlay(OVERLAY_TYPES.NONE);
                        }}
                      >
                        <span className={`${styles.number} ${styles.number_left}`}>{page.page_number_left}</span>
                        <span className={`${styles.number} ${styles.number_right}`}>{page.page_number_right}</span>

                        {page.hotspots.length > 0 &&
                          <Button icon="interaction" color="blue-light" classes="hotspot-button" />
                        }
                        <img src={generateUrl(page.file_image_id, { width: IMAGE_THUMBNAIL.PAGES_OVERLAY.WIDTH })} width="1024" height="720" alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              );
            }) : <LoadingSpinner />
          }
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ magazines, magazineBlobs, assets }) => {
  const currentMagazine = magazines.magazines.find(mag => mag.id === magazines.currentMagazineId);
  return {
    currentPageId: magazines.pageId,
    currentMagazine: currentMagazine,
    fetchMagazine: magazines.fetchMagazine,
    hasUpdatedBlobs: magazineBlobs.list.findIndex(m => m === currentMagazine.id) > -1,
    blobLoadingProgress: assets.blobLoadingProgress,
    blobFilesToLoad: assets.blobFilesToLoad,
    blobLoadedFiles: assets.blobLoadedFiles
  };
};

const mapDispatchToProps = {
  setPage: magazinesActions.setPageId,
  setOverlay: magazinesActions.setOverlay,
  setMagazinesWithUpdatedBlobs: magazineBlobsActions.setMagazinesWithUpdatedBlobs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesOverlay);