import uniq from 'lodash/uniq';

export default function getFileIds(any, opts = {}, level = 1) {
  const {
    depth,
    testKey
  } = {
    depth: Infinity,
    testKey: /^file_/,
    ...opts
  };

  if (!any || typeof any !== 'object') {
    return [];
  }

  if (Array.isArray(any)) {
    return level <= depth ?
      any.reduce(
        (acc, a) => [...acc, ...getFileIds(a, {
          depth,
          testKey
        }, level + 1)],
        []
      ) :
      [];
  }

  return uniq(
    Object.keys(any).reduce((acc, key) => {
      const type = typeof any[key];

      if (type === 'string') {
        if (testKey.test(key)) {
          acc.push(any[key]);
        }
      } else if (level < depth) {
        const res = getFileIds(any[key], {
          depth,
          testKey
        }, level + 1);

        if (res.length) {
          acc.push(...res);
        }
      }

      return acc;
    }, [])
  );
}