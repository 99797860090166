import React, { Component } from 'react';

import styles from './styles.less';


class DataProtectionOverlay extends Component {

  render() {

    return (
      <div className={styles.overlay}>
        <div className={styles.container}>
          <h1>AGB und Datenschutz</h1>
          <h2>AGB</h2>
          <h3>1. AGB</h3>
          <p><strong>1.1 Geltungsbereich</strong><br />
          Die Allgemeinen Geschäftsbedingungen (AGB) gelten für sämtliche Leistungen der Schulverlag plus AG (nachfolgend Schulverlag genannt) gegenüber ihren Kundinnen und Kunden. Durch den Erwerb eines Produktes erklärt sich die Kundin oder der Kunde ausdrücklich mit diesen AGB einverstanden. Der Schulverlag behält sich vor, die AGB jederzeit zu ändern. Massgebend ist jeweils die zum Zeitpunkt der Bestellung geltende Version dieser AGB.</p>
          <p><strong>1.2 Zustandekommen des Vertrages</strong><br />
          Ein Vertrag zwischen dem Schulverlag und der Kundin oder dem Kunden gilt als abgeschlossen, wenn die Kundin oder der Kunde nach der Bestellung die Bestellbestätigung erhält, oder mit Aufgabe der Ware zum Versand.</p>
          <p><strong>1.3 Kaufmöglichkeiten</strong><br />
          Die Produkte des Schulverlags können per e-Shop, Telefon oder Mail bestellt, oder in den Geschäften in Bern und Buchs sowohl bestellt als auch direkt gekauft werden. Der Schulverlag übernimmt keine Gewähr für den Produktebestand an beiden Geschäftsstandorten.<br /><br />
            <strong>1.4 Preisgestaltung</strong><br />
          Alle Preise verstehen sich inkl. MwSt. und sind in Schweizer Franken zu begleichen.<br /><br />
            <strong>1.5 Lieferung und Versandkosten Inland</strong><br />
          Der Versand (inkl. Lieferschein/Rechnung) erfolgt mittels B-Post.<br />Für Inlandlieferungen bis zum Betrag von CHF 200.– wird ein Mindestversandkosten-Anteil von CHF 7.50 erhoben. Bei einem höheren Warenwert erfolgt die Lieferung portofrei bzw. franko Domizil.<br />Expresslieferungen werden auf speziellen Wunsch der Kunden ausgeführt. Nebst den höheren Posttaxen wird eine zusätzliche Bearbeitungsgebühr von CHF 8.– verrechnet.<br />Artikel müssen sofort nach Erhalt auf allfällige Schäden kontrolliert werden. Schäden müssen der Post bzw. der Transportfirma gemeldet werden. Andernfalls ist ein Realersatz nicht versichert.<br /><br />
            <strong>1.6 Lieferung und Versandkosten Ausland</strong><br />
            <p>Der Versand von Produkten der Schulverlag Plus AG ist nur in gewisse Länder möglich. Für Belgien, Deutschland, Frankreich, Italien, Luxemburg, Niederlande, Österreich, San Marino gelten folgende Tarife. Für alle weiteren Länder erfolgt die Berechnung vom Tarif je Land / Umfang / Gewicht. Wir erstellen Ihnen gerne eine Offerte mit den aktuellen Versandspesen je Versandart Economy / Priority. Nach Erhalt Ihrer Zahlung via Kreditkarte oder Vorausrechnung werden wir den Versand umgehen veranlassen.</p>
            <p>101 bis 500 g &nbsp; &nbsp; &nbsp; 12.00 CHF<br />501 bis 1000 g &nbsp;&nbsp;&nbsp; 16.00 CHF<br />bis 2 kg &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; 20.00 CHF<br />bis 5 kg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 26.00 CHF<br />bis 10 kg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 30.00 CHF<br />bis 20 kg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 36.00 CHF<br />bis 30 kg&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; 40.00 CHF<br />über 30 kg &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Paket muss 2x verrechnet werden<br /><br /> Auslandlieferungen werden nur gegen Kreditkartenzahlung oder Vorausrechnung ausgeführt.<br /><br />
              <strong>1.7 Einschränkungen in der Lieferung</strong><br />
          Der Schulverlag behält sich vor, gewisse Artikel nur an bestimmte Kundengruppen zu liefern.</p>
            <p><strong>1.8 Zahlungsbestimmungen</strong><br />
          Institutionen können Bestellungen per Kreditkarte / Debitkarte oder Rechnung bezahlen. Die Rechnung wird zusammen mit dem bestellten Material geliefert und ist innerhalb von 30 Tagen zu bezahlen. Dafür ist der mitgelieferte Einzahlungsschein zu verwenden. Privatpersonen können Bestellungen im e-Shop per Kreditkarte / Debitkarte bezahlen, Direktkäufe in den Geschäften in bar oder Maestro-/Postkarte.<br /><br />
              <strong>1.9 Vorgehen bei Zahlungsverzug oder Nichtbezahlung</strong><br />
          Bei nichtbezahlten Rechnungen innerhalb der Zahlungsfrist kann der Schulverlag seine Kundinnen und Kunden bis zu drei Mal mahnen. Bleibt die Bezahlung weiterhin aus, behält sich der Schulverlag eine Betreibung vor. Betrifft die Betreibung eine Nutzungslizenz, kann der Schulverlag diese sperren.<br /><br />
              <strong>1.10 Beanstandung</strong><br />
          Reklamationen werden innert 14 Tagen unter Angabe der Rechnungs- und Kundennummer entgegengenommen.<br /><br />
              <strong>1.11 Rücksendung</strong><br />
          Grundsätzlich besteht kein Rückgaberecht. Angelieferte Sendungen müssen sofort nach Erhalt auf Vollständigkeit geprüft werden. Sofern die Ware noch in verkaufsneuem Zustand ist, können irrtümlich bestellte Artikel nach vorheriger Rückfrage beim Schulverlag innerhalb von maximal 10 Tagen nach Lieferung zurückgegeben werden. Falsch erhaltene Ware kann von den Kundinnen und Kunden kostenlos retourniert werden. Bei unangemeldeten Kundenrücksendungen, bei denen kein Fehler des Schulverlags vorliegt, werden CHF 30.00 Bearbeitungsgebühr verrechnet. Bei Artikeln, die der Schulverlag nicht selber an Lager hat und ausschliesslich für die Kundin oder den Kunden besorgt hat, ist jegliche Rücksendung ausgeschlossen.</p>
            <h3><br />2. Lizenzbestimmungen</h3>
            <p><strong>2.1 Geltungsbereich</strong><br />
          Die Lizenzbestimmungen gelten für die Nutzung von Online-Lehrmitteln, Lernapplikationen und weiterer Software des Schulverlags (nachfolgend digitale Produkte genannt). Mit dem Erwerb einer Lizenz erhält die Kundin oder der Kunde das Nutzungsrecht zum digitalen Produkt (nachfolgend Nutzungsrecht genannt) und erklärt sich ausdrücklich mit diesen Lizenzbestimmungen einverstanden.<br /><br />
              <strong>2.2 Nutzungsrecht</strong><br />
          Das Nutzungsrecht gilt nur für die eigene Nutzung (Einzellizenz) oder für die lizenzierte Anzahl registrierter Nutzer (Mehrfachlizenz). Vertragspartnerin oder Vertragspartner des Schulverlags ist die Kundin oder der Kunde. Dieser ist verantwortlich für die Einhaltung der Lizenzbedingungen durch die Nutzer. Die Lizenznehmerin oder der Lizenznehmer darf die für sich lizenzierten Inhalte, Teile davon sowie die Lizenznummer nicht weitergeben oder in irgendeiner anderen Form kommerziell verwerten.<br /><br />
              <strong>2.3 Gültigkeitsdauer</strong><br />
          Die Gültigkeitsdauer einer Lizenz ist dem jeweiligen Produktebeschrieb zu entnehmen. Kundinnen und Kunden werden 30 Tage vor Ablauf per E-Mail informiert.<br /><br />
              <strong>2.4 Systemanforderung &amp; Sicherheit</strong><br />
          Die Kundinnen und Kunden sind selber dafür verantwortlich, dass ihre Geräte die System- und Sicherheitsvoraussetzungen erfüllen, die für die Benutzung der digitalen Produkte des Schulverlags nötig sind. Dasselbe gilt für die Sicherheit Ihrer Zugangsdaten wie zum Beispiel Passwörter. Sind diese Voraussetzungen nicht erfüllt, gewährleistet der Schulverlag die Nutzung nicht.<br /><br />
              <strong>2.5 Verletzung der Lizenzbestimmungen</strong><br />
          Der Schulverlag behält sich vor, Lizenzen entschädigungslos zu entziehen, wenn eine Nutzerin oder ein Nutzer gegen diese AGB verstösst oder andere Rechte des Schulverlags verletzt. Rechtliche Schritte bleiben vorbehalten.</p>
            <h3>3. Gemeinsame Bestimmungen für AGB und Lizenzbedingungen</h3>
            <p><strong>3.1 Haftung</strong><br />
          Die Kundin oder der Kunde nimmt zur Kenntnis, dass die Benutzung sämtlicher Produkte ausschliesslich auf eigenes Risiko erfolgt. Der Schulverlag schliesst jede Haftung für allfällige Schäden und Folgeschäden irgendwelcher Art durch diesen Gebrauch ausdrücklich aus.<br /><br />
              <strong>3.2 Urheberrecht</strong><br />
          Die Produkte des des Schulverlags sind durch das Urheberrecht und sonstige Immaterialgüterrechte geschützt. Eine kommerzielle Verbreitung ist nicht erlaubt. Gemäss den gesetzlichen Bestimmungen dürfen die Produkte zum Eigengebrauch verwendet werden.<br /><br />
              <strong>3.3 Datenschutz</strong><br />
          Mit der Nutzung der digitalen Produkte bzw. durch die Aufgabe von Bestellungen stimmt die Lizenznehmerin oder der Lizenznehmer bzw. die Kundin oder der Kunde der Verarbeitung der entsprechenden Personendaten zu eigenen Dokumentationszwecken zu. Die registrierten Daten werden nicht an Dritte weitergegeben. Der Umgang mit personenbezogenen Daten ist in unserer <a href="https://www.schulverlag.ch/assets/AGB-und-Datenschutz/agb_kurz.pdf">Datenschutzerklärung</a> ersichtlich.<br /><br />
              <strong>3.4 Anwendbares Recht und Gerichtsstand</strong><br />
          Anwendbar ist Schweizer Recht. Gerichtsstand ist Bern.</p>
            <p>20. Mai 2020</p>
            <h2><br />Datenschutzrichtlinie</h2>
            <p>Die Schulverlag plus AG ist sich bewusst, dass der Schutz von Personendaten von zentraler Bedeutung ist. Wir haben deshalb technische und organisatorische Massnahmen getroffen, um den Vorschriften zum Datenschutz gerecht zu werden.</p>
            <h3><strong>1. Geltungsbereich</strong></h3>
            <p>Diese Datenschutzerklärung legt offen, in welchem Umfang und zu welchem Zweck die Webseite <a rel="noopener noreferrer" href="https://www.schulverlag.ch/de/" target="_blank">schulverlag.ch</a>, unsere Produktwebsites sowie die digitalen Lernplattformen personenbezogene Daten erheben und verwenden. Mit dem Zugriff auf eine dieser Plattformen erklären Sie Ihr Einverständnis mit der vorliegenden Datenschutzerklärung. Die Erklärung gilt sinngemäss auch für Informationen, die Kundinnen und Kunden z.B. in Form von abonnierten Newslettern eingeben.<br /> Sollten Sie mit dieser Erklärung nicht einverstanden sein, ist ein Zugriff auf diese Seiten zu unterlassen. Diese Erklärung kann jederzeit aktualisiert werden. Die Nutzenden werden in angemessener Weise davon informiert.</p>
            <h3><strong>2. Verantwortliche Stelle</strong></h3>
            <p>Schulverlag plus AG (Nachfolgend Schulverlag genannt)<br />Belpstrasse 48<br />3000 Bern<br /><br />
              <h3><strong>3. Zugriffsdaten &amp; Cookies</strong></h3>
              Beim Zugriff auf unsere Webportale und digitalen Lernplattformen werden diverse Zugriffsdaten gespeichert. Dazu verwenden wir Cookies. Sie können in Ihren Browsereinstellungen verhindern, dass Cookies gespeichert werden. Dies beeinflusst möglicherweise Ihre Möglichkeiten bei der Nutzung der Webseite. Zu statistischen Zwecken werden die Daten anonym ausgewertet. Sie dienen der Optimierungen unserer Online-Dienstleitungen. Es finden keine personenbezogenen Auswertungen statt.</p>
            <p>Je nach Applikation verwendet der Schulverlag unterschiedliche Webanalyse-Tools. Diese Tools erfassen eine Reihe von Metriken. Die wichtigsten sind Seitenzugriffe, die Verweildauer, Spracheinstellungen und teilweise die geographische Quelle des Zugriffs. Ausserdem werden Angaben zu Endgeräten, dem OS und der Browserversion erfasst. Diese Daten werden streng vertraulich behandelt und nicht ausserhalb des Schulverlags weitergegeben oder Dritten zugänglich gemacht, es sei denn, dass dies vom geltenden Recht und namentlich der zuständigen Strafverfolgungsbehörde gefordert wird.</p>
            <h3><strong>4. Personenbezogene Daten</strong></h3>
            <p>Soweit Sie uns personenbezogene Daten zur Verfügung gestellt haben, verwenden wir diese nur zur Beantwortung Ihrer Anfragen, zur Abwicklung mit Ihnen geschlossener Verträge und für die technische Administration. Diese Daten nehmen wir auf:</p>
            <p><strong>4.1 Kundenprofile Webshop</strong><br />
          Benutzername, Email, Kunden-Nr., Anrede, Adresse, Schule/Institution, Vor- und Nachname, Strasse, PLZ, Ort, Land, Telefon, Warenkorb und getätigte Bestellungen.<br /><br />
              <strong>4.2 Nutzerprofile bei Login-geschützten Anwendungen</strong><br />
          Username, zugeteilte Rolle, E-Mail. Bei der Rolle «Schülerinnen und Schüler» ist die Angabe von E-Mail sowie Vor- und Nachname optional. Die Entscheidung bleibt den Schulen überlassen.</p>
            <p><strong>4.3 Newsletter</strong><br />
          Email-Adresse, Vor- und Nachname, Anrede, Adressgruppe (Schulleitung, Materialverantwortliche, Lehrperson, Buchhandel, Privat).</p>
            <p><strong>4.4 Katalogbestellung</strong><br />Anrede, Vor- und Nachname, Strasse, PLZ, Ort, Email.</p>
            <h3><strong>5. Weitergabe personenbezogener Daten</strong></h3>
            <p>Ihre personenbezogenen Daten werden an Dritte nur weitergegeben oder sonst übermittelt, wenn dies zum Zwecke der Vertragsabwicklung, insbesondere Weitergabe von Bestelldaten an Lieferanten, erforderlich ist, dies zu Abrechnungszwecken erforderlich ist oder Sie zuvor eingewilligt haben.</p>
            <h3>6. Haftungsausschluss</h3>
            <p>Der Schulverlag schliesst jegliche Haftung (einschliesslich Fahrlässigkeit) aus, die sich aus dem Zugriff bzw. den verunmöglichten Zugriff auf das Portal oder einzelner seiner Elemente und aus der Benutzung ergeben könnten. Der Zugriff auf das Portal und dessen Nutzung werden nicht garantiert. Das Portal enthält Links zu Websites Dritter. Diese Seiten werden nicht durch die Schulverlag plus AG betrieben oder überwacht. Die Schulverlag plus AG lehnt jegliche Verantwortung für den Inhalt und die Einhaltung der gesetzlichen Datenschutzbestimmungen durch die Betreiber von verlinkten Seiten ab.</p>
            <h3>7. Datenübertragung / Aufbewahrung von Daten</h3>
            <p>Wenn Sie sich auf <a rel="noopener noreferrer" href="https://www.schulverlag.ch/de/" target="_blank">schulverlag.ch</a>&nbsp;oder auf einer unserer Lernapplikationen anmelden, werden die Datenpakete ihres Besuches verschlüsselt übertragen. Für unsere Kundinnen und Kunden verwenden wir das weit verbreitete und sichere SSL-Verfahren, um auch jede Bestellung im Webshop ausschliesslich verschlüsselt zu übermitteln.</p>
            <p>&nbsp;</p>
            <p>Soweit nicht anders angegeben, werden Personendaten bis zum Erreichen ihres Zwecks gespeichert. Der Schulverlag kann aus finanziellen und rechtlichen Gründen verpflichtet sein, Daten aufzubewahren. Dabei handelt es sich in erster Linie um Adress- und Bestelldaten. Die Aufbewahrungsdauer beträgt in der Regel zehn Jahre.</p>
            <h3>8. Datenerhebung durch Dritte: Webanalyse-Tools beim Schulverlag</h3>
            <p>Der Schulverlag arbeitet für den Betrieb des e-Shops und der digitalen Lernplattformen mit Anwendungen Dritter. Diese können für den Betrieb von digitalen Lehrmitteln Daten verarbeiten:<br /><br />
              <strong>8.1 Google Analytics</strong><br />
          Die Website schulverlag.ch&nbsp;benutzt «Google Analytics». Google Analytics verwendet sog. «Cookies» (Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglicht). Die durch das Cookie erzeugten Informationen über Ihre Benutzung diese Website (einschliesslich Ihrer IP-Adresse) wird an einen Server von Google in den USA übertragen und dort gespeichert. Google wird diese Informationen benutzen, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
            <p><strong>8.2 Matomo (Piwik)</strong><br />
          Matomo ist ein PHP-basiertes Open Source Webanalyse-Tool, das ähnlich funktioniert wie Google Analytics (Page Tagging). Die Applikation wird in Österreich gehostet. Die Applikation steht bei diversen Lernapplikationen vom Schulverlag im Einsatz. Erhoben werden Seitenzugriffe, Verweildauer, Browsertyp, Browsereinstellungen und Betriebssystem und geographische Quelle des Zugriffs. Alle Daten werden anonymisiert erhoben und erlauben keine Rückschlüsse auf die einzelne Userin oder den einzelnen User. Die Daten dienen uns zur Verbesserung unserer Lerninhalte. Hier finden Sie die <a rel="noopener noreferrer" href="https://matomo.org/privacy/" target="_blank">Datenschutzbestimmungen von Matomo</a>.</p>
            <p><strong>8.3 Mailchimp</strong><br />
          Der Schulverlag nutzt «MailChimp» für den Versand seiner Newsletter. Beim Öffnen eines Newsletters werden über «web-beacons» Informationen zu Ihrem Browser und Ihrem OS sowie Ihre IP-Adresse getrackt. Die statistische Auswertung umfasst weitere Metriken wie die Angabe, ob und wann die versandten Newsletter geöffnet werden und welche Links angeklickt werden. Obwohl diese Informationen rein technisch gesehen jeweils einzelnen Empfängerinnen oder Empfängern zugeordnet werden können, hat der Schulverlag kein Interesse daran. Den Schulverlag interessieren nur aggregierte und somit anonymisierte Daten. Hier finden Sie die <a rel="noopener noreferrer" href="https://mailchimp.com/legal/privacy/" target="_blank">Datenschutzbestimmungen von Mailchimp</a>.</p>
            <p><strong>8.4 ovos play</strong><br />
          ovos play ist eine Standardsoftware, beim Schulverlag zur Abbildung von Lerninhalten eingesetzt wird. ovos play wird von der ovos media GmbH entwickelt und betrieben. ovos play erhebt neben technischen Informationen (Browsertyp, Browsereinstellungen und Betriebssystem) und den Registrierungsdaten (Username / Registrierungsdatum) auch Bewertungsdaten wie Kartenperformance, Deck- und Topicperformance, Punktewerte (inkl. historischer Snapshots), Swipe Direction; Enable Push notifications; gewählter Avatar. Ferner wird das gewählte Profilbild des Nutzers; der Zeitpunkt der Accounterstellung; Zeitpunkt der Accountaktivierung und der Accountstatus (Aktivierung, Eingliederung, Registrierung) erfasst. Hier finden Sie die <a rel="noopener noreferrer" href="https://ovos.at/de/impressum/" target="_blank">Datenschutzbestimmungen der ovos media GmbH</a>.
            </p>
            <h3>9. Ihre Rechte</h3>
          Auf schriftliche Anfrage werden wir Sie gern über die zu Ihrer Person gespeicherten Daten informieren. Sie haben das Recht, eine erteilte Einwilligung mit Wirkung für die Zukunft jederzeit zu widerrufen. Die Löschung der gespeicherten personenbezogenen Daten erfolgt, wenn Sie Ihre Einwilligung zur Speicherung widerrufen, wenn die Daten zur Erfüllung des mit der Speicherung verfolgte Zwecks nicht mehr erforderlich sind oder wenn ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.</p>
          <h3>10. Verantwortliche Stelle / Kontakt Ansprechperson</h3>
          <p>
            Fragen zur Bearbeitung von Personendaten, zu deren Korrektur oder Löschung sind an folgende Adresse zu richten: <a href="mailto:support@schulverlag.ch">support@schulverlag.ch</a><br />&nbsp;
          </p>
          <p>
            &nbsp;<br /><strong>Standort Bern</strong><br />
            Belpstrasse 48, Postfach 366, CH-3007 Bern<br />Telefon +41 58 268 14 14<br />shopBE@schulverlag.ch<br /><br />
            <strong>Standort Buchs AG</strong><br /> Amsleracherweg 8, CH-5033 Buchs AG<br />Telefon +41 58 268 14 14<br />shopAG@schulverlag.ch<br />&nbsp;<br />
          </p>
        </div>
      </div>
    );
  }
}

export default DataProtectionOverlay;
