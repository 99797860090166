import React from 'react';
import { AnswerImage } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_audio_id, file_image_id, ...props }) => (
  <AnswerImage
    {...props}
    textElements={props.answers.map(textElement => ({
      id: textElement.id,
      audioSource: generateUrl(textElement.file_audio_id),
      imageSource: generateUrl(textElement.file_image_id)
    }))}
  />
);