
import DownloadHandler, { ERROR_MESSAGES } from './DownloadHandler';

class ElectronDownloadHandler extends DownloadHandler {
  fileSystem = window.require('electron').remote.require('fs');
  electronDownloadManager = window.require('electron').remote.require('electron-download-manager');
  ipcRenderer = window.require('electron').ipcRenderer;
  SAVE_FILE = 'savefile';
  SAVED_FILE = 'savedfile';
  dataDirectory = window.navigator.platform.match(/(win)/i) ?
    'C:/ProgramData/SVP/assets/' :
    '/Library/Application Support/SVP/assets/';


  getPathToFile = (name, ext = 'txt') => {
    return `${this.dataDirectory}${this.resolveFilename(name)}.${ext}`;
  }

  isFileDownloaded = async (name, ext) => {
    return false;

    // ELECTRON DOWNLOAD MAGAGER CHECKS IF IT IS DOWNLOADED BY COMPARING THE SIZE OF THE FILE AND THE SUPPOSED SIZE FROM THE REQUEST
    // const path = this.getPathToFile(name, ext);
    // const filePromise = new Promise(async (resolve, reject) => {
    //   this.fileSystem.access(path, this.fileSystem.constants.F_OK, err => {
    //     if (err) {
    //       resolve(false);
    //     } else {
    //       resolve(true);
    //     }
    //   });
    // });
    // return filePromise;
  }

  downloadFile = async (url, name) => {
    return new Promise(async (resolve, reject) => {
      this.electronDownloadManager.download({ url, filename: this.resolveFilename(name) }, function (err, info) {
        if (err) {
          DownloadHandler.errorMessage(ERROR_MESSAGES.DOWNLOAD, err);
          reject(err);
        }
        // console.log('Download successful:', info.filePath);
        resolve(info.filePath);
      });
    });
  }

  saveBlobToFilesystem = (blob, url) => {
    const dlh = this;
    // set up a reader which will read the blob.
    // create a buffer from the read in blob.
    // send a message to the main process that it should write the file to the fs.
    // wait for the reply with the fileUrl and then resolve.
    return new Promise(async function (resolve, reject) {
      let reader = new FileReader();
      reader.onloadend = function () {
        var buffer = new Buffer(reader.result);
        const fileName = dlh.resolveFilename(url);
        dlh.ipcRenderer.setMaxListeners(50); // 25 save file
        dlh.ipcRenderer.send(dlh.SAVE_FILE, fileName, buffer, blob.type);
        // console.log(`Saving ${JSON.stringify({ fileName, size: blob.size, type: blob.type })}`);

        dlh.ipcRenderer.once(dlh.SAVED_FILE, (event, fileUrl, err) => {
          if (err) {
            DownloadHandler.errorMessage(ERROR_MESSAGES.WRITE_FILE_ON_ELECTRON, err, fileUrl);
            resolve(null);
          } else {
            // console.log('Saved file ' + fileUrl);
            resolve(fileUrl);
          }
        });
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  returnCorrectErrorObject = () => {
    return this.transparentGif;
  }

  downloadAsset = async ({ url, name, regenerateBlobUrl = false }) => {
    if (typeof url !== 'string') {
      return this.returnCorrectErrorObject();
    }

    let persistedUrl;

    persistedUrl = await this.downloadAsFile(url, name);
    if (persistedUrl) {
      this.queueSetFile({ name, url: persistedUrl });
    }

    return persistedUrl || this.returnCorrectErrorObject();
  }
}

export default ElectronDownloadHandler;