import React from 'react';
import { QuestionImageText } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_image_id, file_audio_id, ...props }) => (
  <QuestionImageText
    {...props}
    imageSource={generateUrl(file_image_id)}
    audioSource={generateUrl(file_audio_id)}
  />
);