import isArray from 'lodash/isArray';

import { batchActions } from 'redux-batched-actions';

import { store } from '../redux';
import { actions } from '../magazineBlobs/redux';

import deleteAsset from './deleteAsset';
import getThumbnailPath from './functions/getThumbnailPath';
import { IMAGE_THUMBNAIL, CONTAINER_TYPES } from '../../components/constants';

let setMagazinesWithUpdatedBlobsBuffer = [];
let setFileTimeout = false;

function deleteMagazinesWithUpdatedBlobs(id) {
  clearTimeout(setFileTimeout);

  setMagazinesWithUpdatedBlobsBuffer.push(actions.setMagazinesWithUpdatedBlobs(id));

  setFileTimeout = setTimeout(() => {
    store.dispatch(batchActions(setMagazinesWithUpdatedBlobsBuffer));
    setMagazinesWithUpdatedBlobsBuffer = [];
  }, 250);
}

export default function deleteItems(obj = {}, ids = null) {
  if (ids) {
    if (ids.length) {
      ids.map(id => deleteMagazinesWithUpdatedBlobs(id));
    }
    else {
      deleteMagazinesWithUpdatedBlobs(ids);
    }
  }
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'object') {
      deleteItems(obj[key] || {});
    } else if (typeof key === 'string' && key.indexOf('file_') === 0) {
      switch (obj.__typename) {
        case 'Page':
          deleteAsset(
            obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.PAGES_OVERLAY.WIDTH })
          );
          deleteAsset(
            obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.PAGE.WIDTH, quality: IMAGE_THUMBNAIL.PAGE.QUALITY })
          );
          break;
        case 'Magazine':
          // deleteAsset(obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.MAGAZINES_OVERLAY.WIDTH }));
          break;
        case 'Datapool':
          if (key.includes('image')) {
            if (obj.type === CONTAINER_TYPES.CONTAINER_SCENE) {
              deleteAsset(
                obj[key] + getThumbnailPath({ height: IMAGE_THUMBNAIL.SCENE.HEIGHT, quality: IMAGE_THUMBNAIL.SCENE.QUALITY })
              );
            } else {
              deleteAsset(
                obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.DATAPOOL_IMAGE.WIDTH, height: IMAGE_THUMBNAIL.DATAPOOL_IMAGE.HEIGHT })
              );
            }
          } else {
            deleteAsset(obj[key]);
          }
          break;
        case 'Content':
          if (key.includes('image')) {
            if (obj.type && obj.type ==='CONTENT_SLIDESHOW_SLIDE') {
              deleteAsset(
                obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.PAGE.WIDTH })
              );
            } else if (key === 'file_background_image_id') {
              deleteAsset(
                obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.PAGE.WIDTH })
              );
            } else {
              deleteAsset(
                obj[key] + getThumbnailPath({ width: IMAGE_THUMBNAIL.CONTENT.WIDTH })
              );
            }
          } else {
            deleteAsset(obj[key]);
          }
          break;
        default:
          deleteAsset(obj[key]);
      }
    }
    return acc;
  }, isArray(obj) ? [] : {});
}