const defaultState = {
  unlocked: [],
  overrideUnlock: false,
};

const SET_UNLOCKED = 'SET_UNLOCKED';
const CLEAR_UNLOCKED = 'CLEAR_UNLOCKED';

const TOGGLE_OVERRIDE_UNLOCK = 'TOGGLE_OVERRIDE_UNLOCK';
const SET_OVERRIDE_LOGIN = 'SET_OVERRIDE_LOGIN';

const setUnlocked = unlocked => ({ type: SET_UNLOCKED, unlocked });
const clearUnlocked = () => ({ type: CLEAR_UNLOCKED });

const toggleOverrideUnlock = () => ({ type: TOGGLE_OVERRIDE_UNLOCK });

const setOverrideLogin = overrideLogin => ({ type: SET_OVERRIDE_LOGIN, overrideLogin });

const licenseReducer = (state = defaultState, { type, unlocked, overrideLogin }) => {
  switch (type) {
    case SET_UNLOCKED:
      return {
        ...state,
        unlocked: Array.isArray(unlocked) ? unlocked : [unlocked]
      };
    case CLEAR_UNLOCKED:
      return { ...defaultState };
    case TOGGLE_OVERRIDE_UNLOCK:
      return { 
        ...state,
        overrideUnlock: !state.overrideUnlock
      };
    case SET_OVERRIDE_LOGIN:
      return { 
        ...state,
        overrideLogin
      };
    default: return state;
  }
};

export const reducerKey = 'license';
export const reducer = licenseReducer;
export const actions = {
  setUnlocked,
  clearUnlocked,
  toggleOverrideUnlock,
  setOverrideLogin
};
