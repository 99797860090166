import { getStoredState as getStoredStateNew } from 'redux-persist';


export default function getStoredState(oldConfig) {
  return function (newConfig) {
    return getStoredStateNew(newConfig).then(state => {
      if (state) return state;
      else return getStoredStateOld(oldConfig);
    });
  };
}

function hasLocalStorage() {
  if (typeof self !== 'object' || !('localStorage' in self)) { // eslint-disable-line
    return false;
  }

  try {
    let storage = self.localStorage; // eslint-disable-line
    const testKey = 'redux-persist localStorage test';
    storage.setItem(testKey, 'test');
    storage.getItem(testKey);
    storage.removeItem(testKey);
  } catch (e) {
    if (process.env.NODE_ENV !== 'production')
      console.warn(
        'redux-persist localStorage test failed, persistence will be disabled.'
      );
    return false;
  }
  return true;
}

let noop = (...args) => {
  /* noop */ return null;
};
const noStorage = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
  getAllKeys: noop,
};
const createAsyncLocalStorage = () => {
  if (!hasLocalStorage()) return noStorage;
  let localStorage = self.localStorage; // eslint-disable-line
  return {
    getAllKeys: function (cb) {
      try {
        var keys = [];
        for (var i = 0; i < localStorage.length; i++) {
          keys.push(localStorage.key(i));
        }
        cb(null, keys);
      } catch (e) {
        cb(e);
      }
    },
    getItem(key, cb) {
      try {
        var s = localStorage.getItem(key);
        cb(null, s);
      } catch (e) {
        cb(e);
      }
    },
    setItem(key, string, cb) {
      try {
        localStorage.setItem(key, string);
        cb(null);
      } catch (e) {
        cb(e);
      }
    },
    removeItem(key, cb) {
      try {
        localStorage.removeItem(key);
        cb && cb(null);
      } catch (e) {
        cb(e);
      }
    },
  };
};

function getStoredStateOld(oldConfig) {
  return new Promise((resolve, reject) => {
    let storage = oldConfig.storage || createAsyncLocalStorage();

    let restoredState = {};
    const KEY = 'persist:root';
    storage.getItem(KEY, (err, serialized) => {
      const deserialized = JSON.parse(serialized);
      if (err && process.env.NODE_ENV !== 'production')
        console.warn('redux-persist/getStoredStateOld: Error restoring data for key:', KEY, err);
      else restoredState = rehydrate(deserialized);
      resolve(restoredState);
    });
  });

  function rehydrate(data) {
    const state = {};
    Object.keys(data).forEach(key => state[key] = JSON.parse(data[key]));
    return state;
  }
}