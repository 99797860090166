import React from 'react';

import { AuthProvider } from './domains/auth/containers';
import { ReduxProvider } from './domains/redux';
import { ConfigProvider } from './domains/config';
import { MatomoProvider } from './domains/matomo';
import { Graphql } from './containers';
import { OverlaySwitch } from './components';

import './styles.css';

function App(props) {
  return (
    <ReduxProvider>
      <Graphql>
        <AuthProvider>
          <ConfigProvider>
            <MatomoProvider>
              <OverlaySwitch />
            </MatomoProvider>
          </ConfigProvider>
        </AuthProvider>
      </Graphql>
    </ReduxProvider>
  );
}

export default App;
