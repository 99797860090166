import { Component } from 'react';
import { connect } from 'react-redux';
import ReactPiwik from 'react-piwik';

// initialize matomo
new ReactPiwik({
  url: 'piwik.ovos.at',
  siteId: (window.location.hostname === 'serv88919410.secure-node.at' || 
  window.location.hostname === 'app.serv88919410.secure-node.at') ? 11 : 10,
  trackErrors: true,
});

export function matomo(content) {
  ReactPiwik.push(content);
};

class MatomoProvider extends Component {
  constructor(props) {
    super(props);
    const magazines = props.magazines;

    matomo(['enableHeartBeatTimer']);
    if (magazines.currentMagazineId && magazines.pageId) {
      matomo(['setCustomUrl', `/magazine/${magazines.currentMagazineId}/page/${magazines.pageId}`]);
    } else {
      matomo(['setCustomUrl', '/start']);
    }
    matomo(['trackPageView']);
  }

  render() {
    const { children } = this.props;
    return children;
  }
};

const mapStateToProps = ({ magazines }) => ({
  magazines
});

export default connect(mapStateToProps)(MatomoProvider);