import { toast } from 'react-toastify';
import { store } from '../domains/redux/store';

import { generateUrl } from '../domains/uploads';
import appVersion from '../helpers/appVersion';

function resolveFilename(filename) {
  return filename.replace(/\?/g, '_');
}

async function downloadElectron(uri, name, file) {
  const remote = window.require('electron').remote;
  const electronDownloadManager = remote.require('electron-download-manager');

  return new Promise(async (resolve, reject) => {
    electronDownloadManager.download({ url: uri, filename: resolveFilename(name), askForDownloadPath: true }, function (err, info) {
      if (err) {
        console.error('DOWNLOAD FROM BROWSER FAILED:', file, err);
        reject(err);
      }
      // console.log('Download successful:', info.filePath);
      resolve(info.filePath);
    });
  });
}

export function createFile(dirEntry, fileName) {
  return new Promise((resolve, reject) => {
    // Creates a new file or returns the file if it already exists.
    dirEntry.getFile(
      fileName,
      { create: true, exclusive: false },
      function (fileEntry) {
        resolve(fileEntry);
      },
      reject
    );
  });
}

export function writeFile(fileEntry, dataObj) {
  return new Promise((resolve, reject) => {
    // Create a FileWriter object for our FileEntry (log.txt).
    fileEntry.createWriter(function (fileWriter) {
      fileWriter.onwriteend = function () {
        console.info('Successful file write...');
        resolve(fileEntry);
      };

      fileWriter.onerror = function (e) {
        console.error('Failed file write: ' + e.toString());
        reject(e);
      };

      // If data object is not passed in,
      // create a new Blob instead.
      if (!dataObj) {
        dataObj = new Blob(['some file data'], { type: 'text/plain' });
      }

      fileWriter.write(dataObj);
    });
  });
}

export default async function downloadFromBrowser(file, name = 'svp-file') {
  const online = store.getState().offline.online;

  if (!online) {
    toast('Kann nicht gedownloaded werden, da zur Zeit keine Internetverbindung besteht.');
    return;
  }

  if (document.IS_CORDOVA) {
    const uri = generateUrl(file, null, true, true);
    window.open(uri, '_blank');
  } else if (appVersion.isDesktop) {
    const uri = generateUrl(file, null, true, true);
    await downloadElectron(uri, name, file);
  } else {
    window.open(generateUrl(file, null, true, true), '_blank');
  }
}
