import React, { Component } from 'react';

import styles from './styles.less';
import { Popup } from '../../components/Popup';

export default class AndroidBackbuttonHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false
    };

    document.addEventListener('backbutton', this.onBackKeyDown, false);
  }

  onBackKeyDown = () => {
    const { showPopup } = this.state;

    if (showPopup) {
      this.closeApp();
    } else {
      this.setState({
        showPopup: true
      });
    }
  }

  render() {
    const { showPopup } = this.state;
    if (!showPopup) {
      return null;
    }
    return (
      <div className={styles.closeContainer}>
        <Popup
          className={styles.popupContainerStyle}
          show={showPopup}
          title="Attention!"
          text={<span>Est-ce que tu veux fermer l'application?</span>}
          accept={this.closeApp}
          cancel={this.cancel}
        />
      </div>
    );
  }

  closeApp = () => {
    navigator.app.exitApp();
  }

  cancel = () => {
    this.setState({
      showPopup: false
    });
  }
}

