import React from 'react';
import { QuestionCloze } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_audio_id, file_image_id, ...props }) => {
  return (
    <QuestionCloze
      {...props}
      audioSource={generateUrl(file_audio_id)}
      imageSource={generateUrl(file_image_id)}
    />
  );
};