import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { matomo } from '../../../domains/matomo';
import { Popup } from '../../Popup';
import Datapools from '../Components/Datapools';
import Result from './Components/Result';
import TaskPair from './Components/TaskPair';

import { CONTAINER_SCREENS, CONTAINER_WAITING_TIME, CONTAINER_FLIP_TIME, ITEMS_TYPE, NEXT_TASK_TYPE, TASK_TYPE_MAP, HEADER_INFO_TYPE } from '../../constants';

import styles from './styles.less';
import verifyAnswer from '../functions/verifyAnswer';
import taskCanContinue from '../functions/taskCanContinue';
import nextTask from '../functions/nextTask';

class PairContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenType: props.screenType,
      datapool: props.currentDatapool,
      task: props.item,
      showInformation: false,
      information: '',
      taskProgress: [],
      startRotation: false,
      backgroundColor: false,
      showPopup: false,
      firstPlayer: true,
      isSpeak: false,
      setup: true,
      player: { player1: '', player2: '' }
    };
  }

  render() {
    const { screenType, datapool, task, showInformation, information, taskProgress, startRotation, backgroundColor, showPopup, firstPlayer, setup, player } = this.state;
    const { onClose, container, enteredAnswer, getClozeAnswers } = this.props;

    let contentComponent = null;
    switch (screenType) {
      case CONTAINER_SCREENS.DATAPOOLS:
        contentComponent = <Datapools container={container} onClose={onClose} setDatapool={this.setDatapool} />;
        break;
      case CONTAINER_SCREENS.RESULT:
        const resultData = taskProgress.reduce((acc, curr) => {
          if (acc[curr.player]) {
            acc[curr.player] = curr.status === 'right' ? acc[curr.player] + 1 : acc[curr.player];
          } else {
            acc[curr.player] = curr.status === 'right' ? 1 : 0;
          };
          return acc;
        }, {});
        contentComponent =
          <Result
            showDatapools={this.reset}
            datapoolName={datapool.name}
            showInformation={showInformation}
            information={information}
            toggleInformation={this.toggleInformation}
            onClose={onClose}
            restart={this.replay}
            resultData={resultData}
            data={taskProgress}
            player={player}
          />;
        break;
      case CONTAINER_SCREENS.CONTAINER_PAIR:
        contentComponent = task
          ? <TaskPair
            datapoolName={datapool.name}
            showDatapools={this.reset}
            togglePopup={this.togglePopup}
            information={information}
            toggleInformation={this.toggleInformation}
            showInformation={showInformation}

            startRotation={startRotation}
            backgroundColor={backgroundColor}
            taskProgress={taskProgress}
            task={task}
            enteredAnswer={enteredAnswer}
            verify={this.verify}
            canContinue={taskCanContinue(task.answerGroup, enteredAnswer, getClozeAnswers)}

            finishSetup={this.finishSetup}
            setup={setup}
            firstPlayer={firstPlayer}
            player={player}
            setPlayerName={this.setPlayerName}

            description={datapool.description}
            rounds={datapool.items_per_session}
            imageId={datapool.file_image_id}
          />
          : null;
        break;
      default:
        break;
    }
    return (
      <div className={styles.container}>
        {contentComponent}
        <Popup
          show={showPopup}
          title="Attention!"
          text={<span>Est-ce que tu veux vraiment arrêter le jeu?<br />Tous les progrès seront perdus si tu fermes le jeu maintenant.</span>}
          accept={this.accept}
          cancel={this.cancel}
        />
      </div>
    );
  }

  componentDidMount = () => {
    this.timeoutSlot = null;
    this.timeoutSlot2 = null;
  };

  togglePopup = () => {
    const { showPopup } = this.state;
    this.setState({
      showPopup: !showPopup
    });
  }

  accept = () => {
    const { onClose } = this.props;
    onClose();
  }

  cancel = () => {
    this.togglePopup();
  }

  toggleInformation = type => {
    const { showInformation, task, datapool } = this.state;
    this.setState({
      showInformation: showInformation !== type ? type : false,
      information: type === HEADER_INFO_TYPE.TASK ? task.description : type === HEADER_INFO_TYPE.DATAPOOL ? datapool.description : ''
    });

    if ((showInformation && type !== showInformation) || !showInformation) {
      if (type === HEADER_INFO_TYPE.TASK) {
        matomo(['trackEvent', 'ClickedHelp', `${task.name} (${task.id})`]);
      } else {
        matomo(['trackEvent', 'ClickedBackgroundInfo', `${datapool.name} (${datapool.id})`]);
      }
    }
  }
  verify = () => {
    const { setAnswer, addToClozeAnswers, enteredAnswer, getClozeAnswers } = this.props;
    const { isSpeak, task, taskProgress, firstPlayer, player } = this.state;

    const correct = verifyAnswer(task.answerGroup, enteredAnswer, getClozeAnswers);

    let resetAnswers = false;
    let newTask = false;
    let currPlayer = '';
    let opponent = '';
    if (firstPlayer) {
      currPlayer = player.player1;
      opponent = player.player2;
    } else {
      currPlayer = player.player2;
      opponent = player.player1;
    }
    if (isSpeak) {
      setTimeout(() => this.setState(prevState => {
        if (prevState.task) {
          return ({
            isSpeak: false,
            task: {
              ...prevState.task,
              answerGroup: {
                ...prevState.task.answerGroup,
                type: 'ANSWER_SPEAK'
              }
            }
          });
        }
      }
      ), CONTAINER_WAITING_TIME);
    }
    if (correct === 'Speak') {
      this.setState({
        startRotation: true
      });
      setTimeout(() => this.setState(prevState => ({
        isSpeak: true,
        task: {
          ...prevState.task,
          answerGroup: {
            ...prevState.task.answerGroup,
            type: TASK_TYPE_MAP.ANSWER_FACT_FICTION,
            message: `<strong>${opponent}</strong>, vérifie la réponse de <strong>${currPlayer}</strong>!`
          }
        }
      })), CONTAINER_FLIP_TIME);
      setAnswer();
      addToClozeAnswers();
    } else if (correct) {
      taskProgress.push({ player: currPlayer, status: 'right' });
      this.setState({
        backgroundColor: 'green'
      });
      newTask = true;
      resetAnswers = true;
    } else {
      taskProgress.push({ player: currPlayer, status: 'wrong' });
      newTask = true;
      this.setState({
        backgroundColor: 'red'
      });
      resetAnswers = true;
    }
    this.continue(firstPlayer, newTask, resetAnswers);
  }

  continue = (firstPlayer, newTask, resetAnswers) => {
    const { setAnswer, addToClozeAnswers, enteredAnswer } = this.props;
    const { taskProgress, datapool } = this.state;
    let funcAfterTimeout = null;
    if (taskProgress.length === datapool.items_per_session * 2) {
      funcAfterTimeout = () => {
        this.setState({
          screenType: CONTAINER_SCREENS.RESULT,
          startRotation: false,
          backgroundColor: '',
          task: null
        });

        matomo(['trackEvent', 'FinishedDatapool', `${datapool.name} (${datapool.id})`]);
      };
    } else if (newTask) {
      funcAfterTimeout = () => this.setTask(enteredAnswer);
      if (firstPlayer) {
        this.timeoutSlot = setTimeout(() => this.setState({ firstPlayer: false, startRotation: false, backgroundColor: '' }), CONTAINER_WAITING_TIME);
      } else {
        this.timeoutSlot = setTimeout(() => this.setState({ firstPlayer: true, startRotation: false, backgroundColor: '' }), CONTAINER_WAITING_TIME);
      }
    }
    if (resetAnswers) {
      this.timeoutSlot = setTimeout(() => { setAnswer(); addToClozeAnswers(); }, CONTAINER_WAITING_TIME);
    }
    this.timeoutSlot2 = setTimeout(funcAfterTimeout, CONTAINER_WAITING_TIME);
  }

  replay = () => {
    const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
    const { datapool } = this.state;
    resetCurrentUsedItems(datapool);
    this.setState({
      screenType: container.type,
      firstPlayer: true,
      taskProgress: []
    }, () => this.setState({ task: getItemFromPool(datapool, ITEMS_TYPE.TASKS) }));
  }

  reset = () => {
    const { resetCurrentUsedItems } = this.props;
    const { datapool } = this.state;

    clearTimeout(this.timeoutSlot);
    clearTimeout(this.timeoutSlot2);

    resetCurrentUsedItems(datapool);
    this.setState({
      screenType: CONTAINER_SCREENS.DATAPOOLS,
      datapool: null,
      task: null,
      showInformation: false,
      firstPlayer: true,
      setup: true,
      player: { player1: '', player2: '' },
      taskProgress: [],
      startRotation: false,
      backgroundColor: '',
      isSpeak: false,
    });
  }

  setDatapool = id => {
    const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
    const datapool = container.datapools.find(datapool => datapool.id === id);

    resetCurrentUsedItems(datapool);
    this.setState(prevState => ({
      datapool: datapool,
      firstPlayer: true,
      screenType: container.type,
      task: getItemFromPool(datapool, ITEMS_TYPE.TASKS),
      taskProgress: []
    }));
  }

  setTask = enteredAnswer => {
    const { getItemFromPool } = this.props;
    const { datapool, task } = this.state;
    const hasDependency = task.answerGroup.config ? JSON.parse(task.answerGroup.config).dependencyMode : false;
    this.setState({
      task: hasDependency ? nextTask(datapool.tasks, task.answerGroup, enteredAnswer, NEXT_TASK_TYPE.TASK) : getItemFromPool(datapool, ITEMS_TYPE.TASKS),
      startRotation: false,
      backgroundColor: ''
    });
  }

  finishSetup = () => {
    const { player: { player1, player2 }, datapool } = this.state;

    this.setState({
      player: {
        player1: player1 || 'Joueur 1',
        player2: player2 || 'Joueur 2'
      },
      setup: false
    });

    matomo(['trackEvent', 'StartedDatapool', `${datapool.name} (${datapool.id})`]);
  }

  setPlayerName = (player, name) => {
    const { player: { player1, player2 } } = this.state;
    if (player === 'player1') {
      this.setState({ player: { player1: name, player2 } });
    } else if (player === 'player2') {
      this.setState({ player: { player1, player2: name } });
    }
  }
}

PairContainer.propTypes = {
  setAnswer: PropTypes.func.isRequired,
  getItemFromPool: PropTypes.func.isRequired,
  resetCurrentUsedItems: PropTypes.func.isRequired
};

PairContainer.defaultProps = {
};

export default PairContainer;
