import { batchActions } from 'redux-batched-actions';

import { updateBlobsDownload as downloadAsset, migrateBlobs } from '../../uploads/downloadAsset';

import { store } from '../../redux';

let setBlobProgressActionBuffer = [];
let setBlobProgressTimeout = false;
/**
 * Batch multiple set file actions into one batched action
 */
function setBlobLoading(downloadProgress, blobLoadedFiles, amountOfPromises) {
  clearTimeout(setBlobProgressTimeout);

  setBlobProgressActionBuffer.push(
    {
      type: 'SET_BLOB_LOADING_PROGRESS',
      payload: {
        blobLoadingProgress: Math.floor(downloadProgress),
        blobFilesToLoad: amountOfPromises,
        blobLoadedFiles
      }
    }
  );

  // the timeout update will only work if there is a break of more than 500ms and then the update is fired
  // since most things will not take so long updates are pretty rare.
  // To still show that something is going on we send an update every 10th file.
  if (blobLoadedFiles % 10 === 0) {
    store.dispatch(batchActions(setBlobProgressActionBuffer));
    setBlobProgressActionBuffer = [];
  }

  setBlobProgressTimeout = setTimeout(() => {
    store.dispatch(batchActions(setBlobProgressActionBuffer));
    setBlobProgressActionBuffer = [];
  }, 500);
}

export default async function updateBlobUrls(filter, migrate, selectedFilesToDownload) {
  let { files } = store.getState().assets;

  if (filter) {
    const regex = new RegExp(`${filter.join('|')}`);

    files = Object.keys(files).reduce((acc, key) => {
      return regex.test(key)
        ? { ...acc, [key]: files[key] }
        : acc;
    }, {});
  }

  if (selectedFilesToDownload) {
    files = selectedFilesToDownload;
  }

  const amountOfPromises = Object.keys(files).length;
  const downloadProgressStep = 100 / amountOfPromises;
  let downloadProgress = 0;
  let blobLoadedFiles = 0;
  await Promise.all(
    Object.keys(files)
      .map(fileName => migrate ?
        migrateBlobs(
          { name: fileName, url: fileName }
        ).then(() => {
          downloadProgress += downloadProgressStep;
          blobLoadedFiles += 1;
          setBlobLoading(downloadProgress, blobLoadedFiles, amountOfPromises);
        }) :
        downloadAsset(
          { name: fileName, url: fileName, regenerateBlobUrl: true }
        ).then(() => {
          downloadProgress += downloadProgressStep;
          blobLoadedFiles += 1;
          setBlobLoading(downloadProgress, blobLoadedFiles, amountOfPromises);
        })
      )
  );
}
