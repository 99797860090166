import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import equal from 'fast-deep-equal';

import { version } from '../../../config/version';
import { NOTIFICATION_STATE } from '../../domains/notifications/constants';
import { Button } from '..';

import { actions as magazinesActions } from '../../domains/magazines/redux';
import { actions as licenseActions } from '../../domains/license/redux';
import { actions as notificationsActions } from '../../domains/notifications/redux';

import LoginButton from '../../domains/auth/components/LoginButton';

import ToastCloseButton from '../Toast/components/ToastCloseButton';
// import LicenseKey from '../../domains/license/components/LicenseKey';
import Year from './components/Year';

import styles from './styles.less';

const ToastElement = ({ message, markNotificationAsRead }) => (
  <span>{message}</span>
);

class MagazinesOverlay extends Component {
  state = {
    showOverrideUnlockOverlay: false
  }

  componentDidMount() {
    const { downloadNotifications } = this.props;
    downloadNotifications();
  }

  componentDidUpdate(prevProps) {
    const { notifications, updateNotificationToastId, markNotificationAsRead } = this.props;

    const updatedNotifications = [];
    notifications.forEach(notification => {
      if (!notification.toastId && notification.state !== NOTIFICATION_STATE.READ) {
        const toastId = toast(<ToastElement message={notification.message} markNotificationAsRead={() => markNotificationAsRead(notification)} />,
          {
            autoClose: false,
            onClose: ({ markNotificationAsRead }) => markNotificationAsRead(),
            closeButton: <ToastCloseButton />
          }
        );
        updatedNotifications.push({ ...notification, toastId });
      } else {
        updatedNotifications.push(notification);

        if (notification.toastId) {
          toast.update(notification.toastId,
            { render: <ToastElement message={notification.message} markNotificationAsRead={() => markNotificationAsRead(notification)} /> }
          );
        }
      }
    });

    if (!equal(notifications, updatedNotifications)) {
      updateNotificationToastId(updatedNotifications);
    }
  }

  onVersion = () => {
    this.setState({
      showOverrideUnlockOverlay: true
    });
  }

  onOverrideUnlockClose = () => {
    this.setState({
      showOverrideUnlockOverlay: false
    });
  }

  onOverrideUnlockInputChange = event => {
    const { toggleOverrideUnlock } = this.props;

    if (event.target.value === '1SVPadmin') {
      toggleOverrideUnlock();

      this.setState({
        showOverrideUnlockOverlay: false
      });
    }
  }

  render() {
    const { magazinesList, license: { unlocked, overrideUnlock, overrideLogin }, loggedIn } = this.props;
    const { showOverrideUnlockOverlay } = this.state;

    const years = magazinesList.length ? magazinesList.reduce((acc, key) => {
      if (!acc.length || acc.findIndex(x => x.id === key.year.id) < 0) {
        acc.push(key.year);
      }
      return acc;
    }, []) : [];

    return (
      <div className={styles.magazinesOverlay} onKeyUp={this.handleKeyUp}>
        <div className={styles.magazineScrollFix} />
        {showOverrideUnlockOverlay &&
          <div className={styles.overrideUnlockOverlay}>
            <div className={`${styles.wrapperExpanded}`}>
              <input
                type="text"
                className={styles.licenseInput}
                onChange={this.onOverrideUnlockInputChange}
                maxLength={24}
                onKeyUp={this.onInputKeyUp}
                autoFocus
              />
            </div>
            <Button icon="close-large" classes={styles.button} onClick={this.onOverrideUnlockClose} />
          </div>
        }
        <div className={`${styles.header}`}>
          <img className={styles.logo} src="./svp-logo.png" alt="" />
          <div className={styles.welcome}>Herzlich willkommen auf der Plattform<br />«Mille feuilles» und «Clin d’œil»</div>
          {!overrideLogin && <LoginButton hasNoUnlockedMagazines={!unlocked.length} loggedIn={loggedIn} overrideUnlock={overrideUnlock} />}
          {/* {(!appVersion.isDesktop && !appVersion.isIpad) || appVersion.isMobile ? <LicenseKey hasNoUnlockedMagazines={!license.unlocked.length} overrideUnlock={license.overrideUnlock} /> : null} */}
          <div className={styles.version} onClick={this.onVersion}>Version<br />{version}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.magazinesContainer}>
            {years.length ? years.sort((y1, y2) => y1.sort - y2.sort).map(year => <Year
              magazines={magazinesList.filter(x => x.year.id === year.id)}
              year={year}
              {...this.props}
              key={year.id} />
            ) : null}
            {/* {unlocked.length || appVersion.isIpad || overrideUnlock || overrideLogin  ?
              null :
              (!unlocked.length && appVersion.isMobile) || (!unlocked.length && loggedIn) ?
                <div className={styles.noMagazinesWarning}>Um Magazine freizuschalten, bitte einen <strong>Lizenzschlüssel</strong> aktivieren.</div>
                :
                <div className={styles.noMagazinesWarning}>Bitte <strong>melden</strong> Sie sich mit Ihrem Schulverlag-Konto <strong>an</strong> oder geben Sie Ihren <strong>Lizenzschlüssel</strong> ein.</div>
            } */}
          </div>
        </div>
      </div >
    );
  }
}


const mapStateToProps = ({ magazines, license, auth: { user }, notifications: { notifications } }) => {
  return {
    overlay: magazines.overlay,
    magazinesList: magazines.magazines,
    offlineYearsList: magazines.offlineYearsList,
    offlineMagazinesList: magazines.offlineMagazinesList,
    magazinesLastLoaded: magazines._loaded,
    queuedMagazines: magazines.queuedMagazines,
    currentMagazineId: magazines.currentMagazineId,
    closedWithOngoingDownloads: magazines.closedWithOngoingDownloads,
    license,
    loggedIn: !(!user || (user && !user.user) || (user && user.user && !user.user.loginId)),
    notifications
  };
};

const mapDispatchToProps = {
  setPage: magazinesActions.setPageId,
  setOverlay: magazinesActions.setOverlay,
  setCurrentMagazineId: magazinesActions.setCurrentMagazineId,
  downloadMagazines: magazinesActions.downloadMagazines,
  queueMagazinesForDownload: magazinesActions.queueMagazinesForDownload,
  deleteMagazine: magazinesActions.deleteMagazine,
  cancelDownloads: magazinesActions.cancelDownloads,
  setMagazines: magazinesActions.setMagazines,
  setClosedWithOngoingDownloads: magazinesActions.setClosedWithOngoingDownloads,
  toggleOverrideUnlock: licenseActions.toggleOverrideUnlock,
  downloadNotifications: notificationsActions.download,
  markNotificationAsRead: notificationsActions.markAsRead,
  updateNotificationToastId: notificationsActions.updateToastId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MagazinesOverlay);