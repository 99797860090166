import appVersion from './appVersion';

// let interval;

export function openUrl(url, readerMode, fallbackTarget = '_blank') {
  if (appVersion.isIpad) {
    window.SafariViewController.isAvailable(function (available) {
      if (available) {
        window.SafariViewController.show({
          url: url,
          hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
          animated: false, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
          enterReaderModeIfAvailable: readerMode, // default false
          tintColor: '#00ffff', // default is ios blue
          barColor: '#0000ff', // on iOS 10+ you can change the background color as well
          controlTintColor: '#ffffff' // on iOS 10+ you can override the default tintColor
        },
        // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
        function (result) {
          if (result.event === 'opened') {
            console.info('opened');
          } else if (result.event === 'loaded') {
            console.info('loaded');

            // this will hide the Safariviewcontroller, after the logout page loaded
            if(url.includes(`${process.env.REACT_APP_AUTHENTICATION_URL}/logout?idToken=`)) {
              window.SafariViewController.hide();
            }
            // this approach does not work because, ios does only allow us to get this
            // event for the url that was passed, not for other urls that come afterwards
            // Thanks Apple
            // else if(url.includes(`${process.env.REACT_APP_AUTHENTICATION_URL}`)) {
            //   interval = setInterval(function() {
            //     if(window.location === process.env.REACT_APP_API_URL.replace('api/', '')) {
            //       clearInterval(interval);
            //       window.SafariViewController.hide();
            //     }
            //   }, 100);
            // }
          } else if (result.event === 'closed') {
            console.info('closed');
            // clearInterval(interval);
          }
        },
        function (msg) {
          console.log('KO: ' + msg);
        });
      } else {
        // potentially powered by InAppBrowser because that (currently) clobbers window.open
        window.open(url, fallbackTarget);
      }
    });
  } else {
    window.open(url, fallbackTarget);
  }
}

export function dismissSafari() {
  if (appVersion.isIpad) {
    window.SafariViewController.hide();
  }
}