import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { TextWithAudio } from '@ovos-media/templates/dist/sp';

import Button from '../../Button';

import styles from '../styles/header.less';
import { HEADER_INFO_TYPE } from '../../constants';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: ''
    };
  }

  render() {
    const { showDatapoolsButton, datapoolName, showDatapools, onClose, information, showInformation, showTaskInformation } = this.props;
    const { clicked } = this.state;
    return (
      <div
        className={styles.titleBar}
        style={{ height: `${showInformation ? '102%' : '44px'}` }}
      >
        <div className={styles.top}>
          <div className={styles.left}>
            {showDatapoolsButton && <Button icon="grid" color="gray" onClick={showDatapools} />}
            {datapoolName}
          </div>
          <div className={styles.right}>
            {showTaskInformation && <Button icon="question-circle" color="gray" active={showInformation === HEADER_INFO_TYPE.TASK} onClick={() => this.toggleInfo(HEADER_INFO_TYPE.TASK)} />}
            <Button icon="information" color="gray" active={showInformation === HEADER_INFO_TYPE.DATAPOOL} onClick={() => this.toggleInfo(HEADER_INFO_TYPE.DATAPOOL)} />
            <Button icon="close-large" color="yellow" onClick={onClose} />
          </div>
        </div>
        <div className={styles.informationContainer}>
          <div className={styles.title}>{`${clicked === HEADER_INFO_TYPE.TASK ? 'Aide' : clicked === HEADER_INFO_TYPE.DATAPOOL ? 'Instructions' : ''}`}</div>
          <div className={styles.content}>
            <TextWithAudio text={information} />
          </div>
        </div>
      </div>
    );
  }

  toggleInfo = type => {
    const { toggleInformation } = this.props;
    this.setState({
      clicked: type
    });
    toggleInformation(type);
  }
}

Header.propTypes = {
  toggleInformation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showDatapools: PropTypes.func.isRequired,
  showDatapoolsButton: PropTypes.bool,
  datapoolName: PropTypes.string,
  showTaskInformation: PropTypes.bool
};

Header.defaultProps = {
  showDatapoolsButton: false,
  datapoolName: '',
  showTaskInformation: false
};