import React from 'react';
import { Music } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_audio_id, file_music_id, ...props }) =>
  <Music
    {...props}
    sourceWithVocals={generateUrl(file_audio_id)}
    sourceKaraoke={generateUrl(file_music_id)}
  />
;