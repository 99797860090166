import { store } from '../../redux/store';

const SERVICE_URL = process.env.REACT_APP_SERVICE_URL;
const BASE_URL = SERVICE_URL.indexOf('http') > -1
  ? SERVICE_URL
  : `${window.location.origin}${SERVICE_URL.indexOf('/') === 0 ? '' : '/'}${SERVICE_URL}`;

export default async function signUri(uri, options = {}) {
  let res = await fetch(`${process.env.REACT_APP_SERVICE_URL}/sign/get`,
    {
      method: 'POST',
      body: JSON.stringify({
        uri: `${BASE_URL}${uri}`,
        ...options
      }),
      headers: {
        'authorization': store.getState().auth.token,
        'content-type': 'application/json'
      }
    }
  );

  if (res.status !== 200) {
    return false;
  }

  res = await res.json();

  return res.token;
}