import React, { Component } from 'react';

import SimpleButton from '../../SimpleButton';
import { Button } from '../../../components';

import styles from './stylesHotspot.less';

class BasicHotspot extends Component {

  render() {
    const { hotspot, showHotspot, editMode, onHotspotClick, isScene } = this.props;

    if (!editMode && !hotspot.content && !hotspot.container) {
      return null;
    }

    const position = {
      left: hotspot.position.left + 'px',
      top: hotspot.position.top + 'px',
      width: hotspot.position.width + 'px',
      height: hotspot.position.height + 'px',
      transform: 'rotate(' + hotspot.position.angle + 'deg)',
      borderRadius: hotspot.position.type === 'circle' ? '50%' : 0
    };

    let icon = null;
    if (hotspot.content) {
      // ugly exception for fichier links
      if(hotspot.content.type === 'CONTENT_LINK_FICHIER') {
        return (
          <div
            key={hotspot.id}
            className={`${styles.hotspot} ${showHotspot && styles.showHotspot} ${styles.editMode}`}
            style={position}
            data-id={hotspot.id}
            onClick={onHotspotClick}
          >
            <SimpleButton icon="fichierWithFontBig" styles={`${styles.hotspotIcon} ${showHotspot && styles.showHotspotIcon}`} />
          </div>
        );
      }

      icon = hotspot.content.type;
    } else if (hotspot.container) {
      icon = hotspot.container.type;
    }

    return (
      <div
        key={hotspot.id}
        className={`${styles.hotspot} ${isScene && styles.showBorder} ${showHotspot && styles.showHotspot} ${editMode ? styles.editMode : null}`}
        style={position}
        data-id={hotspot.id}
        onClick={onHotspotClick}
      >
        <Button
          icon={icon}
          color="yellow"
          styles={`${styles.hotspotIcon} ${styles[`icon-${icon}`]} ${showHotspot && styles.showHotspotIcon}`}
        />
      </div>
    );
  }
}

BasicHotspot.defaultProps = {
  editMode: false,
  onHotspotClick: () => { }
};


export default BasicHotspot;