import React from 'react';
import { AnswerDualOrder } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ ...props }) => {
  return (<AnswerDualOrder
    {...props}
    rightOnly
    textElementsLeft={props.answers.reduce((acc, key) => {
      if (key.config && JSON.parse(key.config).column === 'left') {
        const { file_audio_id, ...items } = key;
        acc.push({ ...items, audioSource: generateUrl(file_audio_id) });
      }
      return acc;
    }, [])}
    textElementsRight={props.answers.reduce((acc, key) => {
      if (key.config && JSON.parse(key.config).column === 'right') {
        const { file_audio_id, ...items } = key;
        acc.push({ ...items, audioSource: generateUrl(file_audio_id) });
      }
      return acc;
    }, [])}
  />);
};