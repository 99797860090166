// Polyfill require.context for jest
if (process.env.NODE_ENV === 'test') {
  const fs = require('fs');
  const path = require('path');

  require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
    const files = {};

    function readDirectory(directory) {
      fs.readdirSync(directory).forEach(file => {
        const fullPath = path.resolve(directory, file);

        if (fs.statSync(fullPath).isDirectory()) {
          if (scanSubDirectories) readDirectory(fullPath);

          return;
        }

        if (!regularExpression.test(fullPath)) return;

        files[fullPath] = true;
      });
    }

    readDirectory(path.resolve(__dirname, base));

    function Module(file) {
      return require(file);
    }
    
    Module.keys = () => Object.keys(files);

    return Module;
  };
}

/**
 * Dynamically loads reducers which are exported from "redux.js" files.
 */
export default function getDynamicReducers(ctxRequire) {
  const reduxCtx = require.context('../../../', true, /redux\.js$/i);

  return reduxCtx.keys().reduce((acc, key) => {
    const info = reduxCtx(key);

    if (!info.reducerKey) {
      throw new Error(`Reducer key not set in ${key}`);
    }

    if (acc[info.reducerKey])  {
      throw new Error(`Using two reducers with same key: ${info.reducerKey} is not possible!`);
    }

    if (!info.reducer)  {
      throw new Error(`No "reducer" export in ${key}`);
    }

    return {
      ...acc,
      [info.reducerKey]: info.reducer
    };
  }, {});
}