import React from 'react';
import { AnswerTextInput } from '@ovos-media/templates/dist/sp';

export default ({ answers, ...props }) => (
  <AnswerTextInput
    {...props}
    textElements={answers.map(textElement => ({
      id: textElement.id,
      config: textElement.config
    }))}
    placeholder={'Antwort hier eingeben!'}
  />
);