const defaultState = {
  darkenSurroundings: false
};

const SET_DARKEN_SURROUNDINGS = 'SET_DARKEN_SURROUNDINGS';

const setDarkenSurroundings = darkenSurroundings => {
  return { payload: { darkenSurroundings }, type: SET_DARKEN_SURROUNDINGS };
};


export const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_DARKEN_SURROUNDINGS: return { ...state, darkenSurroundings: payload.darkenSurroundings };
    default:
      return state;
  }
};

export const reducerKey = 'darkenSurroundings';
export const actions = {
  setDarkenSurroundings,
};