import isArray from 'lodash/isArray';
import generateUrl from './generateUrl';
import downloadAsset from './downloadAsset';
import getThumbnailPath from './functions/getThumbnailPath';

import { IMAGE_THUMBNAIL, CONTAINER_TYPES } from '../../components/constants';

import { store } from '../redux';

export default function replaceFileIdsWithUrls(obj = {}, options = { id: null, withPromises: false }) {
  let promises = options.withPromises ? [] : undefined;

  const result = Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'object') {
      if (options.withPromises) {
        const subResult = replaceFileIdsWithUrls(obj[key] || {}, options);
        acc[key] = subResult.result;
        promises = promises.concat(subResult.promises);
      } else {
        acc[key] = replaceFileIdsWithUrls(obj[key] || {}, options);
      }
    } else if (typeof key === 'string' && key.indexOf('file_') === 0) {
      let thumbnailProps = null;
      if (key.includes('image')) {
        switch (obj.__typename) {
          case 'Page':
            thumbnailProps = { width: IMAGE_THUMBNAIL.PAGES_OVERLAY.WIDTH };
            acc[key] = generateUrl(obj[key], thumbnailProps, false, true);
            const isDownloaded = store.getState().assets[obj[key]];
            if (!isDownloaded) {
              if (options.withPromises) {
                promises.push(downloadAsset({ url: acc[key], name: obj[key] + getThumbnailPath(thumbnailProps), id: options.id }));
              }
            }
            thumbnailProps = { width: IMAGE_THUMBNAIL.PAGE.WIDTH, quality: IMAGE_THUMBNAIL.PAGE.QUALITY };
            break;
          case 'Magazine':
            thumbnailProps = { width: IMAGE_THUMBNAIL.MAGAZINES_OVERLAY.WIDTH };
            break;
          case 'Content':
            if(obj.type && obj.type ==='CONTENT_SLIDESHOW_SLIDE') {
              thumbnailProps = { width: IMAGE_THUMBNAIL.PAGE.WIDTH };
            } else {
              thumbnailProps = key === 'file_background_image_id'
                ? { width: IMAGE_THUMBNAIL.PAGE.WIDTH }
                : { width: IMAGE_THUMBNAIL.CONTENT.WIDTH };
            }
            break;
          case 'Datapool':
            if(obj.type === CONTAINER_TYPES.CONTAINER_SCENE) {
              thumbnailProps = { height: IMAGE_THUMBNAIL.SCENE.HEIGHT, quality: IMAGE_THUMBNAIL.SCENE.QUALITY };
            } else {
              thumbnailProps = { width: IMAGE_THUMBNAIL.DATAPOOL_IMAGE.WIDTH, height: IMAGE_THUMBNAIL.DATAPOOL_IMAGE.HEIGHT };
            }
            break;
          case 'Answer':
          case 'Question':
          default:
            break;
        }
      } else if(key.includes('audio_archive' || 'source_directory_id' || 'any_id')) {
        // don't download audio archive, source directory and downloads
        return acc;
      }
      acc[key] = generateUrl(obj[key], thumbnailProps, false, true);
      const isDownloaded = store.getState().assets[obj[key] + getThumbnailPath(thumbnailProps)];
      if (!isDownloaded) {
        const downloadPromise = downloadAsset({ url: acc[key], name: obj[key] + getThumbnailPath(thumbnailProps), id: options.id });

        if (options.withPromises) {
          promises.push(downloadPromise);
        }
      }
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, isArray(obj) ? [] : {});

  return options.withPromises
    ? { result, promises }
    : result;
}
