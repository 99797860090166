import React from 'react';
import { Video } from '@ovos-media/templates/dist/sp';
import { generateUrl } from '../domains/uploads';

export default ({ file_video_id, ...props }) => {
  return <Video
    {...props}
    key={file_video_id}
    src={generateUrl(file_video_id)}
  />;
};