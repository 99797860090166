export default async function getToken({ username, email, password, token = false }) {
  return false; // no need to refresh the token with new oauth api
  /* const res = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/${token ? 'refreshToken' : 'login'}`,
    { 
      method: 'POST',
      body: JSON.stringify({
        username,
        email,
        password,
        token,
        scopes: ['admin.dashboard']
      }),
      headers: { 
        'Authorization': token ? `Bearer ${token}` : 'anonymous',
        'Content-Type': 'application/json',
      }
    }
  );

  if (res.status !== 200) {
    return false;
  }

  return await res.text(); */
}
