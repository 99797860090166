import React, { Component } from 'react';

import styles from './stylesBackgroundImage.less';

import { generateUrl } from '../../../domains/uploads';

import { IMAGE_THUMBNAIL } from '../../constants';


class BackgroundImage extends Component {

  render() {
    const { file_image_id, forScene } = this.props;

    let src, width, height;

    if(forScene) {
      src = generateUrl(file_image_id, { height: IMAGE_THUMBNAIL.SCENE.HEIGHT, quality: IMAGE_THUMBNAIL.SCENE.QUALITY });
    } else {
      src = generateUrl(file_image_id, { width: IMAGE_THUMBNAIL.PAGE.WIDTH, quality: IMAGE_THUMBNAIL.PAGE.QUALITY });
      width = 1024;
      height = 720;
    }

    return(
      <div className={styles.background}>
        <img
          className={styles.img}
          alt=""
          src={src}
          width={width}
          height={height}
          onLoad={this.onImgLoad}
          onMouseDown={this.onMouseDown}
        />
      </div>
    );
  }

  onMouseDown = e => {
    e.preventDefault(); // for ff and safari to not be able to drag the image
  }

  onImgLoad = e => {
    const { onImgLoad } = this.props;
    onImgLoad();
  };
}

BackgroundImage.defaultProps = {
  onImgLoad: () =>{}
};

export default BackgroundImage;