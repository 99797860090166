import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';


class ButtonWithText extends Component {
  render() {
    const { id, onClick, icon, color, active, disabled, className } = this.props;
    
    return (
      <div
        id={id}
        onClick={!disabled ? onClick : () => {}}
        disabled={ disabled }
        className={
          [
            styles.button,
            color ? styles[color] : styles.yellow,
            active ? styles.active : null,
            className,
          ].join(' ')
        }
      >
        <i className={[icon && `icon-${icon} ${styles.icon}`]} />
        {this.props.children}
      </div>
    );
  }
}

ButtonWithText.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ButtonWithText.defaultProps = {
  onClick: () => {}
};

export default ButtonWithText;

