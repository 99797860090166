import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { matomo } from '../../../domains/matomo';
import { Popup } from '../../Popup';
import Datapools from '../Components/Datapools';
import Result from './Components/Result';
import TaskCombination from './Components/TaskCombination';

import { CONTAINER_SCREENS, CONTAINER_WAITING_TIME, ITEMS_TYPE, ANIMATION_TYPE, NEXT_TASK_TYPE, TASK_TYPE_MAP, HEADER_INFO_TYPE } from '../../constants';

import styles from './styles.less';
import verifyAnswer from '../functions/verifyAnswer';
import taskCanContinue from '../functions/taskCanContinue';
import nextTask from '../functions/nextTask';

class CombinationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenType: props.screenType,
      datapool: props.currentDatapool,
      sentence: props.item,
      rounds: this.getRoundsLength(props.item),
      showInformation: false,
      information: '',
      resultSentences: [],
      startAnimation: '',
      firstTry: true,
      taskProgress: [],
      currentTaskIndex: 0,
      backgroundColor: false,
      showPopup: false,
      setup: true
    };
  }

  render() {
    const {
      screenType, datapool, sentence, rounds, showInformation, information, resultSentences,
      taskProgress, currentTaskIndex, showPopup, backgroundColor, startAnimation, setup
    } = this.state;
    const { onClose, container, enteredAnswer, getClozeAnswers } = this.props;

    let contentComponent = null;
    switch (screenType) {
      case CONTAINER_SCREENS.DATAPOOLS:
        contentComponent = <Datapools container={container} onClose={onClose} setDatapool={this.setDatapool} />;
        break;
      case CONTAINER_SCREENS.RESULT:
        const resultData = taskProgress.reduce((acc, curr) => {
          acc[curr.status] ? acc[curr.status] += 1 : acc[curr.status] = 1;
          return acc;
        }, {});
        const data = resultData.first > 0 || resultData.second > 0 || resultData.wrong > 0 ? [resultData.first, resultData.second, resultData.wrong] : [resultData.answered];
        const finished = resultSentences.length === datapool.items_per_session;
        contentComponent =
          <Result
            showDatapools={this.reset}
            datapoolName={datapool.name}
            showInformation={showInformation}
            information={datapool.description}
            toggleInformation={this.toggleInformation}

            onClose={finished ? onClose : this.togglePopup}
            restart={this.replay}
            data={data}
            sentences={resultSentences}
            finished={finished}
            nextSentence={this.nextSentence}
          />;
        break;
      case CONTAINER_SCREENS.CONTAINER_COMBINATION:
        contentComponent = sentence && sentence.tasks[currentTaskIndex]
          ? <TaskCombination
            showDatapools={this.reset}
            datapoolName={datapool.name}
            togglePopup={this.togglePopup}
            showInformation={showInformation}
            information={information}
            toggleInformation={this.toggleInformation}

            finishSetup={this.finishSetup}
            setup={setup}
            description={datapool.description}
            imageId={datapool.file_image_id}

            backgroundColor={backgroundColor}
            rounds={rounds}
            taskProgress={taskProgress.filter(tp => tp.sentenceId === sentence.id)}
            task={sentence.tasks[currentTaskIndex]}
            enteredAnswer={enteredAnswer}
            verify={this.verify}
            canContinue={taskCanContinue(sentence.tasks[currentTaskIndex].answerGroup, enteredAnswer, getClozeAnswers)}
            startAnimation={startAnimation}
          />
          : null;
        break;
      default:
        break;
    }
    return (
      <div className={styles.container}>
        {contentComponent}
        <Popup
          show={showPopup}
          title="Attention!"
          text={<span>Est-ce que tu veux vraiment arrêter le jeu?<br />Tous les progrès seront perdus si tu fermes le jeu maintenant.</span>}
          accept={this.accept}
          cancel={this.cancel}
        />
      </div>
    );
  }

  componentDidMount = () => {
    this.timeoutSlot = null;
    this.timeoutSlot2 = null;
  };

  togglePopup = () => {
    const { showPopup } = this.state;
    this.setState({
      showPopup: !showPopup
    });
  }

  accept = () => {
    const { onClose } = this.props;
    onClose();
  }

  cancel = () => {
    this.togglePopup();
  }

  findTask = (sentence, id) => {
    return sentence.tasks.find(t => t.id === id);
  }

  toggleInformation = type => {
    const { showInformation, sentence, datapool, currentTaskIndex } = this.state;
    this.setState({
      showInformation: showInformation !== type ? type : false,
      information: type === HEADER_INFO_TYPE.TASK ? sentence.tasks[currentTaskIndex].description : type === HEADER_INFO_TYPE.DATAPOOL ? datapool.description : ''
    });

    if((showInformation && type !== showInformation) || !showInformation) {
	    if(type === HEADER_INFO_TYPE.TASK) {
	      matomo(['trackEvent', 'ClickedHelp', `${sentence.tasks[currentTaskIndex].name} (${sentence.tasks[currentTaskIndex].id})`]);
	    } else {
	      matomo(['trackEvent', 'ClickedBackgroundInfo', `${datapool.name} (${datapool.id})`]);
	    }
	  }
  }

  getRoundsLength = sentence => {
    if (!sentence) {
      return 0;
    } else if (sentence.tasks[0].answerGroup.config && JSON.parse(sentence.tasks[0].answerGroup.config).dependencyMode) {
      let i = 1;
      let index = 0;
      let nextTask = sentence.tasks[index];
      while (nextTask.answerGroup.answers[0].nextTask !== null) {
        i += 1;
        nextTask = this.findTask(sentence, nextTask.answerGroup.answers[0].nextTask.id);
      }
      return i;
    } else {
      return sentence.tasks.length;
    }
  }

  verify = () => {
    const { enteredAnswer, getClozeAnswers } = this.props;
    const { sentence, taskProgress, currentTaskIndex, firstTry } = this.state;

    const correct = verifyAnswer(sentence.tasks[currentTaskIndex].answerGroup, enteredAnswer, getClozeAnswers);

    let newTask = false;
    if (sentence.tasks[currentTaskIndex].answerGroup.type === TASK_TYPE_MAP.ANSWER_FACT_FICTION) {
      if (correct) {
        taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'first' });
        this.setState({
          backgroundColor: 'green'
        });
      } else {
        taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'wrong' });
        this.setState({
          backgroundColor: 'red'
        });
      }
      newTask = true;
    } else if (correct === 'Speak') {
      taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'answered' });
      this.setState({
        backgroundColor: 'grey'
      });
      newTask = true;
    } else if (correct) {
      if (firstTry) {
        taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'first' });
      }
      else {
        taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'second' });
      }
      this.setState({
        backgroundColor: 'green'
      });
      newTask = true;
    } else {
      if (firstTry) {
        setTimeout(() => this.setState({
          firstTry: false,
          startAnimation: ANIMATION_TYPE.ROTATION,
          backgroundColor: ''
        }), CONTAINER_WAITING_TIME);
      } else {
        taskProgress.push({ sentenceId: sentence.id, snippet: sentence.tasks[currentTaskIndex].snippet, status: 'wrong' });
        newTask = true;
      }
      this.setState({
        backgroundColor: 'red'
      });
    }
    this.continue(newTask);
  }

  continue = newTask => {
    const { setAnswer, addToClozeAnswers, enteredAnswer } = this.props;
    const { taskProgress, sentence, rounds, resultSentences, datapool } = this.state;
    let funcAfterTimeout = null;
    if (rounds === taskProgress.filter(tp => tp.sentenceId === sentence.id).length) {
      resultSentences.push({ id: sentence.id, text: sentence.text, file_audio_id: sentence.file_audio_id });
      funcAfterTimeout = () => {this.setState({
        screenType: CONTAINER_SCREENS.RESULT,
        backgroundColor: '',
        sentence: null,
        rounds: 0
      });

      matomo(['trackEvent', 'FinishedDatapool', `${datapool.name} (${datapool.id})`]);
      };
    } else if (newTask) {
      funcAfterTimeout = () => this.setTask(enteredAnswer);
    }
    this.timeoutSlot = setTimeout(() => { setAnswer(); addToClozeAnswers(); }, CONTAINER_WAITING_TIME);
    this.timeoutSlot2 = setTimeout(funcAfterTimeout, CONTAINER_WAITING_TIME);
  }

  nextSentence = () => {
    const { container, getItemFromPool } = this.props;
    const { resultSentences, datapool } = this.state;
    if (resultSentences.length === datapool.items_per_session) {
      this.setState({
        screenType: CONTAINER_SCREENS.RESULT,
        startRotation: false,
        backgroundColor: '',
        task: null
      });
    } else {
      const sentence = getItemFromPool(datapool, ITEMS_TYPE.SENTENCES);
      this.setState({
        screenType: container.type,
        sentence: sentence,
        rounds: this.getRoundsLength(sentence),
        currentTaskIndex: 0,
        firstTry: true,
        backgroundColor: '',
        startAnimation: ''
      });
    }
  }

  replay = () => {
    const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
    const { datapool } = this.state;
    resetCurrentUsedItems(datapool);
    const sentence = getItemFromPool(datapool, ITEMS_TYPE.SENTENCES);
    this.setState({
      screenType: container.type,
      startAnimation: '',
      firstTry: true,
      currentTaskIndex: 0,
      resultSentences: [],
      taskProgress: []
    }, () => this.setState({
      sentence: sentence,
      rounds: this.getRoundsLength(sentence)
    }));
  }

  reset = () => {
    const { resetCurrentUsedItems } = this.props;
    const { datapool } = this.state;

    clearTimeout(this.timeoutSlot);
    clearTimeout(this.timeoutSlot2);

    resetCurrentUsedItems(datapool);
    this.setState({
      screenType: CONTAINER_SCREENS.DATAPOOLS,
      startAnimation: '',
      datapool: null,
      sentence: null,
      rounds: 0,
      showInformation: false,
      firstTry: true,
      resultSentences: [],
      taskProgress: [],
      currentTaskIndex: 0
    });
  }

  setDatapool = id => {
    const { container, getItemFromPool, resetCurrentUsedItems } = this.props;
    const newDatapool = container.datapools.find(dp => dp.id === id);
    resetCurrentUsedItems(newDatapool);
    const sentence = getItemFromPool(newDatapool, ITEMS_TYPE.SENTENCES);
    this.setState({
      datapool: newDatapool,
      screenType: container.type,
      sentence: sentence,
      rounds: this.getRoundsLength(sentence),
      resultSentences: [],
      taskProgress: [],
      setup: true,
      currentTaskIndex: 0,
      backgroundColor: ''
    });
  }

  setTask = enteredAnswer => {
    const { currentTaskIndex, sentence } = this.state;
    const hasDependency = sentence.tasks[currentTaskIndex].answerGroup.config ? JSON.parse(sentence.tasks[currentTaskIndex].answerGroup.config).dependencyMode : false;
    this.setState({
      firstTry: true,
      backgroundColor: '',
      startAnimation: '',
      currentTaskIndex: hasDependency ? nextTask(sentence.tasks, sentence.tasks[currentTaskIndex].answerGroup, enteredAnswer, NEXT_TASK_TYPE.INDEX) : currentTaskIndex + 1
    });
  }

  finishSetup = () => {
    const { datapool } = this.state;

    this.setState({
      setup: false
    });

    matomo(['trackEvent', 'StartedDatapool', `${datapool.name} (${datapool.id})`]);
  }
}

CombinationContainer.propTypes = {
  setAnswer: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  enteredAnswer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ])
};

CombinationContainer.defaultProps = {
};

export default CombinationContainer;