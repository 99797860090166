import parseJWT from './parseJwt';

export default async function getToken({ loginId, token }) {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/auth/autoLogin`,
    { 
      method: 'POST',
      body: JSON.stringify({
        loginId
      }),
      headers: { 
        'Authorization': token ? `Bearer ${token}` : 'anonymous',
        'Content-Type': 'application/json',
      }
    }
  );

  if (res.status !== 200) {
    return false;
  }

  const text = await res.text();

  return parseJWT(text);
}
