import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Home from './components/Home';
import Pages from './components/Pages';
import Fichier from './components/Fichier';
import FichierWithFont from './components/FichierWithFont';
import FichierWithFontBig from './components/FichierWithFontBig';

import styles from './styles.less';

class SimpleButton extends Component {
  renderIcon = () => {
    const { icon } = this.props;

    switch (icon) {
      case 'home': return Home;
      case 'pages': return Pages;
      case 'fichier': return Fichier;
      case 'fichierWithFont': return FichierWithFont;
      case 'fichierWithFontBig': return FichierWithFontBig;
      default: return null;
    }
  }

  render() {
    const { id, onClick, styles: classNames } = this.props;

    return (
      <div
        id={id}
        onClick={onClick}
        className={
          [
            styles.button,
            classNames,
          ].join(' ')
        }
      >
        {this.renderIcon()}
      </div>
    );
  }
}


SimpleButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  classNames: PropTypes.string,
};

SimpleButton.defaultProps = {
  onClick: () => { }
};

export default SimpleButton;

