const defaultState = {
  serviceTokens: {},
  permissions: [],
  hideLoginButtonForWebApp: false,
  showNoLicenseWarning: false
};

const SET_USER = 'SET_USER';
const SET_TOKEN = 'SET_TOKEN';
const SET_SERVICE_TOKEN = 'SET_SERVICE_TOKEN';
const RESET_STATE = 'RESET_STATE';
const SET_HIDE_LOGIN_BUTTON = 'SET_HIDE_LOGIN_BUTTON';
const SET_SHOW_NO_LICENSE_WARNING = 'SET_SHOW_NO_LICENSE_WARNING';

const setUser = user => ({ type: SET_USER, user });
const setToken = token => ({ type: SET_TOKEN, token });
const setServiceToken = (service, token) => ({ type: SET_SERVICE_TOKEN, service, token });
const clearAuth = () => ({ type: RESET_STATE });
const setHideLoginButton = () => ({ type: SET_HIDE_LOGIN_BUTTON });
const setShowNoLicenseWarning = showNoLicenseWarning => ({ type: SET_SHOW_NO_LICENSE_WARNING, showNoLicenseWarning });

const authReducer = (state = defaultState, { type, user, service, token, payload, showNoLicenseWarning }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        permissions: user.permissions,
        user,
      };
    case SET_TOKEN:
      return { ...state, token: token || payload };
    case SET_SERVICE_TOKEN:
      return { ...state, serviceTokens: { ...state.serviceTokens, [service]: token } };
    case RESET_STATE:
      return { ...defaultState };
    case SET_HIDE_LOGIN_BUTTON:
      return { ...state, hideLoginButtonForWebApp: true };
    case SET_SHOW_NO_LICENSE_WARNING:
      return { ...state, showNoLicenseWarning };
    default: return state;
  }
};

export const reducerKey = 'auth';
export const reducer = authReducer;
export const actions = {
  setUser,
  setToken,
  clearAuth,
  setServiceToken,
  setHideLoginButton,
  setShowNoLicenseWarning
};
