export default object => {
  // handle cache ids for specific types
  switch (object.__typename) {
    case 'Datapool':
      return `${object.__typename}.${object.id || object._id}.${object.items_per_session}`;
    case 'OverrideLoginStatus':
      return `${object.__typename}.${object.key}`;
    default:
      return `${object.__typename}.${object.id || object._id}`; // fall back to `id` and `_id` for all other types
  }
};
