import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor, oldStorage } from '../store';

import appVersion from '../../../helpers/appVersion';
import LoadingSpinner from '../../../components/LoadingSpinner';
import styles from './styles.less';

class ReduxProvider extends Component {
  constructor(props) {
    super(props);

    // since we need to use async in the store.js, store and persistor will be undefined at start and we need to forceUpdate when that changed
    this.checkPersistor = setInterval(() => {
      if (persistor) {
        this.forceUpdate();
        clearInterval(this.checkPersistor);
      }
    }, 200);

    if (appVersion.isIpad) {
      // since we migrated the store for ios from indexeddb to websql we still have to clean up all the things
      // in the old storage (blobs), we cant do it earlier, since the store might not have been filled until then.
      this.checkStore = setInterval(() => {
        if (store && store.getState()._persist.rehydrated) {
          oldStorage.clear();
          clearInterval(this.checkStore);
        }
      }, 200);
    }
  }

  render() {
    const { children } = this.props;

    return (!persistor ?
      this.renderLoadingSpinner() :
      <Provider store={store}>
        <PersistGate
          loading={this.renderLoadingSpinner()}
          persistor={persistor}
        >
          {children}
        </PersistGate>
      </Provider>
    );
  };

  renderLoadingSpinner = () => {
    return <div>
      <div className={styles.loadingProgress}>
        <LoadingSpinner width={100} caption={'Loading'} />
      </div>
    </div>;
  }
}

export default ReduxProvider;