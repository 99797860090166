import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Button from '../../Button';

import styles from '../styles/datapools.less';
import { generateUrl } from '../../../domains/uploads';

export default class Datapools extends Component {
  render() {
    const { onClose, container, setDatapool } = this.props;
    return (
      <div className={styles.informationContainer}>
        <div className={styles.titleBar}>
          {container.name}
          <Button icon="close-large" color="yellow" onClick={onClose} />
        </div >
        <div className={styles.content}>
          {container.datapools.map(datapool =>
            <div key={datapool.id} className={styles.datapool} onClick={() => setDatapool(datapool.id)}>
              <img className={styles.datapoolImage} src={generateUrl(datapool.file_image_id)} alt="Datapool" />
              <div className={styles.datapoolName} >
                {datapool.name}
              </div>
            </div>
          )}
        </div >
      </div>
    );
  }
}

Datapools.propTypes = {
  onClose: PropTypes.func.isRequired,
  container: PropTypes.object.isRequired
};