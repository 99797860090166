import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.less';


class Button extends Component {
  render() {
    const { id, onClick, icon, color, pageNumber, active, disabled, shadow, smallShadow, smaller, classes, position, styles: externalStyles } = this.props;

    return (
      <div
        id={id}
        onClick={!disabled ? onClick : () => { }}
        data-pagenumber={pageNumber || null}
        disabled={disabled}
        className={
          [
            styles.button,
            color ? styles[color] : styles.yellow,
            icon && `icon-${icon}`,
            active ? styles.active : null,
            position ? styles[position] : null,
            !shadow && styles.noShadow,
            smallShadow && styles.smallShadow,
            smaller && styles.smaller,
            classes,
            externalStyles,
          ].join(' ')
        }
      >
        {this.props.children}
      </div>
    );
  }
}


Button.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  pageNumber: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
  overlay: PropTypes.string,
  classes: PropTypes.string,
  setOverlay: PropTypes.func,
  position: PropTypes.string,
  styles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

Button.defaultProps = {
  onClick: () => { }
};


export default Button;

