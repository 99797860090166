import { replaceFileIdsWithUrls } from '../../uploads';
import { store } from '../../redux';

import performanceChecks from '../../../helpers/performanceChecks';

export default async function downloadMagazineContent(magazine) {
  const { promises } = replaceFileIdsWithUrls(magazine, { id: magazine.id, withPromises: true });

  if (performanceChecks.enabled) {
    console.info('DOWNLOAD START');
    performanceChecks.downloadStart = new Date();
  }

  // amountOfPromises needs to be cached because if some promises get the same data, duplicates get deleted, which causes the progress to fluctuate
  const amountOfPromises = promises.length;
  const downloadProgressStep = 100 / amountOfPromises;
  let downloadProgress = 0.1;
  if (store.getState().magazines.currentMagazineId === magazine.id) {
    store.dispatch({
      type: 'SET_CURRENT_MAGAZINE_ID',
      payload: {
        currentMagazineId: null
      }
    });
  }
  store.dispatch({
    type: 'DOWNLOAD_CONTENT',
    payload: {
      id: magazine.id,
      downloading: true,
      progress: downloadProgress
    }
  });

  await Promise.all(promises.map(promise => {
    return promise.then(() => {
      const increasedByOnePercentPoint = Math.ceil(downloadProgress) < Math.ceil(downloadProgress + downloadProgressStep);
      downloadProgress += downloadProgressStep;

      if (increasedByOnePercentPoint) {
        // dont dispatch for every download, do it only when enough changed that it jumps to the next percent point
        store.dispatch({
          type: 'DOWNLOAD_CONTENT',
          payload: {
            id: magazine.id,
            downloading: true,
            progress: Math.ceil(downloadProgress) >= 100 ? 99 : Math.ceil(downloadProgress)
          }
        });
      }
    }).catch(err => {
      console.error('PROMISE ERROR:', err);
      downloadProgress += downloadProgressStep;
      return;
    });
  }));

  if (performanceChecks.enabled) {
    performanceChecks.downloadEnd = new Date();
    console.info('DOWNLOAD END');
    console.info(performanceChecks.downloadDuration());
  }

  store.dispatch({
    type: 'DOWNLOAD_CONTENT',
    payload: {
      id: magazine.id,
      downloading: false,
      progress: 100
    }
  });

  store.dispatch({
    type: 'SET_MAGAZINES_WITH_UPDATED_BLOBS',
    payload: {
      id: magazine.id
    }
  });
}
